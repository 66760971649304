import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import { styled } from "nativewind";
import Papa from 'papaparse';
import React, { useEffect } from 'react';
import {
    KeyboardAvoidingView,
    SafeAreaView,
    StyleSheet,
    Text,
    View
} from 'react-native';
import { useDispatch } from 'react-redux';
import { fetchCreateUserRequest } from '../../store/ducks/app/actions';
import { ScreenNames, generateRandomPassword } from "../../utils/Util";
import { getItemFromStore } from '../../utils/storage/storage';
import MyButton from '../MyButton';
import { pageTitleStyle, textStyle } from '../Styles';
import AnalyticsLogger from "../managers/AnalyticsLogger";

const StyledText = styled(Text);
const StyledView = styled(View);

const ImportUsersComponent = ({ }) => {

    const dispatch = useDispatch()

    useEffect(() => {
      AnalyticsLogger.getInstance().logScreenView(ScreenNames.ImportUsers)
    }, [])

    const pickFile = async () => {
        let result = await DocumentPicker.getDocumentAsync({
            type: 'text/csv',
            copyToCacheDirectory: false,
            multiple: false,
        });
        if (!result.canceled) {
            const fileUri = result.assets[0].uri;
            const readFileResult = await FileSystem.readAsStringAsync(fileUri); 
            parseCsv(readFileResult); 
        }
    };

    const parseCsv = async (csvString) => {
        const orgId = await getItemFromStore('orgId')
        Papa.parse(csvString, {
            header: true, 
            complete: (results) => {
                const usersList = results.data;
                for (const user of usersList) {
                    const password =  generateRandomPassword(8)
                    let body = {
                        firstName : user.firstname,
                        lastName : user.lastname,
                        email: user.email,
                        password: password,
                        role: 'golfer',
                        csvUser: true
                    }
                    orgId && (body.orgId = orgId)
                    dispatch(fetchCreateUserRequest(body))
                }
                alert(`Users created successfully, all newly created users will recieve email with password to login`)
            },
            error: (error) => console.error('Parsing error:', error),
        });
    };

    return (
        <View style={{ flex: 1, marginBottom: 0, width: '100%',backgroundColor:'white' }}>
            <SafeAreaView style={style.safeAreaStyle}>
                <KeyboardAvoidingView behavior="padding">
                    <StyledView className="mx-auto my-2">
                        <StyledText className={pageTitleStyle}>
                            Import Golfers
                        </StyledText>
                        <View style={style.mainBoxStyle} />
                        <StyledText className={textStyle}>
                            CSV file should contain three columns: Email, First Name, Last Name. User account will be created, user will recieve email with tempoary password.
                        </StyledText>
                        <View style={style.mainBoxStyle} />
                        {
                            <MyButton
                                label={"Import Golfers"}
                                theme={"submit"}
                                onPress={() => pickFile()}
                            />
                        }

                    </StyledView>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    )
}

const style = StyleSheet.create({
    pageHeaderStyle: { flexDirection: 'row', justifyContent: 'space-between' },
    addNewUserButtonStyle: {
        color: 'rgba(87,164,251,1)',
        fontSize: 20,
        marginTop: 7.5,
         
    },
    userListButtonsStyle: {
        color: 'rgba(87,164,251,1)',
        fontSize: 17.5,
        marginTop: 7.5
    },
    flatlistContainer: { marginBottom: 130 },
    userName: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    userEmail: {
        fontSize: 18,
        fontWeight: '400'
    },
    item: {
        padding: 20,
        borderWidth: 2,
        borderRadius: 10,
        borderBottomColor: 'black',
        marginBottom: 10
    },
    mainBoxStyle: {
        padding: 20,
    },
    safeAreaStyle: {
        flex: 1,
        marginHorizontal: 20,
    },
    warningBoxStyle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    warningTextStyle: {
        fontFamily: 'semi_bold',
        fontSize: 16,
        color: 'red',
        marginLeft: 10,
    },
    descriptionTextStyle: {
        fontSize: 14,
        fontFamily: 'regular',
        color: '#000004',
        textAlign: 'justify',
        lineHeight: 22,
        marginBottom: 16,
        marginTop: 11,
    },
    enterPasswordDescriptionTextStyle: {
        fontSize: 14,
        fontFamily: 'regular',
        color: 'black',
        textAlign: 'justify',
        lineHeight: 20,
        marginTop: 10,
    },
    buttonBoxStyle: {
        flex: 1,
        justifyContent: 'flex-end',
        padding: 20,
    },
})

export default ImportUsersComponent
