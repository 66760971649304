import { Dimensions, Text, TextInput, View } from 'react-native';
import { styled } from 'nativewind';

import {
  pageTitleStyle,
  textFunStyle,
  textInputStyle,
  textInputLabelStyle,
  textStyle
} from './Styles';


const StyledText = styled(Text);
const StyledTextInput = styled(TextInput);
const StyledView = styled(View);

export default function MyTextInput({ label, type, value, placeholder = null, onChangeValue, isSearch = false, onSubmitEditing = null, width = null, keyboardType ='numeric', disabled=false }) {

  if (type === "email") {
    return (
      <StyledView className="mt-3">
        <StyledText className={textInputLabelStyle}>
          {label}
        </StyledText>
        <StyledTextInput
          style={[
            width && { width: width }
          ]}
          editable={!disabled}
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
          value={value}
          onChangeText={onChangeValue}
          className={textInputStyle}
          placeholder={placeholder || "Enter email address"}
          placeholderTextColor="#A9A9A9"
          returnKeyType={isSearch ? 'search' : 'default'}
          onSubmitEditing={onSubmitEditing}
        />
      </StyledView>
    );
  } else if (type === "password") {
    return (
      <StyledView className="mt-2">
        <StyledText className={textInputLabelStyle}>
          {label}
        </StyledText>
        <StyledTextInput
          style={[
            width && { width: width }
          ]}
          editable={!disabled}
          id="password"
          name="password"
          type="password"
          autoComplete="password"
          required
          className={textInputStyle}
          onChangeText={onChangeValue}
          value={value}
          placeholder="Enter password"
          placeholderTextColor="#A9A9A9"
          secureTextEntry={true}
        />
      </StyledView>
    );
  } else {
    return (
      <StyledView className="mt-2">
        <StyledText className={textInputLabelStyle}>
          {label}
        </StyledText>
        <StyledTextInput
          editable={!disabled}
          style={[
            width && { width: width }
          ]}
          inputMode={type}
          required
          value={value}
          onChangeText={onChangeValue}
          className={textInputStyle}
          keyboardType={keyboardType}
        />
      </StyledView>
    );
  };

};
