import React, { useEffect, useState } from 'react';
import {
    Dimensions,
    KeyboardAvoidingView,
    SafeAreaView,
    StyleSheet,
    View,
    Platform,
    Text
} from 'react-native';
import HeaderButton from "../HeaderButton";
import OrderList from "./OrderList";
import { exportOrdersIdle, exportOrdersRequest } from '../../../store/ducks/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentUser } from '../../CurrentUserProvider';
import { getItemFromStore } from "../../../utils/storage/storage";

const width = Dimensions.get("window").width;

const Orders = ({ }) => {
    const {uid} = useCurrentUser();
    const dispatch = useDispatch();
    const exportData = useSelector(state => state.app.exportOrders);

    useEffect(() => {
        if (exportData.status === "success") {
            alert("exported successfully")
            dispatch(exportOrdersIdle())
        }
    }, [exportData.status])

    const handleExport = async() => {
        const orgId = await getItemFromStore('orgId')
        dispatch(exportOrdersRequest(
            {orgId:orgId}
        ))
    }

    return (
        <View style={{ flex: 1, marginBottom: 0, backgroundColor: 'white' }}>
            <SafeAreaView style={style.safeAreaStyle}>
                <KeyboardAvoidingView behavior="padding">
                    {Platform.OS === 'web' ?
                        <>
                            <HeaderButton title={"Orders"} btn1={"Add Order"} btn2={"Export Order"} description={"Manage and View details of your Orders"}  onOrdersExport={handleExport} />
                            <OrderList
                                titles={["Customer Name", "SwingID (email)", "Order ID", "Status", "Delivery Date", "Order Details"]}
                            />
                        </>
                         :
                        <View style={style.container}>
                            <Text style={{ fontSize: 15, width: '80%', textAlign: 'center' }}>Page currently under development, check back soon</Text>
                        </View>
                    }

                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
};

export default Orders;

const style = StyleSheet.create({
    safeAreaStyle: {
        flex: 1,
        ...Platform.select({
            ios: {
                width: Dimensions.get("screen").width,
            },
            android: {
                width: width,
            },
            web: {
                width: width - 40,
                marginHorizontal: 20,
            },
        }),
    },
    container: {
        width:'100%',
        height: '100%',
        margin: 0,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',

    },

});
