import { styled } from "nativewind";
import { Dimensions, Platform, ScrollView, Text, View } from 'react-native';
import { useEffect, useRef, useState } from 'react';
import MyButton from '../MyButton';
import MyTextInput from '../MyTextInput';
import MyPicker from '../Picker';
import Checkbox from 'expo-checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetSwingIdle, fetchGetSwingRequest, fetchPostSwingIdle, fetchPostSwingRequest, fetchRecommendationRequest } from '../../store/ducks/app/actions';
import { APIStates } from '../../utils/Strings';
import {
  pageTitleStyle,
  textScreenDescriptionlStyle
} from '../Styles';
import TagsPicker from "../TagsPicker";
import { ironInputs, swingInputs, defaultValues } from "../../utils/Util";
import { getItemFromStore } from "../../utils/storage/storage";
import { useNavigation } from '@react-navigation/native';
import { readSwingData } from '../../database/ReadData';
import { useCurrentUser } from "../CurrentUserProvider";


const StyledView = styled(View);
const StyledText = styled(Text);
const StyledScrollView = styled(ScrollView);



export default function SwingDataForm() {
  const { currentUser,role,uid } = useCurrentUser();
 const navigation = useNavigation();
  const dispatch = useDispatch()
  const swing = useSelector(state => state.app.swing);
  const postSwing = useSelector(state => state.app.postSwing);
  const [refresh, setRefresh] = useState(false)
  const [userId, setUserId] = useState('');
  const [isPickerVisible, setIsPickerVisible] = useState(-1)
  const [hasIronData, setHasIronData] = useState(null);
  const [inputs, setInputs] = useState(swingInputs)
  const [swingDataKey, setSwingDataKey] = useState(null);

  const scrollRef = useRef(null)

  useEffect(() => {
    readSwingData(uid, setSwingDataKey);
  }, []);

  useEffect(() => {
    fetchSwingData()
  }, []);

  useEffect(() => {
    if (swing.status == APIStates.SUCCESS) {
      populateInputs(swing?.data)
      setRefresh(!refresh)
    } else if (swing.status == APIStates.FAILURE) {
      swing.message && alert(swing.message)
      populateInputs(defaultValues, true)
      dispatch(fetchGetSwingIdle())
    }
  }, [swing.status])

  useEffect(() => {
    if (postSwing.status == APIStates.SUCCESS) {
      fetchSwingData()
      dispatch(fetchPostSwingIdle())
      navigation.navigate("ViewClubs")
    } else if (postSwing.status == APIStates.FAILURE) {
      alert(postSwing.message)
      dispatch(fetchPostSwingIdle())
    }
  }, [postSwing.status])


  const fetchSwingData = async () => {
    const userId = await getItemFromStore('userId')
    setUserId(userId)
    const _userId = currentUser ? currentUser.id : userId;
    dispatch(fetchGetSwingRequest({
      userId: _userId
    }))
    dispatch(fetchRecommendationRequest({
      userId: _userId
    }))
  }

  const populateInputs = (data, defaultValues = false) => {
    if (!defaultValues &&  data.ironMiss && inputs.filter((input) => input.name == 'ironClubPath').length == 0) {
      setHasIronData(true)
      inputs.splice(5, 0, ...ironInputs)
    }

    inputs.forEach((input) => {
      input.selection = data[input.name] ? data[input.name] : defaultValues[input.name] 
    })
  }

  function onSubmit() {

    const _userId = currentUser ? currentUser.id : userId;
    let formJson = {
      userId: _userId,
    }

    inputs.forEach((input) => {
      if (input.name == 'swingSpeed' || input.name == 'ironSwingSpeed'){
        formJson[`${input.name}`] = (Math.round(input.selection / 5) * 5).toString();
        return
      }
      formJson[`${input.name}`] = typeof input.selection == 'string' ? input.selection.toLowerCase() : input.selection;
    })
    dispatch(fetchPostSwingRequest(formJson))
  }

  // Include Iron Data?
  // On Top of Screen - Show GolfRoots Everywhere
  // Show in Manage Account - Actual Role of User - In Golfer Profile 
  useEffect(() => {
    if (hasIronData) {
      if (inputs.filter((input) => input.name == 'ironClubPath').length == 0) {
        inputs.splice(5, 0, ...ironInputs)
        setRefresh(!refresh)
      }
    } else if(hasIronData == false){
      if (inputs.filter((input) => input.name == 'ironClubPath').length > 0) {
        inputs.splice(5, 4)
        setRefresh(!refresh)
      }
    }
  }, [hasIronData])
  

  const setCurrentSelected = (index) => {
    scrollRef.current.scrollTo({ x: 0, y: index * Dimensions.get('screen').height * 0.15 - 100, animated: true })
  }

  const setSelection = (index, value) => {
    const _index = inputs.findIndex((input) => input.index == index)
    inputs[_index].selection = value;
    setRefresh(!refresh)    
  }

  return (
    <StyledScrollView 
      showsVerticalScrollIndicator={false}
      ref={scrollRef}
      style={{ flex: 1, paddingBottom: 40, width: '100%',backgroundColor:'white',padding:"1.5%",paddingLeft:Platform.OS ==='ios'? "3%":null,paddingRight:Platform.OS ==='ios'? "3%":null }}>
      <StyledView className='flex flex-col'>
        <StyledText className={pageTitleStyle}>
          Swing Data
        </StyledText>
        <StyledText className={textScreenDescriptionlStyle + " my-2"}>
          Fill out the form to get your personalized caddie and club recommendations.
        </StyledText>
      </StyledView>

      {
        currentUser && (
          <StyledView className='flex flex-col'>
            <StyledText className={textScreenDescriptionlStyle + " my-2"}>
              {`Username: ${currentUser.firstName ? `${currentUser.firstName} ${currentUser.lastName}` : currentUser.name || 'No Name'} \nEmail: ${currentUser.email}`}
            </StyledText>
          </StyledView>
        )
      }

      <View
        key={refresh}
      >
        {inputs.map((data) => {
          if (data.list) {
            return (
              <StyledView
                key={data.name}
              >
                <MyPicker
                  index={data.index}
                  key={data.name}
                  label={data.label.toString()}
                  name={data.name}
                  pickerItems={data.pickerItems}
                  selection={data.selection}
                  setSelection={setSelection}
                  setCurrentSelectedIndex={setCurrentSelected}
                  isPickerVisible={isPickerVisible}
                  setIsPickerVisible={setIsPickerVisible}
                />
              </StyledView>
            );
          } else {
            if (data.checkbox) {
              return (
                <StyledView
                  style={{flexDirection: 'row', alignItems: 'center', marginVertical: 10}}
                  key={data.name}
                >
                  <Checkbox
                    value={hasIronData}
                    onValueChange={setHasIronData}
                    color='#4B5563'
                    style={{ alignSelf: 'center' }}
                  />
                  <StyledText className='text-gray-900 text-lg capitalize mx-2'>{data.label}</StyledText>
                </StyledView>
              )
            } else if (data.tags) {
              return (
              <View key={data.name}>
                <TagsPicker 
                  defaultSelected={data.selection}
                  index={data.index}
                  tags={data.pickerItems}
                  setSelection={setSelection}
                />
              </View>)
            } else {
            return (
              <View key={data.name}>
                <MyTextInput
                  key={data.name}
                  label={data.label}
                  type={data.type}
                  value={data.value}
                  onChangeValue={data.setFunction}
                />
              </View>
            );
          }
        }}
      )}
      </View>

      <StyledView className='mx-auto pt-2 pb-6'>
        <MyButton
          label={"Save Swing Data"}
          theme={"submit"}
          onPress={() => onSubmit()}
        />
      </StyledView>
    </StyledScrollView>
  )
};
