import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Builds from '../components/sideMenu/Builds';
import Transactions from '../components/sideMenu/Transactions/Transactions';
import UsersListingComponent from '../components/admin/UsersListing';
import AccountLanding from '../components/account/AccountLanding';
import Payout from '../components/sideMenu/Payout';
import Store from '../components/sideMenu/Store';
import Contact from '../components/contact/Contact';
import Header from '../components/Header';
import { routeNamesWithBack ,routeNames} from '../utils/Util';
import CalendarComponent from '../components/calendar/Calendar.web';
import DeleteAccountComponent from '../components/minor/DeleteAccount';
import HeaderWithBack from '../components/HeaderWithBack';
import BookingPage from '../components/sideMenu/BookingPage';
import ProfileLanding from '../components/profile/ProfileLanding';
import AiFitterHome from '../components/clubs/AiFitterHome';
import AiCaddie from '../components/caddie/AiCaddie';


const Stack = createStackNavigator();

const DrawerStack = ({ route }) => {
      const { screen ,uid,role,orgName, type} = route.params;
      return (
            <Stack.Navigator
                  initialRouteName={screen} 
                  screenOptions={({ route }) => ({
                    headerShown: routeNamesWithBack.includes(route.name) || routeNames.includes(route.name),
                    header: (props) => {
                       if (routeNames.includes(route.name)) {
                        return (
                          <Header
                            uid={uid}
                            role={role}
                            orgName={orgName}
                            {...props}
                          />
                        );
                        
                      }
                      return null; 
                    },
                    title: 'golf-eq-app', 
                  })}
            >
                   <Stack.Screen
                        name="ManageTeam"
                        component={UsersListingComponent}
                        initialParams={{ type: "UserList",role:role }}
                        options={{
                              header: () => <Header uid={uid} role={role} screenName={route.name}  orgName={orgName}/>,
                              headerShown: true,
                              title: 'golf-eq-app',
                        }}
                        
                  />
                  <Stack.Screen
                        name="Builds"
                        component={Builds}
                  />
                  <Stack.Screen
                        name="manageAccount"
                        component={AccountLanding}
                        initialParams={{uid: uid, role: role, }}
                        options={{
                              header: () => <Header uid={uid} role={role} screenName={route.name}  orgName={orgName} />,
                              headerShown: true,
                              title: 'golf-eq-app',
                        }}
                  />
                  <Stack.Screen
                        name="Transaction"
                        component={Transactions}
                        initialParams={{uid: uid, role: role, }}
                  />
                  <Stack.Screen
                        name="Store"
                        component={Store}
                        initialParams={{uid: uid, role: role, }}
                  />
                  <Stack.Screen
                        name="Payout"
                        component={Payout}
                        initialParams={{uid: uid, role: role, }}
                  />
                  <Stack.Screen
                        name="Contact"
                        component={Contact}
                        initialParams={{uid: uid, role: role, }}
                  />
                   <Stack.Screen
                        name="Booking"
                        component={BookingPage}
                        initialParams={{uid: uid, role: role, }}
                  />
                    <Stack.Screen
                        name="Profile"
                        component={ProfileLanding}
                        initialParams={{role:role,uid:uid}}
                  />
                  <Stack.Screen
                        name="AiFitterHome"
                        component={AiFitterHome}
                  />
                     <Stack.Screen
                        name="AiCaddie"
                        component={AiCaddie}
                  />
                   <Stack.Screen
                        name="CalendarComponent"
                        component={CalendarComponent}
                        initialParams={{uid: uid, role: role, }}
                        options={{
                              header: () => <HeaderWithBack uid={uid} role={role} screenName={route.name}  orgName={orgName} />,
                              headerShown: true,
                              title: 'golf-eq-app',
                        }}
                  />
                  <Stack.Screen
                        name="DeleteAccount"
                        component={DeleteAccountComponent}
                        initialParams={{uid: uid, role: role, }}
                        options={{
                              header: () => <HeaderWithBack uid={uid} role={role} screenName={route.name}  orgName={orgName} />,
                              headerShown: true,
                              title: 'golf-eq-app',
                        }}
                  />
                  
            </Stack.Navigator>
      );
};

export default DrawerStack;
