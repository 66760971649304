import { styled } from "nativewind";
import { StyleSheet, Text, View } from 'react-native';

const StyledView = styled(View);

export default function RecommendedBalls() {
    return (
        <View style={style.container}>
            <StyledView className="my-2">
                <Text style={{fontSize:20}}>Page currently under development, check back soon</Text>
            </StyledView>
        </View>
    )
};

const style = StyleSheet.create({
    container:{ 
        flex: 1, 
        marginBottom: 0, 
        width: '100%',
        backgroundColor:'white',
        paddingLeft:'2%',
        paddingRight:'2%',
        justifyContent: 'center', 
        alignItems: 'center'
    },
})