import { readSavedConfig } from '../../database/ReadData';
import MyButton from '../MyButton';

export default function ClubNumber({ number, label, setSelectedClubNumber }) {

  const handlePress = () => {
    setSelectedClubNumber(number);
  }

  return (
    <MyButton
      label={label}
      theme={"viewClubs"}
      onPress={() => handlePress()}
    />
  )
};
