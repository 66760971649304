import { useCallback, useEffect, useState } from 'react';
import { Alert, Image, Linking, Pressable, Text, View,Platform } from 'react-native';
import { styled } from 'nativewind';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useNavigation } from '@react-navigation/native';
import { textStyle } from './Styles';
import { useCurrentUser } from "./CurrentUserProvider";

const StyledView = styled(View);

const StyledPressable = styled(Pressable);
const StyledText = styled(Text)
const StyledImage = styled(Image);


const HeaderWithBack = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const navigation = useNavigation();
  const {role,orgData } = useCurrentUser();

  const GolfEQURLButton = ({ url, children }) => {
    const handlePress = useCallback(async () => {
      // Checking if the link is supported for links with custom URL scheme.
      const supported = await Linking.canOpenURL(url);

      if (supported) {
        // Opening the link with some app, if the URL scheme is "http" the web link should be opened
        // by some browser in the mobile
        await Linking.openURL(url);
      } else {
        Alert.alert(`Don't know how to open this URL: ${url}`);
      }
    }, [url]);

    return (
      <Pressable onPress={handlePress}>
        {children}
      </Pressable>
    );
  };
  useEffect(() => {
    checkIfAdmin();
  }, []);

  const checkIfAdmin = async () => {
    setUserRole(role)
    setIsAdmin(['provider', 'owner', 'admin', 'contributor', 'viewer'].includes(role) ? true : false)
  }


  return (
    <>
      <StyledView>
        <StyledView
          className='flex flex-row w-full items-center justify-between mb-0 p-4 bg-white'
          style={{
            paddingTop: Platform.OS === 'ios' ? 50 : 20
          }}
        >
          <StyledPressable
            className=''
            onPress={() => navigation.goBack()}
          >
            <MaterialIcons name="arrow-back" color="#000" size={30} />
          </StyledPressable>

          {
            isAdmin == true && (
              <StyledText style={{ marginLeft: 30 }} className={textStyle + " my-2"}>
                {orgData?.name}
              </StyledText>)
          }
          <StyledView
            className=''
          >
            <GolfEQURLButton url="https://golf-eq.com">
              <StyledImage
                className="rounded"
                style={{ width: 80, height: 40 }}
                source={require('../assets/menu-icon-small.png')}
                alt={"logo"}
              />
            </GolfEQURLButton>
          </StyledView>
        </StyledView>
      </StyledView>

    </>
  );
}
export default HeaderWithBack;