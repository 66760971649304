
import React, { useEffect, useState } from 'react';
import { View, Button, Linking, StyleSheet, Platform } from 'react-native';
import BookingWeb from "../booking/Booking.web"
import { fetchLogoutRequest } from '../../store/ducks/app/actions';
import { getItemFromStore } from '../../utils/storage/storage';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '../CurrentUserProvider';

const BookingPage = () => {
  const [userData, setUserData] = useState({})
const dispatch = useDispatch()
const { orgData} = useCurrentUser()

  useEffect(()=>{
    fetchUserId()
  }, [])

  const fetchUserId = async ()=>{
    const userId = await getItemFromStore('userId')
  const firstName = await getItemFromStore('firstName')
  const lastName = await getItemFromStore('lastName')

    if(userId){
  setUserData({userId,firstName,lastName})
    }
  }

  const signMeOut = async () => {
    const refreshToken = await getItemFromStore('refresh')
    dispatch(fetchLogoutRequest({
        refreshToken
    }))
};

  const handlePress = () => {
    const url = orgData?.domain ? "https://" + orgData?.domain : 'https://ttv-booking.golf-eq.com/'
    Linking.canOpenURL(url)
      .then((supported) => {
        if (supported) {
          Linking.openURL(url);
        } else {
          console.log("Don't know how to open URI: " + url);
        }
      })
      .catch((err) => console.error('An error occurred', err));
  };

  return (
    <>
      {Platform.OS === 'web' ? <BookingWeb isLogin={userData?.userId} firstName={userData?.firstName} lastName={userData?.lastName} signMeOut={signMeOut} orgDomain={orgData?.domain}/> : <>
        <View style={styles.container}>
          <Button title="Book Now" onPress={handlePress} />
        </View>
      </>}
    </>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    backgroundColor: 'white'
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
});

export default BookingPage;