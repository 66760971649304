import { styled } from "nativewind";
import { useEffect, useState } from 'react';
import { ActivityIndicator, Image, KeyboardAvoidingView, Platform, Pressable, SafeAreaView, ScrollView, Text, TouchableWithoutFeedback, View } from 'react-native';
import { useDispatch, useSelector } from "react-redux";
import MyButton from '../MyButton';
import MyTextInput from '../MyTextInput';

import * as LocalAuthentication from 'expo-local-authentication';
import { fetchLoginIdle, fetchLoginRequest, fetchRefreshRequest } from '../../store/ducks/app/actions';
import { APIStates } from '../../utils/Strings';
import {
  pageTitleStyle,
  textFunStyle,
  textStyle,
  textStyleXL
} from '../Styles';
import AnalyticsLogger from "../managers/AnalyticsLogger";
import { ScreenNames } from "../../utils/Util";
import { getItemFromStore } from "../../utils/storage/storage";

const StyledPressable = styled(Pressable);
const StyledView = styled(View);
const StyledText = styled(Text);
const StyledImage = styled(Image);

export default function LoginForm({ setShowSignUp, setForgotScreen = null, auth, isCallingFromBooking }) {

  const dispatch = useDispatch()
  const login = useSelector(state => state.app.login)
  const [email, onChangeEmail] = useState("");
  const [password, onChangePassword] = useState("");
  const [hasFaceID, setHasFaceID] = useState(null)

  useEffect(() => {
    AnalyticsLogger.getInstance().logScreenView(ScreenNames.LoginForm)
  }, [])

  useEffect(() => {
    if (login.status == APIStates.FAILURE) {
      dispatch(fetchLoginIdle())
      alert(login.message)
    }
  }, [login.status])

  useEffect(() => {

    const checkFaceIDCapability = async () => {
      const hasBiometric = await LocalAuthentication.hasHardwareAsync();
      const isEnrolled = await LocalAuthentication.isEnrolledAsync();
      
      const barrierToken = await getItemFromStore('token')
      const refreshToken = await getItemFromStore('refresh')
      setHasFaceID(hasBiometric && isEnrolled && barrierToken && refreshToken)
    }

    checkFaceIDCapability()
  }, [])
  
  const authenticateLocally = async () => {
    const result = await LocalAuthentication.authenticateAsync({
      promptMessage: 'Authenticate to login app.', // Optional message
      fallbackLabel: 'Use PIN code', // Optional fallback label
      disableDeviceFallback: false, // Allow device passcode fallback
      cancelLabel: 'Cancel', // Optional cancel label
    });

    if (result.success) {
      const refreshToken = await getItemFromStore('refresh')
      dispatch(fetchRefreshRequest({
        refreshToken: refreshToken
      }))
    } else {
      console.log('Authentication failed');
    }

  }

  const onSubmit = () => {
    if (email == '') {
      alert('Email is required.')
      return
    }
    if (password == '') {
      alert('Password is required.')
      return
    }
    dispatch(fetchLoginRequest({
      email,
      password
    }))

  };

  // TODO: add an image
  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={-100}
      >
        <ScrollView>
          <StyledView className="flex flex-col m-6 h-full">
            {!isCallingFromBooking && (
              <>
            <StyledView
              className='flex flex-row items-center justify-center'
            >
              <StyledImage
                className="rounded"
                style={{ width: 200, height: 150 }}
                source={require('../../assets/icon.png')}
                alt={"logo"}
              />
            </StyledView>
            <StyledText className={pageTitleStyle + " m-2"}>
              Sign in to Golf EQ
            </StyledText>
              </>
            )}
            <MyTextInput
              label={"Email Address"}
              type={"email"}
              value={email}
              onChangeValue={onChangeEmail}
            />

            <MyTextInput
              label={"Password"}
              type={"password"}
              value={password}
              onChangeValue={onChangePassword}
            />


            {
              login.status == APIStates.LOADING ?
                <View className='m-2 flex flex-row items-center justify-center'>
                  <ActivityIndicator size="large" color="rgba(170,218,253,1)" />
                </View>
                :
               
                    <MyButton
                      label={"Login With SwingID"}
                      theme={"submit"}
                      onPress={() => onSubmit()}
                    />
                
                
            }

            {/* Login With Touch/Face ID */}
            {!isCallingFromBooking && (
            <View>
            {
              hasFaceID && <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginVertical: 15 }}>
                <TouchableWithoutFeedback
                  onPress={authenticateLocally}
                >
                  <Image
                    source={require('../../assets/face-recognition.png')}
                    width={50}
                    height={50}
                    marginVertical={5}
                    resizeMode={'contain'}
                  />
                </TouchableWithoutFeedback>
                <Text> FaceID </Text>
              </View>
            }


            <StyledView className='m-2 flex flex-row items-center justify-center'>
              <StyledPressable
                onPress={() => setForgotScreen(true)}
              >
                <StyledText className={textStyleXL}>
                  Forgot Password?
                </StyledText>
              </StyledPressable>
            </StyledView>

            <StyledView className='m-2 flex flex-row items-center justify-center'>
              <StyledText className={textStyle}>
                    Not a member? {' '}
              </StyledText>
              <StyledPressable
                onPress={() => setShowSignUp(true)}
              >
                <StyledText className={textFunStyle}>
                      Create a SwingID
                </StyledText>
              </StyledPressable>
            </StyledView>
            </View>
            )}
          </StyledView>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
