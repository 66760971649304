import { styled } from "nativewind";
import { useEffect, useState } from "react";
import { Platform, Pressable, RefreshControl, ScrollView, StyleSheet, Text, TouchableWithoutFeedback, View } from "react-native";

import MyPicker from "../Picker";
import AdjustmentSetter from "./AdjustmentSetter";
import RecommendedClubs from "./RecommendedClubs";
import WhichClub from "./WhichClub";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecommendationIdle, fetchRecommendationRequest } from "../../store/ducks/app/actions";
import { APIStates } from "../../utils/Strings";
import {
  pageTitleStyle,
  textScreenDescriptionlStyle,
  textStyle,
} from '../Styles';
import AnalyticsLogger from "../managers/AnalyticsLogger";
import { ScreenNames } from "../../utils/Util";
import { getItemFromStore } from "../../utils/storage/storage";
import { useNavigation } from '@react-navigation/native';
import { useCurrentUser } from "../CurrentUserProvider";

const StyledScrollView = styled(ScrollView);
const StyledView = styled(View);
const StyledText = styled(Text);

export default function AiCaddie() {
  const { currentUser } = useCurrentUser();
  const navigation = useNavigation();
  const dispatch = useDispatch()
  const recommendations = useSelector(state => state.app.recommendations);
  const [carryDistances, setCarryDistances] = useState(null);
  const [distanceFromPin, setDistanceFromPin] = useState(150);
  const [clubToUse, setClubToUse] = useState(null);
  const [adjustment, setAdjustment] = useState(100);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pullToRefresh, setPullToRefresh] = useState(false);
  const [isPickerVisible, setIsPickerVisible] = useState(-1)

  useEffect(() => {  
    AnalyticsLogger.getInstance().logScreenView(ScreenNames.AICaddie)
    fetchRecommendation()
  }, []);

  const fetchRecommendation = async () => {
    const userId = await getItemFromStore('userId')
    const _userId = currentUser?.id || userId
    dispatch(fetchRecommendationRequest({
      userId: _userId
    }))
  }

  useEffect(() => {
    if (recommendations.status == APIStates.SUCCESS) {
      let returnResult = {}
      Object.entries(recommendations.data.idealBag || {}).map((entry) => {
        returnResult[entry[1].optimizedCarryDistance] = entry[0];
      });
      setPullToRefresh(false)
      setCarryDistances(returnResult);
      dispatch(fetchRecommendationIdle())
    }else if(recommendations.status == APIStates.FAILURE){
      setPullToRefresh(false)
    }
  }, [recommendations.status])


  useEffect(() => {
    if (carryDistances) {
      WhichClub(carryDistances, distanceFromPin, adjustment, setClubToUse);
    }
  }, [carryDistances, distanceFromPin, adjustment]);

  const onModalOpen = () => {
    setIsModalVisible(true);
  };

  const onModalClose = () => {
    setIsModalVisible(false);
  };

  const numbers = Array.from({ length: 300 }, (_, index) => index + 1);
  const adjustments = Array.from({ length: 61 }, (_, index) => index + 70);

  return (
    <View style={{ flex: 1, marginBottom: 0, width: '100%',backgroundColor:'white',paddingLeft:'3%',paddingRight:'3%'  }}>
      <StyledScrollView
        refreshControl={
          <RefreshControl
            refreshing={recommendations.status == APIStates.LOADING && pullToRefresh}
            onRefresh={() => { 
              setPullToRefresh(true)
              fetchRecommendation() 
            }}
          />
        }
      >

        {/* Page Header */}
        <View style={style.pageHeaderStyle}>
          <StyledText className={pageTitleStyle}>
            AI Caddie
          </StyledText>

          <TouchableWithoutFeedback onPress={() => { navigation.navigate('ViewClubs') }}>
            <Text
              style={style.addNewUserButtonStyle}
            >See AI Fitter</Text>
          </TouchableWithoutFeedback>
        </View>

        {
          currentUser && (
            <StyledView className='flex flex-col'>
              <StyledText className={textScreenDescriptionlStyle + " my-2"}>
                {`User: ${currentUser.name || ""} \nEmail: ${currentUser.email || ""}`}
              </StyledText>
            </StyledView>
          )
        }

        {
          (carryDistances) ?
            <StyledView>
              <RecommendedClubs
                clubToUse={clubToUse}
                carryDistances={carryDistances}
              />
              <MyPicker
                label={"Distance from Pin"}
                pickerItems={numbers}
                selection={distanceFromPin}
                index={1}
                isPickerVisible={isPickerVisible}
                setIsPickerVisible={setIsPickerVisible}
                setSelection={(index, value)=>{
                  Platform.OS == 'ios' ?
                    setDistanceFromPin(value)
                    :
                    setDistanceFromPin(index)
                }}
              />
              <MyPicker
                label={"Adjust AI"}
                pickerItems={adjustments}
                selection={adjustment}
                onModalOpen={onModalOpen}
                index={2}
                isPickerVisible={isPickerVisible}
                setIsPickerVisible={setIsPickerVisible}
                setSelection={(index, value) => {
                  Platform.OS == 'ios' ?
                    setAdjustment(value)
                    :
                    setAdjustment(index)
                }}
              />
              <AdjustmentSetter
                isVisible={isModalVisible}
                onClose={onModalClose}
                title={"Adjust AI"}
                text={"Use this number to adjust the AI recommendation. Values over 100 mean you hit the ball further than the AI recommendation."}
              />
            </StyledView>
            :
            recommendations.status == APIStates.LOADING ?
              <Text>Loading...</Text>
              :
               <Pressable
                  onPress={() => navigation.navigate("SwingDataFrom")}
                >
                  <Text>You may need to adjust your swing data to see results, click here!</Text>
              </Pressable>
        }
      </StyledScrollView>
    </View>
  )

};

const style = StyleSheet.create({
  pageHeaderStyle: { flexDirection: 'row', justifyContent: 'space-between' },
  addNewUserButtonStyle: {
    color: 'rgba(87,164,251,1)',
    fontSize: 20,
    marginTop: 7.5,
     
  }
})