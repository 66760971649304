import { styled } from "nativewind";
import { useEffect, useState } from 'react';
import { Pressable, RefreshControl, ScrollView, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';

import MyButton from '../MyButton';
import ClubData from './ClubData';
import ClubNumber from './ClubNumber';

import { useDispatch, useSelector } from 'react-redux';
import { fetchClubsIdle, fetchClubsRequest, fetchRecommendationRequest, fetchResetFittingResultsIdle, fetchResetFittingResultsRequest } from '../../store/ducks/app/actions';
import { getItemFromStore } from "../../utils/storage/storage";
import { APIStates } from '../../utils/Strings';
import { ScreenNames, recommendedCategories } from "../../utils/Util";
import AnalyticsLogger from "../managers/AnalyticsLogger";
import {
    pageTitleStyle,
    textScreenDescriptionlStyle
} from '../Styles';
import { useNavigation } from '@react-navigation/native';
import { useCurrentUser } from "../CurrentUserProvider";

const StyledView = styled(View);
const StyledText = styled(Text);
const StyledScrollView = styled(ScrollView);

export default function RecommendedClubs({ }) {
    const { currentUser } = useCurrentUser();
    const dispatch = useDispatch()
    const navigation = useNavigation();
    const recommendations = useSelector(state => state.app.recommendations);
    const resetFittingResults = useSelector(state => state.app.resetFittingResults);
    const _clubs = useSelector(state => state.app.clubs);
    const [selectedClubNumber, setSelectedClubNumber] = useState(null);
    const [recommendationsData, setRecommendation] = useState(null);
    const [havingCustomFittngReusults, setHavingCustomFittngReusults] = useState(false);
    const [pullToRefresh, setPullToRefresh] = useState(false);
    const [clubs, setClubs] = useState(null);
    const clubNumbers = [
        ...recommendedCategories
    ]

    useEffect(() => {
        if (selectedClubNumber == 0) {
            navigation.navigate('FittingResults')
        }
    }, [selectedClubNumber]);

    useEffect(() => {
        // if (recommendations.status == APIStates.SUCCESS) {
        //     if (recommendations?.data?.userFittingResults || false) {
        //         setHavingCustomFittngReusults(true)
        //         setRecommendation([])
        //     } else {
        //         setRecommendation(recommendations.data || [])
        //     }
        //     setPullToRefresh(false)
        // } else if (recommendations.status == APIStates.FAILURE) {
        //     setPullToRefresh(false)
        // }
                setRecommendation(recommendations.data || [])

    }, [recommendations.status])

    useEffect(() => {
        if (resetFittingResults.status == APIStates.SUCCESS) {
            // After reseting custom FittingResults to defaults again fetching recommendations.
            setHavingCustomFittngReusults(false)
            dispatch(fetchResetFittingResultsIdle())
        } else if (resetFittingResults.status == APIStates.FAILURE) {
            alert('Something went wrong, Please try again reseting values to default.')
            dispatch(fetchResetFittingResultsIdle())
        }
    }, [resetFittingResults.status])

    useEffect(() => {
        fetchClubs()
    }, [selectedClubNumber])

    const fetchClubs = async () => {
        const userId = await getItemFromStore('userId')
        const _userId = currentUser?.id || userId
        dispatch(fetchClubsRequest({
            userId: _userId,
            uids: recommendationsData[selectedClubNumber]?.uids || []
        }))
    }

    useEffect(() => {
        if (_clubs.status == APIStates.SUCCESS) {
            setClubs(_clubs.data)
            dispatch(fetchClubsIdle())
        }
    }, [_clubs.status])

    const resetFittingResultsToDefault = async () => {
        const userId = await getItemFromStore('userId')
        const _userId = currentUser?.id || userId
        dispatch(fetchResetFittingResultsRequest({
            userId: _userId,
        }))
    }

    return (
        <StyledScrollView style={{ flex: 1, marginBottom: 0, width: '100%' ,backgroundColor:'white',paddingLeft:'2%',paddingRight:'2%'}}
            refreshControl={
                <RefreshControl
                    refreshing={recommendations.status == APIStates.LOADING && pullToRefresh}
                    onRefresh={() => {
                        setPullToRefresh(true)
                    }}
                />
            }
        >
            <StyledView className="my-2">

                {/* Page Header */}
                <View style={style.pageHeaderStyle}>
                    <StyledText className={pageTitleStyle}>
                        Clubs
                    </StyledText>

                    <TouchableWithoutFeedback onPress={() => { navigation.navigate('AiCaddie') }}>
                        <Text
                            style={style.addNewUserButtonStyle}
                        >See AI Caddie</Text>
                    </TouchableWithoutFeedback>
                </View>

                <StyledText className={textScreenDescriptionlStyle}>
                    Click on one of the following links to see which clubs would be good fits for you.
                </StyledText>
            </StyledView>
            <View>
                {recommendations.status == APIStates.LOADING ? <Text>Loading...</Text> : (recommendationsData === null) ?
                    <View>
                        <Pressable
                            onPress={() => navigation.navigate("SwingDataFrom")}
                        >
                            <Text>Looks like you need to enter your swing data, click here!</Text>
                        </Pressable>
                    </View>
                    :
                    <>
                        {havingCustomFittngReusults ?
                            <View>
                                <TouchableWithoutFeedback onPress={() => resetFittingResultsToDefault()}>
                                    <Text>You may need to adjust your custom fitting results to see results!
                                        <Text style={style.resetToDefaultsButton}> Return to Default Values </Text>
                                    </Text>
                                </TouchableWithoutFeedback>
                            </View>
                            :
                            (recommendationsData && recommendationsData.length === 0) ?
                                <View>
                                    <Pressable
                                        onPress={() => navigation.navigate("SwingDataFrom")}
                                    >
                                        <Text>You may need to adjust your swing data to see results, click here!</Text>
                                    </Pressable>
                                </View>
                                :
                                <View>
                                    {(selectedClubNumber) ?
                                        <ScrollView>
                                            <MyButton
                                                label={"Club List"}
                                                theme={"viewClubs"}
                                                onPress={() => setSelectedClubNumber(null)}
                                            />
                                            {
                                                _clubs.status == APIStates.LOADING ?
                                                    <Text>Loading...</Text>
                                                    :
                                                    (clubs || []).map((club) => {
                                                        return (
                                                            <View key={club.uid}>
                                                                <ClubData
                                                                    clubData={club}
                                                                    selectedClubNumber={selectedClubNumber}
                                                                />
                                                            </View>
                                                        )
                                                    })}
                                        </ScrollView>
                                        :
                                        <>
                                            {clubNumbers.map((data) => {
                                                return (
                                                    <ClubNumber
                                                        key={data.number}
                                                        number={data.number}
                                                        label={data.label}
                                                        setSelectedClubNumber={setSelectedClubNumber}
                                                    />
                                                )
                                            })}
                                        </>
                                    }
                                </View>
                        }
                    </>
                }
                {
                    selectedClubNumber === 14 && navigation.navigate("Putter")
                }
                 {
                    selectedClubNumber === 15 && navigation.navigate("GolfBall")
                }
            </View>
        </StyledScrollView>
    )
};

const style = StyleSheet.create({
    pageHeaderStyle: { flexDirection: 'row', justifyContent: 'space-between' },
    addNewUserButtonStyle: {
        color: 'rgba(87,164,251,1)',
        fontSize: 20,
        marginTop: 7.5,
         
    },
    resetToDefaultsButton: {
        color: 'rgba(87,164,251,1)',
        fontSize: 15,
    }
})