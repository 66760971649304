// useResponsiveValues.js
import { useBreakpointValue } from "native-base";
import { Dimensions } from "react-native";

const width = Dimensions.get("window").width;
const height = Dimensions.get("window").height;

export const useResponsiveValues = () => {
    const numColumns = useBreakpointValue({
        base: 1, // for small screens
        sm: 1,   // for small to medium screens
        md: 2,   // for medium screens
        lg: 2,   // for large screens
        xl: 3,   // for extra large screens
    });
    const Width = useBreakpointValue({
        base: width * 0.65,
        sm: width * 0.4,
        md: width * 0.3,
        lg: width * 0.3,
        xl: width * 0.18,
    });
    const containerWidth = useBreakpointValue({
        base: width * 0.9,
        sm: width * 0.8,
        md: width * 0.8,
        lg: width * 0.8,
        xl: width * 0.8,
    });
    const modelWidth = useBreakpointValue({
        base: width * 0.7,
        sm: width * 0.7,
        md: width * 0.4,
        lg: width * 0.3,
        xl: width * 0.3,
    });
    const loginModelWidth = useBreakpointValue({
        base: width * 0.95,
        sm: width * 0.8,
        md: width * 0.5,
        lg: width * 0.5,
        xl: width * 0.5,
    });
    const inputsWidth = useBreakpointValue({
        base: width * 0.7,
        sm: width * 0.7,
        md: width * 0.62,
        lg: width * 0.6

    });
    const fontSize = useBreakpointValue({
        base: width * 0.06,
        sm: width * 0.05,
        md: width * 0.04,
        lg: width * 0.03,
        xl: width * 0.025

    });
    const marginTop = useBreakpointValue({
        base: width * 0.02,
        sm: width * 0.02,
        md: width * 0.09,
        lg: width * 0.06,
        xl: width * 0.05,
    });
    const isSmallScreen = useBreakpointValue({
        base: true,
        sm: true,
        md: false,
        lg: false,
        xl: false,
    });
    const flexDirection = useBreakpointValue({
        base: "column",
        sm: "column",
        md: "row",
        lg: "row",
        xl: "row",
    });
    const Top = useBreakpointValue({
        base: height * .03,
        sm: height * .033,
        md: height * .06,
        lg: height * .14,
        xl: height * 1,
    });
    const Right = useBreakpointValue({
        base: width * .6,
        sm: width * .62,
        md: width * .67,
        lg: width * .7,
        xl: width * .7,
    });
    const screenDivider = useBreakpointValue({
        base: false,
        sm: false,
        md: true,
        lg: true,
        xl: true,
    });

    const screenPosition = useBreakpointValue({
        base: "relative",
        sm: "relative",
        md: "absolute",
        lg: "absolute",
        xl: "absolute",
    });
    const screenBlock = useBreakpointValue({
        base: 2,
        sm: 2,
        md: 1,
        lg: 1,
        xl: 1,
    });
    const topBlock = useBreakpointValue({
        base: .25,
        sm: .25,
        md: .5,
        lg: .5,
        xl: .5,
    });
    const rightBlock = useBreakpointValue({
        base: .5,
        sm: .5,
        md: 1,
        lg: 1,
        xl: 1,
    });
    const buttonAlignment = useBreakpointValue({
        base:30,
        sm: 30,
        md: 20,
        lg: 10,
        xl: 10,
    })

    return { numColumns, Width, modelWidth, containerWidth, loginModelWidth, inputsWidth, fontSize, marginTop, isSmallScreen, flexDirection, Top, Right, screenDivider, screenPosition, screenBlock, topBlock, buttonAlignment,rightBlock };
};
