import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Dimensions,ActivityIndicator } from 'react-native';

const width = Dimensions.get("window").width;
const height = Dimensions.get("window").height

const NoteComponent = ({ updateOrders, updatedNote ,notes,isLoading }) => {
    const handleNoteChange = (text) => {
        updatedNote(text); 
    };
    return (
        <View style={styles.noteContainer}>
            <View style={{ width: "80%", flex: 3 }}>
                <TextInput
                    style={styles.notesInput}
                    placeholder="Notes..."
                    multiline
                    value={notes}
                    onChangeText={handleNoteChange}
                />
            </View>
           
            <View style={styles.btnContainer}>
                <TouchableOpacity style={styles.button} onPress={updateOrders}>
                {isLoading ? (
              <View className="m-2 flex flex-row items-center justify-center">
                <ActivityIndicator size="small" color="rgba(170,218,253,1)" />
              </View>
            ) :(
                    <Text style={styles.buttonText}>
                        Update order
                    </Text>
                       )}
                </TouchableOpacity>
            </View>
         
        </View>
    );
};

const styles = StyleSheet.create({

    noteContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 'auto'
    },
    label: {
        fontSize: 14,
        fontWeight: '500',
    },
    value: {
        fontSize: 14,
    },
    notesInput: {
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 10,
        marginVertical: 20,
        borderRadius: 5,
        height: 60,
    },
    button: {
        width: '60%',
        backgroundColor: '#3758f9',
        padding: 15,
        borderRadius: 5,
        alignItems: 'center',
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
    },
    divider: {
        marginVertical: 15,
        borderBottomColor: 'lightgrey',
        borderWidth: .25
    },
    btnContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default NoteComponent;
