import axios from 'axios';
import { BASE_URL } from '../utils/EndPoints';
import { getItemFromStore } from '../utils/storage/storage';

const maxRetries = 5;
const timeout = 500;
class Api {
    static get(route, params) {
        return this.xhr(route, params, 'GET');
    }

    static put(route, params) {
        return this.xhr(route, params, 'PUT');
    }

    static post(route, params) {
        return this.xhr(route, params, 'POST');
    }

    static post(route, params, body) {
        return this.xhr(route, params, 'POST', body);
    }

    static patch(route, params, body) {
        return this.xhr(route, params, 'PATCH', body);
    }

    static delete(route, params) {
        return this.xhr(route, params, 'DELETE');
    }

    static async xhr(route, params, verb, body = null, currentEntry = 1) {
        const url = `${BASE_URL}${route}`
        console.log(' URL ', url)
        var options;
        if (body == null) {
            options = Object.assign({ method: verb }, params ? { params } : null);
        } else {
            options = Object.assign({ method: verb }, params ? { params } : null, { data: body });
        }
        const token = await getItemFromStore('token');
        options.responseType = 'json'
        options.headers = {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token ? token : ''}`,
        };

        // console.log('=================================')
        // console.log(' url.url ', url)
        // console.log(' options.headers ', JSON.stringify(options.headers))
        // console.log(' body ', JSON.stringify(body))
        return axios(url, options).then((resp) => {
            // console.log(' resp ', JSON.stringify(resp.data))
            return resp.data;
        }).catch(async (error) => {
            if (error.code == "ERR_NETWORK" && currentEntry <= maxRetries) {
                await new Promise((resolve) => setTimeout(resolve, timeout));
                return this.xhr(route, params, verb, body = null, ++currentEntry)
            }
            throw error.response.data;
        })
    };
};

export default Api;
