import Checkbox from 'expo-checkbox';
import { styled } from "nativewind";
import { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, Dimensions, KeyboardAvoidingView, ScrollView, Text, View, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUpdateUserIdle, fetchUpdateUserRequest, fetchUserProfileRequest } from '../../store/ducks/app/actions';
import { APIStates } from '../../utils/Strings';
import { WITBInputs } from "../../utils/Util";
import MyButton from '../MyButton';
import MyTextInput from '../MyTextInput';
import MyPicker from '../Picker';
import TagsPicker from "../TagsPicker";
import { useCurrentUser } from "../CurrentUserProvider";
const StyledView = styled(View);
const StyledText = styled(Text);
const StyledScrollView = styled(ScrollView);

export default function WITB({ }) {
    const { currentUser,role,uid } = useCurrentUser();
    const dispatch = useDispatch()
    const updateUser = useSelector(state => state.app.updateUser);
    const getProfile = useSelector(state => state.app.getProfile);
    const [isPickerVisible, setIsPickerVisible] = useState(-1)
    const [hasIronData, setHasIronData] = useState(null);
    const [inputs, setInputs] = useState(WITBInputs)

    const scrollRef = useRef(null)
    
    useEffect(() => {
      if(getProfile.status == APIStates.SUCCESS){
        populateDataToInputs(getProfile.data)
      }else if (getProfile.status == APIStates.FAILURE){
        alert(getProfile.message)
      }
    }, [getProfile.status])
    
    useEffect(() => {
        if (updateUser.status == APIStates.SUCCESS){
           dispatch(fetchUpdateUserIdle())
           alert('Data updated successfully')
        } else if (updateUser.status == APIStates.FAILURE){
            alert(updateUser.message)
            dispatch(fetchUpdateUserIdle())
        }
    }, [updateUser.status])

    function onSubmit() {

        const _userId = currentUser?.id || uid
        let formJson = {
            userId: _userId,
        }

        inputs.forEach((input) => {
            if(input.selection != null && input.selection != '' && input.selection != undefined) {
                formJson[`${input.name.toLowerCase()}`] = input.selection;
            }
        })

        dispatch(fetchUpdateUserRequest(formJson))
    }

    const populateDataToInputs = (data) => {
        inputs.forEach((input) => {
            if (data[input.name.toLowerCase()]) {
                input.selection = data[input.name.toLowerCase()]
            }
        })
        setInputs([...inputs])
    }

    const setCurrentSelected = (index) => {
        // scrollRef.current.scrollTo({ x: 0, y: index * Dimensions.get('screen').height * 0.15 - 100, animated: true })
    }

    const setSelection = (index, value) => {
        const _index = inputs.findIndex((input) => input.index == index)
        inputs[_index].selection = value
    }

    const setInputValue = (index, value) => {
        const _index = inputs.findIndex((input) => input.index == index)
        inputs[_index].selection = value
        setInputs([...inputs])
    }

    return (
        <ScrollView style={{ marginBottom: 300 }}>
                {inputs.map((data) => {
                    if (data.list) {
                        return (
                            <StyledView
                                key={data.name}
                            >
                                <MyPicker
                                    index={data.index}
                                    key={data.name}
                                    label={data.label.toString()}
                                    name={data.name}
                                    pickerItems={data.pickerItems}
                                    selection={data.selection}
                                    setSelection={setSelection}
                                    setCurrentSelectedIndex={setCurrentSelected}
                                    isPickerVisible={isPickerVisible}
                                    setIsPickerVisible={setIsPickerVisible}
                                />
                            </StyledView>
                        );
                    } else {
                        if (data.checkbox) {
                            return (
                                <StyledView
                                    style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 10 }}
                                    key={data.name}
                                >
                                    <Checkbox
                                        value={hasIronData}
                                        onValueChange={setHasIronData}
                                        color='#4B5563'
                                        style={{ alignSelf: 'center' }}
                                    />
                                    <StyledText className='text-gray-900 text-lg capitalize mx-2'>{data.label}</StyledText>
                                </StyledView>
                            )
                        } else if (data.tags) {
                            return (
                                <View key={data.name}>
                                    <TagsPicker
                                        defaultSelected={data.selection}
                                        index={data.index}
                                        tags={data.pickerItems}
                                        setSelection={setSelection}
                                    />
                                </View>)
                        } else {
                            return (
                                <View key={data.name}>
                                    <MyTextInput
                                        key={data.name}
                                        label={data.label}
                                        type={data.type}
                                        value={data.selection}
                                        keyboardType={'default'}
                                        onChangeValue={(value)=> {
                                            setInputValue(data.index, value)
                                        }}
                                    />
                                </View>
                            );
                        }
                    }
                }
                )}

            
            {
                role == 'viewer' ? null : (
                updateUser.status == APIStates.LOADING ?
                    <View className='m-2 flex flex-row items-center justify-center'>
                        <ActivityIndicator size="large" color="rgba(170,218,253,1)" />
                    </View>
                    :
                    <MyButton
                        label={"Save Profile Data"}
                        theme={"submit"}
                        onPress={() => onSubmit()}
                    />)
            }

        </ScrollView>
    )
};
