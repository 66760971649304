import { put, takeLatest } from "redux-saga/effects";
import * as actions from './actions';
import * as constants from './constants';
import Api from "../../../network/api";
import { CLUBS_URL, EXPORT_USERS_URL, FORGOT_PASSWORD_URL, LOGIN_URL, LOGOUT_URL, RECOMMENDATION_URL, REFRESH_TOKENS_URL, REGISTER_URL, RESET_FITTING_RESULTS_URL, RESET_PASSWORD_URL, RE_AUTHENTICATE_URL, SWING_URL, UPDATE_PASSWORD, USER_FITTING_RESULTS_URL, USER_URL, PAYMENTS_URL,SERVICES_URL, SAVE_BOOKINGS, GET_TIMESLOTS, CANCEL_BOOKINGS, SAVE_TOKENS, SYNC_CALENDAR, EXPORT_TRANSACTIONS, FETCH_TRANSACTIONS, EXPORT_ORDER, CREATE_ORDER, FETCH_ORDERS, UPDATE_ORDER } from "../../../utils/EndPoints";

function* fetchLoginRequest(req) {
    try {
        var response = yield Api.post(LOGIN_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchLoginSuccess({ data: response }));
        } else {
            yield put(actions.fetchLoginFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchLoginFailure({ message: error.message }));
    }
}

function* fetchRegisterRequest(req) {
    try {
        var response = yield Api.post(REGISTER_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchRegisterSuccess({ data: response }));
        } else {
            yield put(actions.fetchRegisterFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchRegisterFailure({ message: error.message }));
    }
}

function* fetchLogoutRequest(req) {
    try {
        var response = yield Api.post(LOGOUT_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchLogoutSuccess({ data: response }));
        } else {
            yield put(actions.fetchLogoutFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchLogoutFailure({ message: error.message }));
    }
}

function* saveTokensRequest(req) {
    try {
        var response = yield Api.post(SAVE_TOKENS, {}, req.payload);
        if (response) {
            yield put(actions.saveTokensSuccess({ data: response }));
        } else {
            yield put(actions.saveTokensFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.saveTokensFailure({ message: error.message }));
    }
}

function* syncCalendarRequest(req) {
    try {
        var response = yield Api.post(SYNC_CALENDAR, {}, req.payload);
        if (response) {
            yield put(actions.syncCalendarSuccess({ data: response }));
        } else {
            yield put(actions.syncCalendarFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.syncCalendarFailure({ message: error.message }));
    }
}

function* exportTransactionsRequest(req) {
    try {
        var response = yield Api.post(EXPORT_TRANSACTIONS, {}, req.payload);
        if (response) {
            yield put(actions.exportTransactionsSuccess({ data: response }));
        } else {
            yield put(actions.exportTransactionsFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.exportTransactionsFailure({ message: error.message }));
    }
}

function* fetchTransactionsRequest(req) {
    try {
        var response = yield Api.post(FETCH_TRANSACTIONS, {}, req.payload);
        if (response) {
            yield put(actions.fetchTransactionsSuccess({ data: response }));
        } else {
            yield put(actions.fetchTransactionsFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchTransactionsFailure({ message: error.message }));
    }
}

function* fetchRefreshRequest(req) {
    try {
        var response = yield Api.post(REFRESH_TOKENS_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchRefreshSuccess({ data: response }));
        } else {
            yield put(actions.fetchRefreshFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchRefreshFailure({ message: error.message }));
    }
}

function* fetchDeleteUserRequest(req) {
    try {
        var response = yield Api.delete(`${USER_URL}/${req.payload.userId}`);
        if (response) {
            yield put(actions.fetchDeleteUserSuccess({ data: response }));
        } else {
            yield put(actions.fetchDeleteUserFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchDeleteUserFailure({ message: error.message }));
    }
}

function* fetchReAuthRequest(req) {
    try {
        var response = yield Api.post(RE_AUTHENTICATE_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchReAuthSuccess({ data: response }));
        } else {
            yield put(actions.fetchReAuthFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchReAuthFailure({ message: error.message }));
    }
}

function* fetchGetSwingRequest(req) {
    try {
        var response = yield Api.get(`${SWING_URL}/${req.payload.userId}`);
        if (response) {
            yield put(actions.fetchGetSwingSuccess({ data: response }));
        } else {
            yield put(actions.fetchGetSwingFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchGetSwingFailure({ message: error.message }));
    }
}

function* fetchPostSwingRequest(req) {
    try {
        var response = yield Api.post(SWING_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchPostSwingSuccess({ data: response }));
        } else {
            yield put(actions.fetchPostSwingFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchPostSwingFailure({ message: error.message }));
    }
}

function* fetchUsersRequest(req) {
    try {
        let URL = `${USER_URL}?orgId=${req.payload.orgId}&limit=${Number(req.payload.limit)}&page=${Number(req.payload.page)}&sortBy=createdAt:desc`
        req.payload.role && (URL = URL + `&role[]=${req.payload.role}`)
        req.payload.name && (URL = URL + `&name=${req.payload.name}`)
        var response = yield Api.get(URL);
        if (response) {
            yield put(actions.fetchUsersSuccess({ data: response }));
        } else {
            yield put(actions.fetchUsersFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchUsersFailure({ message: error.message }));
    }
}

function* fetchCreateUserRequest(req) {
    try {
        var response = yield Api.post(USER_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchCreateUserSuccess({ data: response }));
        } else {
            yield put(actions.fetchCreateUserFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchCreateUserFailure({ message: error.message }));
    }
}

function* fetchUpdatePasswordRequest(req) {
    try {
        var response = yield Api.post(UPDATE_PASSWORD, {}, req.payload);
        if (response) {
            yield put(actions.fetchUpdatePasswordSuccess({ data: response }));
        } else {
            yield put(actions.fetchUpdatePasswordFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchUpdatePasswordFailure({ message: error.message }));
    }
}

function* fetchRecommendationRequest(req) {
    try {
        var response = yield Api.get(`${RECOMMENDATION_URL}/${req.payload.userId}`);
        if (response) {
            yield put(actions.fetchRecommendationSuccess({ data: response }));
        } else {
            yield put(actions.fetchRecommendationFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchRecommendationFailure({ message: error.message }));
    }
}

function* fetchClubsRequest(req) {
    try {
        var response = yield Api.post(CLUBS_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchClubsSuccess({ data: response }));
        } else {
            yield put(actions.fetchClubsFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchClubsFailure({ message: error.message }));
    }
}

function* fetchExportUsersRequest(req) {
    try {
        var response = yield Api.post(EXPORT_USERS_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchExportUsersSuccess({ data: response }));
        } else {
            yield put(actions.fetchExportUsersFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchExportUsersFailure({ message: error.message }));
    }
}

function* fetchForgotPasswordRequest(req) {
    try {
        var response = yield Api.post(FORGOT_PASSWORD_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchForgotPasswordSuccess({ data: response }));
        } else {
            yield put(actions.fetchForgotPasswordFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchForgotPasswordFailure({ message: error.message }));
    }
}

function* fetchResetPasswordRequest(req) {
    try {
        var response = yield Api.post(`${RESET_PASSWORD_URL}`, {}, req.payload);
        if (response) {
            yield put(actions.fetchResetPasswordSuccess({ data: response }));
        } else {
            yield put(actions.fetchResetPasswordFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchResetPasswordFailure({ message: error.message }));
    }
}

function* fetchUpdateUserRequest(req) {
    try {
        var response = yield Api.patch(`${USER_URL}/${req.payload.userId}`, {}, req.payload);
        if (response) {
            yield put(actions.fetchUpdateUserSuccess({ data: response }));
        } else {
            yield put(actions.fetchUpdateUserFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchUpdateUserFailure({ message: error.message }));
    }
}

function* fetchUserProfileRequest(req) {
    try {
        var response = yield Api.get(`${USER_URL}/${req.payload.userId}`);
        if (response) {
            yield put(actions.fetchUserProfileSuccess({ data: response }));
        } else {
            yield put(actions.fetchUserProfileFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchUserProfileFailure({ message: error.message }));
    }
}

function* fetchPostUserFittingResultsRequest(req) {
    try {
        var response = yield Api.post(USER_FITTING_RESULTS_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchPostUserFittingResultsSuccess({ data: response }));
        } else {
            yield put(actions.fetchPostUserFittingResultsFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchPostUserFittingResultsFailure({ message: error.message }));
    }
}

function* fetchResetFittingResultsRequest(req) {
    try {
        var response = yield Api.post(RESET_FITTING_RESULTS_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchResetFittingResultsSuccess({ data: response }));
        } else {
            yield put(actions.fetchResetFittingResultsFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchResetFittingResultsFailure({ message: error.message }));
    }
}
function* fetchCheckOut(req) {
    try {
        var response = yield Api.post(PAYMENTS_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchCheckoutSuccess({ data: response }));
        } else {
            yield put(actions.fetchCheckoutFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchCheckoutFailure({ message: error.message }));
    }
}

function* saveBookings(req) {
    try {
        var response = yield Api.post(SAVE_BOOKINGS, {}, req.payload);
        if (response) {
            yield put(actions.saveBookingSuccess({ data: response }));
        } else {
            yield put(actions.saveBookingFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.saveBookingFailure({ message: error.message }));
    }
}

function* cancelBookings(req) {
    try {
        var response = yield Api.post(CANCEL_BOOKINGS, {}, req.payload);
        if (response) {
            yield put(actions.cancelBookingSuccess({ data: response }));
        } else {
            yield put(actions.cancelBookingFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.cancelBookingFailure({ message: error.message }));
    }
}

function* getTimeSlots(req) {
    try {
        var response = yield Api.post(GET_TIMESLOTS, {}, req.payload);
        if (response) {
            yield put(actions.getTimeSlotsSuccess({ data: response }));
        } else {
            yield put(actions.getTimeSlotsFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.getTimeSlotsFailure({ message: error.message }));
    }
}

function* fetchServices(req) {
    try {
        var response = yield Api.post(SERVICES_URL, {}, req.payload);
        if (response) {
            yield put(actions.fetchServicesSuccess({ data: response }));
        } else {
            yield put(actions.fetchServicesFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchServicesFailure({ message: error.message }));
    }
}

function* exportOrders(req) {
    try {
        var response = yield Api.post(EXPORT_ORDER, {}, req.payload);
        if (response) {
            yield put(actions.exportOrdersSuccess({ data: response }));
        } else {
            yield put(actions.exportOrdersFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.exportOrdersFailure({ message: error.message }));
    }
}

function* fetchOrders(req) {
    try {
        var response = yield Api.post(FETCH_ORDERS, {}, req.payload);
        if (response) {
            yield put(actions.fetchOrdersSuccess({ data: response }));
        } else {
            yield put(actions.fetchOrdersFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.fetchOrdersFailure({ message: error.message }));
    }
}
function* createOrders(req) {
    try {
        var response = yield Api.post(CREATE_ORDER, {}, req.payload);
        if (response) {
            yield put(actions.createOrdersSuccess({ data: response }));
        } else {
            yield put(actions.createOrdersFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.createOrdersFailure({ message: error.message }));
    }
}
function* updateOrders(req) {
    try {
        var response = yield Api.post(UPDATE_ORDER, {}, req.payload);
        if (response) {
            yield put(actions.updateOrdersSuccess({ data: response }));
        } else {
            yield put(actions.updateOrdersFailure({ message: response.message }));
        }
    } catch (error) {
        yield put(actions.updateOrdersFailure({ message: error.message }));
    }
}


export default () => [
    takeLatest(constants.FETCH_LOGIN_REQUEST, fetchLoginRequest),
    takeLatest(constants.FETCH_REGISTER_REQUEST, fetchRegisterRequest),
    takeLatest(constants.FETCH_LOGOUT_REQUEST, fetchLogoutRequest),
    takeLatest(constants.SAVE_TOKENS_REQUEST, saveTokensRequest),
    takeLatest(constants.SYNC_CALENDAR_REQUEST, syncCalendarRequest),
    takeLatest(constants.EXPORT_TRANSACTIONS_REQUEST, exportTransactionsRequest),
    takeLatest(constants.FETCH_TRANSACTIONS_REQUEST, fetchTransactionsRequest),
    takeLatest(constants.FETCH_REFRESH_REQUEST, fetchRefreshRequest),
    takeLatest(constants.FETCH_DELETE_USER_REQUEST, fetchDeleteUserRequest),
    takeLatest(constants.FETCH_REAUTH_REQUEST, fetchReAuthRequest),
    takeLatest(constants.FETCH_GET_SWING_REQUEST, fetchGetSwingRequest),
    takeLatest(constants.FETCH_POST_SWING_REQUEST, fetchPostSwingRequest),
    takeLatest(constants.FETCH_USERS_REQUEST, fetchUsersRequest),
    takeLatest(constants.FETCH_CREATE_USER_REQUEST, fetchCreateUserRequest),
    takeLatest(constants.FETCH_UPDATE_PASSWORD_REQUEST, fetchUpdatePasswordRequest),
    takeLatest(constants.FETCH_RECOMMENDATION_REQUEST, fetchRecommendationRequest),
    takeLatest(constants.FETCH_CLUBS_REQUEST, fetchClubsRequest),
    takeLatest(constants.FETCH_EXPORT_USERS_REQUEST, fetchExportUsersRequest),      
    takeLatest(constants.FETCH_FORGOT_PASSWORD_REQUEST, fetchForgotPasswordRequest),
    takeLatest(constants.FETCH_RESET_PASSWORD_REQUEST, fetchResetPasswordRequest),
    takeLatest(constants.FETCH_UPDATE_USER_REQUEST, fetchUpdateUserRequest),
    takeLatest(constants.FETCH_USER_PROFILE_REQUEST, fetchUserProfileRequest),
    takeLatest(constants.FETCH_POST_USER_FITTING_RESULTS_REQUEST, fetchPostUserFittingResultsRequest),
    takeLatest(constants.FETCH_RESET_FITTING_RESULTS_REQUEST, fetchResetFittingResultsRequest),
    takeLatest(constants.FETCH_CHECKOUT_REQUEST, fetchCheckOut),
    takeLatest(constants.SAVE_BOOKINGS_REQUEST, saveBookings),
    takeLatest(constants.CANCEL_BOOKINGS_REQUEST, cancelBookings),
    takeLatest(constants.FETCH_SERVICES_REQUEST, fetchServices),
    takeLatest(constants.GET_TIMESLOTS_REQUEST, getTimeSlots),
    takeLatest(constants.EXPORT_ORDERS_REQUEST, exportOrders),
    takeLatest(constants.FETCH_ORDERS_REQUEST, fetchOrders),
    takeLatest(constants.CREATE_ORDERS_REQUEST, createOrders),
    takeLatest(constants.UPDATE_ORDERS_REQUEST, updateOrders),

];