import { View, Text, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, Modal } from "react-native";
import dayjs from "dayjs";

export default function SuccessModal({ modalVisible, handleOutsideClick, modelWidth, description, title, btnLabel1, btnLabel2,status,date }) {
    const containerStyle = btnLabel1 && btnLabel2 ? styles.buttonContainer : styles.buttonOKContainer;
    return (

        <Modal
            transparent={true}
            visible={modalVisible}
            animationType="slide"
        >

            <TouchableWithoutFeedback onPress={handleOutsideClick}>
                <View style={styles.modalBackground}>
                    <View style={[styles.modalContainer, { width: modelWidth }]}>
                        <Text style={styles.modalTitle}>{title}</Text>
                        <Text style={styles.modalMessage}>{description}</Text>
                        <View style={styles.valueContainer} >
                            <Text style={styles.title}>Status: </Text>
                            <Text style={styles.title}>{status}</Text>
                        </View>
                        <View style={styles.valueContainer}>
                            <Text style={styles.title}>Expected Date to Deliver:</Text>
                            <Text style={styles.title}>{" "}{dayjs(date).format("DD MMM YYYY")}</Text>
                        </View>
                        <View style={containerStyle}>

                            {btnLabel1 && (
                                <TouchableOpacity style={[styles.mButton, { marginRight: 20 }]}
                                    onPress={handleOutsideClick}>
                                    <Text style={styles.modalButton}>{btnLabel1}</Text>
                                </TouchableOpacity>
                            )}
                        </View>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        </Modal>

    );
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: "#007dba",
        paddingVertical: 15,
        alignItems: "center",
        width: "50%",
        marginBottom: 10,
    },
    mButton: {
        backgroundColor: "#007dba",
        paddingVertical: 10,
        alignItems: "center",
        width: "40%",
        marginTop: 8,
    },
    okButton: {
        backgroundColor: "#007dba",
        paddingVertical: 15,
        alignItems: "center",
        width: "40%",
        marginBottom: 10,
        justifyContent: 'center'
    },
    buttonText: {
        color: "white",
        fontSize: 16,
    },
    modalButton: {
        color: "white",
        fontSize: 16,
        fontWeight: 'bold'
    },
    modalBackground: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {

        padding: 50,
        backgroundColor: 'white',
        borderRadius: 10,
    },
    modalTitle: {
        fontSize: 23,
        fontWeight: 'bold',
    },
    modalMessage: {
        fontSize: 20,
        marginVertical: 10,
        textAlign: 'center',
        marginBottom: 20,
        alignItems:'center',
        fontWeight:'bold'
    },
    conformationMessage: {
        fontSize: 20,
        marginVertical: 10,
        textAlign: 'center',
        marginBottom: 20
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    buttonOKContainer: {
        flex: 1,
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
    },
    conformationContainer: {
        width: '100%',
        padding: 50,
        backgroundColor: 'white',
        borderRadius: 10,
        alignItems: 'center',
    },
    okContainer: {
        flex: 1,
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',

    },
    title:{
        fontSize:15,
        fontWeight:'600'
    },
    valueContainer:{
        flex:1,
        flexDirection:'row',
        marginBottom:5,
    }
});
