import React from "react";
import { View, Text, StyleSheet, Dimensions, Platform, TouchableOpacity,ActivityIndicator } from "react-native";
import { pageTitleStyle, textScreenDescriptionlStyle } from '../Styles';
import { styled } from "nativewind";
import { useDispatch, useSelector } from 'react-redux';

const StyledText = styled(Text);
const StyledView = styled(View);
const width = Dimensions.get("window").width;
const height = Dimensions.get("window").height



const HeaderButton = ({ title, btn1, btn2, description, onExport, onOrdersExport }) => {
    const exportData = useSelector(state => state.app.exportOrders);

    return (
        <View>
            <View style={style.pageHeaderStyle}>
                <StyledText className={pageTitleStyle}>
                    {title}
                </StyledText>

                {/* Buttons Add & Export Trannsactions*/}

                <StyledView style={{ flexDirection: 'row', marginTop: 20 }}>
                    <TouchableOpacity
                        style={style.loginBtn}
                        onPress={btn2 === "Export Transactions" ? onExport : onOrdersExport}
                    >  {exportData?.status === "loading" ?
                        <View className="m-2 flex flex-row items-center justify-center">
                            <ActivityIndicator size="small" color="rgba(170,218,253,1)" />
                        </View>
                        :
                        <Text
                            style={{
                                color: "white",
                                fontSize: 15,
                                fontWeight: "700",
                            }}
                        >
                            {btn2}
                        </Text>
                        }

                    </TouchableOpacity>
                </StyledView>
            </View>
            <StyledText className={textScreenDescriptionlStyle}>
                {description}
            </StyledText>
        </View>
    )
}
export default HeaderButton;
const style = StyleSheet.create({
    loginBtn: {
        backgroundColor: "#3758f9",
        borderRadius: 5,
        padding: 10,
        justifyContent: 'center',
        alignItems: "center",
        marginRight: 10,
        paddingLeft: 20,
        paddingRight: 20
    },
    pageHeaderStyle: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: Platform.OS === "ios" ? 20 : null,
        paddingRight: Platform.OS === "ios" ? 20 : null,
    },
    pageBodyStyle: {
        flex: 1,
        paddingLeft: Platform.OS === "ios" ? 20 : null,
        paddingRight: Platform.OS === "ios" ? 20 : null,

    },
    item: {
        padding: 20,
        borderWidth: 2,
        borderRadius: 10,
        borderBottomColor: "black",
        marginBottom: 10,
    },
    mainBoxStyle: {
        padding: 20,
    },
    safeAreaStyle: {
        flex: 1,
        marginHorizontal: 20,
        ...Platform.select({
            ios: {
                width: Dimensions.get("screen").width,
            },
            android: {
                width: width,
            },
            web: {
                width: width - 40,
            },
        }),
    },
    btnStyling: {
        flexDirection: "row",
        justifyContent: "space-between",
        ...Platform.select({
            ios: {
                width: Dimensions.get("screen").width - 80,
            },
            android: {
                width: Dimensions.get("screen").width - 80,
            },
            web: {
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                width: Dimensions.get("window").width - 100,
                alignContent: "flex-end",
            },
        }),
    },
    warningBoxStyle: {
        flexDirection: "row",
        alignItems: "center",
    },
    warningTextStyle: {
        fontFamily: "semi_bold",
        fontSize: 16,
        color: "red",
        marginLeft: 10,
    },
    descriptionTextStyle: {
        fontSize: 14,
        fontFamily: "regular",
        color: "#000004",
        textAlign: "justify",
        lineHeight: 22,
        marginBottom: 16,
        marginTop: 11,
    },
    enterPasswordDescriptionTextStyle: {
        fontSize: 14,
        fontFamily: "regular",
        color: "black",
        textAlign: "justify",
        lineHeight: 20,
        marginTop: 10,
    },
    buttonBoxStyle: {
        flex: 1,
        justifyContent: "flex-end",
        padding: 20,
    },
    headerIos: {
        backgroundColor: "red",
    },
    headerAndroid: {
        backgroundColor: "#CCA7B1",
    },
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#fff',
    },
    header: {
        height: height * .1,
        flexDirection: 'row',
        paddingTop: 8,
        paddingBottom: 8,
        marginBottom: 8,
        backgroundColor: '#f9fafb',
        justifyContent: 'center',
        alignItems: 'center'

    },
    headerCell: {
        flex: 1,
        fontWeight: 'bold',
        textAlign: 'center',
        color: "text-gray-900",
        textTransform: 'uppercase',
        color: 'grey'
    },
    row: {
        flexDirection: 'row',
        paddingVertical: 8,
        borderBottomWidth: 1,
        borderBottomColor: 'lightgrey',
        width: '98%',
        paddingVertical: 30
    },
    cell: {
        flex: 1,
        textAlign: 'center',
        color: 'grey',
        fontSize: 15
    },
    cellPrice: {
        flex: 1,
        textAlign: 'center',
        color: 'grey',
        fontSize: 15,
        fontWeight: 'bold'
    },
    editButton: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    dateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#d1d1d1',
        borderRadius: 8,
        padding: 10,
        backgroundColor: '#f8f8f8',
    },
    calendarIcon: {
        width: 10,
        height: 10,
        marginRight: 5,
    },
    dateRange: {
        fontSize: 16,
        color: 'grey',
        marginLeft: 10,
    },
    cuscontainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 5,
        padding: 10,

    },
    icon: {
        width: 20,
        height: 20,
        marginRight: 10,
    },


});