import React,{useState,useEffect} from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import UsersListingComponent from '../components/admin/UsersListing';
import SwingDataForm from '../components/user/SwingDataForm';
import ViewClubs from '../components/clubs/ViewClubs';
import FittingResults from '../components/clubs/FittingResults';
import FittingResultsEditable from '../components/clubs/FittingResultsEditable';
import AiCaddie from '../components/caddie/AiCaddie';
import ExportUsersComponent from '../components/admin/ExportUsers';
import ImportUsersComponent from '../components/admin/ImportUsers';
import DeleteAccountComponent from '../components/minor/DeleteAccount';
import UpdatePassword from '../components/auth/UpdatePassword';
import ProfileLanding from '../components/profile/ProfileLanding';
import CreateUser from '../components/admin/CreateUser';
import AiFitterHome from '../components/clubs/AiFitterHome';
import RecommendedClubs from '../components/clubs/RecommendedClubs';
import RecommendedGrips from '../components/clubs/RecommendedGrips';
import RecommendedBalls from '../components/clubs/RecommendetBalls';
import RecommendedShaft from '../components/clubs/RecommentedShaft';
import Profile from '../components/profile/Profile';
import Header from '../components/Header';
import HeaderWithBack from '../components/HeaderWithBack';
import CalendarComponent from '../components/calendar/Calendar.web';
import WITB from '../components/profile/WITB';
import { routeNamesWithBack ,routeNames} from '../utils/Util';
import Putter from '../components/clubs/Putter';
import GolfBall from "../components/clubs/GolfBall";
import { getItemFromStore, } from '../utils/storage/storage';

const Stack = createStackNavigator();

const CommonStack = ({ route }) => {
      const { screen ,uid,role,type} = route.params;
      const [orgName, setOrgName] = useState(null);
      const loadUserSession = async () => {
            const orgName = await getItemFromStore('orgName')
            setOrgName(orgName || '')
        }
        useEffect(() => {
           loadUserSession()
        }, []);
    
      return (
            <Stack.Navigator
                  initialRouteName={screen || 'UserList'} 
                  screenOptions={({ route }) => ({
                        headerShown: routeNamesWithBack.includes(route.name) || routeNames.includes(route.name),
                        header: (props) => {
                          if (routeNamesWithBack.includes(route.name)) {
                            return <HeaderWithBack uid={uid} role={role} screenName={route.name} {...props} orgName={orgName}/>;
                          } 
                           if (routeNames.includes(route.name)) {
                            return (<Header uid={uid} role={role} {...props} orgName={orgName}/>);
                          }
                          return null; 
                        },
                        title: 'golf-eq-app', 
                      })}
                        
            >
                   <Stack.Screen
                        name="UserList"
                        component={UsersListingComponent}
                        initialParams={{ type: type === "usersList" ? "usersList" : "golfer", role: role }}
                        options={{
                              header: () => <Header uid={uid} role={role} screenName={route.name} orgName={orgName}/>,
                              headerShown: true,
                              title: 'golf-eq-app',
                        }}
                        
                  />
                  <Stack.Screen
                        name="SwingDataFrom"
                        component={SwingDataForm}
                  />
                  <Stack.Screen
                        name="ViewClubs"
                        component={ViewClubs}
                  />
                  <Stack.Screen
                        name="AiFitterHome"
                        component={AiFitterHome}
                  />
                  <Stack.Screen
                        name="FittingResults"
                        component={FittingResults}
                  />
                  <Stack.Screen
                        name="FittingResultsEditable"
                        component={FittingResultsEditable}
                  />
                  <Stack.Screen
                        name="AiCaddie"
                        component={AiCaddie}
                  />
                  <Stack.Screen
                        name="ExportUsers"
                        component={ExportUsersComponent}
                  />
                  <Stack.Screen
                        name="ImportUsers"
                        component={ImportUsersComponent}
                  />
                  <Stack.Screen
                        name="UserProfile"
                        component={Profile}
                  />
                  <Stack.Screen
                        name="DeleteAccount"
                        component={DeleteAccountComponent}
                  />
                  <Stack.Screen
                        name="UpdatePassword"
                        component={UpdatePassword}
                  />
                  <Stack.Screen
                        name="Profile"
                        component={ProfileLanding}
                        initialParams={{role:role,uid:uid}}
                  />
                  <Stack.Screen
                        name="CreateUser"
                        component={CreateUser}
                  />
                  <Stack.Screen
                        name="RecommendedClubs"
                        component={RecommendedClubs}
                  />
                  <Stack.Screen
                        name="RecommendedGrips"
                        component={RecommendedGrips}
                  />
                  <Stack.Screen
                        name="RecommendedBalls"
                        component={RecommendedBalls}
                  />
                  <Stack.Screen
                        name="RecommendedShaft"
                        component={RecommendedShaft}
                  />
                  <Stack.Screen
                        name="CalendarComponent"
                        component={CalendarComponent}
                  />
                  <Stack.Screen
                        name="Putter"
                        component={Putter}
                  />
                  <Stack.Screen
                        name="GolfBall"
                        component={GolfBall}
                  />
                  <Stack.Screen
                        name="WITB"
                        component={WITB}
                        initialParams={{role:role,uid:uid}}
                  />

            </Stack.Navigator>
      );
};

export default CommonStack;
