
import React,{useCallback} from 'react';
import { View,Linking, StyleSheet } from 'react-native';
import MyButton from '../MyButton';
import { styled } from 'nativewind';
const StyledView = styled(View);

const Contact = () => {

    const OpenURLButton = ({ url }) => {
        const handlePress = useCallback(async () => {
          // Checking if the link is supported for links with custom URL scheme.
          const supported = await Linking.canOpenURL(url);
    
          if (supported) {
            // Opening the link with some app, if the URL scheme is "http" the web link should be opened
            // by some browser in the mobile
            await Linking.openURL(url);
          } else {
            Alert.alert(`Don't know how to open this URL: ${url}`);
          }
        }, [url]);
    
        return (
          <MyButton
            label={"Email info@golf-eq.com"}
            theme={"viewClubs"}
            onPress={handlePress}
          />
        );
      };

  return (
    <StyledView className='flex flex-grow w-screen items-center justify-center bg-white'>
    <OpenURLButton
      url={"mailto:info@golf-eq.com"}
    />
  </StyledView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    backgroundColor:'white'
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
});

export default Contact;