import { Modal, Pressable, ScrollView, View, Text } from 'react-native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import { styled } from 'nativewind';

import MyButton from '../MyButton';

const StyledPressable = styled(Pressable);
const StyledView = styled(View);
const StyledText = styled(Text);

const StyledScrollView = styled(ScrollView);


export default function AdjustmentSetter({ isVisible, onClose, title, text }) {
  
  const modalBackgroundStyle = "basis-10 bg-gray-700 opacity-75"
  
  return (
    <Modal animationType="none" transparent={true} visible={isVisible}>
      <StyledView
        className="flex flex-row flex-shrink flex-grow"
      >
        <StyledView
          className={modalBackgroundStyle}
        />
        <StyledView
          className="flex flex-col flex-shrink flex-grow"
        >
          <StyledView
            className={modalBackgroundStyle}
          />
          <StyledView
            className='bg-off-white flex-shrink flex-grow p-2'
          >
            <StyledScrollView>
              <StyledView className="flex flex-row justify-start ">
                <StyledPressable onPress={() => onClose()}>
                  <MaterialIcons name="close" color="#000" size={30} />
                </StyledPressable>
              </StyledView>
              <StyledView
                className='flex flex-row justify-center m-4'
              >
                <StyledText
                  className='flex flex-shrink text-3xl font-bold'
                >
                  {title}
                </StyledText>
              </StyledView>
              <StyledView
                className='flex flex-row justify-center m-4'
              >
                <StyledText
                  className='flex flex-shrink text-2xl font-medium'
                >
                  {text}
                </StyledText>
              </StyledView>
            </StyledScrollView>
          </StyledView>
          <StyledView
            className={modalBackgroundStyle}
          />
        </StyledView>
        <StyledView
          className={modalBackgroundStyle}
        />
      </StyledView>
    </Modal>
  )

  // return (
  //   <Modal animationType="none" transparent={false} visible={isVisible}>
  //     <StyledView className='m-10 flex-1 flex-shrink flex-row max-w-xs'>
  //       <StyledView className='basis-1/4' />
  //       <StyledView className='basis-1/2'>
  //         <StyledPressable onPress={onClose}>
  //           <MaterialIcons name="close" color="#000" size={30} />
  //         </StyledPressable>
  //         <StyledView className='flex-1 items-center justify-center'>
  //           <StyledText className='text-xl font-semibold'>
  //             {title}
  //           </StyledText>
  //           <StyledText className='text-lg'>
  //             {text}
  //           </StyledText>
  //         </StyledView>
  //       </StyledView>
  //       {/* <StyledView className='basis-1/4' />
  //       <StyledView className='basis-1/2 bg-off-white'>
  //         <StyledView className="flex flex-row justify-end">
  //           <StyledView className="m-4">
              
  //           </StyledView>
  //         </StyledView>
  //         <StyledView className='m-4 font-bold'>
  //           {children}
  //         </StyledView>
  //       </StyledView>
  //     </StyledView> */}
  //     </StyledView>
  //   </Modal>
  // )
};




// import { Modal, View, Text, Pressable, StyleSheet } from 'react-native';
// import MaterialIcons from '@expo/vector-icons/MaterialIcons';

// export default function AdjustmentSetter({ isVisible, children, onClose }) {
//   return (
//     <Modal animationType="slide" transparent={true} visible={isVisible}>
//       <View style={styles.modalContent}>
//       {/* <View className="bg-sky-200 w-30"> */}
//         <View style={styles.titleContainer}>
//           <Text style={styles.title}>Adjust AI Recommendation</Text>
//           <Pressable onPress={onClose}>
//             <MaterialIcons name="close" color="#fff" size={22} />
//           </Pressable>
//         </View>
//         <View style={styles.pickerContainer}>
//           {children}
//         </View>
//       </View>
//     </Modal>
//   );
// }

// const styles = StyleSheet.create({
//   modalContent: {
//     height: '80%',
//     width: '80%',
//     backgroundColor: '#87CEEB',
//     borderTopRightRadius: 18,
//     borderTopLeftRadius: 18,
//     position: 'absolute',
//     bottom: '10%',
//     left: '10%'
//   },
//   titleContainer: {
//     height: '10%',
//     backgroundColor: '#464C55',
//     borderTopRightRadius: 10,
//     borderTopLeftRadius: 10,
//     paddingHorizontal: 20,
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//   },
//   title: {
//     color: '#fff',
//     fontSize: 16,
//   },
//   pickerContainer: {
//     flexDirection: 'row',
//     justifyContent: 'center',
//     alignItems: 'center',
//     paddingHorizontal: 50,
//     paddingVertical: 20,
//   },
// });
