import * as React from 'react'
import { StyleSheet,Text,View, TouchableOpacity  } from 'react-native';
import { createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import { Feather } from '@expo/vector-icons';
import { useNavigation } from './NavigationProvider';
import TabOneStack from './tabStacks/TabOneStack'
import TabTwoStack from './tabStacks/TabTwoStack'
import TabThreeStack from './tabStacks/TabThreeStack'
import TabFourStack from './tabStacks/TabFourStack'
import TabFiveStack from './tabStacks/TabFiveStack'
import { routeNameBottom } from '../utils/Util';
import Header from '../components/Header';
import { useCurrentUser } from '../components/CurrentUserProvider';

const Tab = createBottomTabNavigator()

function CustomTabBarButton({ children, onPress }) {
  return (
      <TouchableOpacity
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          onPress={onPress}>
          {children}
      </TouchableOpacity>
  );
}

function CustomTabBarLabel({ iconName, label, color, size, isFocused,badge }) {
  return (
      <View style={{ alignItems: 'center' }}>
          <Feather name={iconName} size={isFocused ? size + 4 : size} color={isFocused ? 'black' : color} />
          <Text style={{ color: isFocused ? 'black' : color, fontWeight: isFocused ? 'bold' : 'normal' }}>
              {label}
          </Text>    
               {badge > 0 && (
        <View style={styles.badgeContainer}>
          <Text style={styles.badgeText}>{badge}</Text>
        </View>
      )}
      </View>
  );
}

const BottomTabNavigator = ({route}) => {
  const { selectedTab } = useNavigation();
  const { uid, role } = route.params || {};
  const { cart,orgData } = useCurrentUser();
  
  return (
    <Tab.Navigator
    screenOptions={({ route }) => ({
      unmountOnBlur: true ,
      headerShown: routeNameBottom.includes(route.name),
      header: (props) => {
        if (routeNameBottom.includes(route.name)) {
          return <Header uid={uid} role={role} screenName={route.name} {...props}  orgName={orgData?.name}/>;
        } 
      },
      title: '',
    })}
      
    initialRouteName={selectedTab} >
    {[
      { name: 'Dashboard', component: TabOneStack, iconName: 'home' },
      { name: 'Golfers', component: TabTwoStack, iconName: 'users' },
      { name: 'Booking', component: TabThreeStack, iconName: 'calendar' },
      { name: 'Orders', component: TabFourStack, iconName: 'shopping-bag' },
      { name: 'Cart', component: TabFiveStack, iconName: 'shopping-cart',},
    ].map((tab, index) => (
      <Tab.Screen
        key={index}
        name={tab.name}
        component={tab.component}
        initialParams={{ uid, role}} 
        options={({ route }) => ({
          tabBarButton: (props) => (
            <CustomTabBarButton {...props}>
              <CustomTabBarLabel
                iconName={tab.iconName}
                label={tab.name}
                color={props.color}
                size={24}
                isFocused={props.accessibilityState.selected}
                badge={route.name === 'Cart'? cart?.cartData?.length || 0 : null}
              />
            </CustomTabBarButton>
          ),
        })}
      />
    ))}
  </Tab.Navigator>
  )
}
const styles = StyleSheet.create({
  tabBarLabel: {
    flex:1,
    color: '#292929',
    fontSize: 15,
  },
  tabBarButton:{ 
    flex: 1, 
    alignItems: 'center', 
    justifyContent: 'center',
  },
  badgeContainer: {
    position: 'absolute',
    right: -10,
    top: -5,
    backgroundColor: 'red',
    borderRadius: 10,
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeText: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
  },
})

export default BottomTabNavigator