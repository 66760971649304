import { StatusBar } from 'expo-status-bar';
import { NativeWindStyleSheet, styled } from "nativewind";
import { useEffect, useState } from 'react';
import { View, Dimensions, Platform } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import LoginForm from '../components/auth/LoginForm';
import SignUpForm from '../components/auth/SignUpForm';
import { auth } from '../firebaseConfig';
import { APIStates } from '../utils/Strings';
import { fetchLoginIdle, fetchLogoutIdle, fetchLogoutRequest, fetchRefreshIdle, fetchRegisterIdle, saveTokensRequest, syncCalendarRequest } from '../store/ducks/app/actions';
import UpdatePassword from './auth/UpdatePassword';
import ForgotForm from './auth/ForgotForm';
import axios from 'axios';
import CrashLogger from './managers/CrashLogger';
import AnalyticsLogger from './managers/AnalyticsLogger';
import { deleteItemFromStore, getItemFromStore, setItemInStore } from '../utils/storage/storage';
import BookingWeb from './booking/Booking.web';
import DrawerNavigator from '../navigation/DrawerNavigation';
import { useCurrentUser } from './CurrentUserProvider';


NativeWindStyleSheet.setOutput({
    web: "native",
    default: "native",
});

const StyledView = styled(View);

export default function RootView({ isCallingFromBooking}) {
    const dispatch = useDispatch()
    const login = useSelector(state => state.app.login);
    const register = useSelector(state => state.app.register);
    const refresh = useSelector(state => state.app.refresh);
    const logout = useSelector(state => state.app.logout);
    const [loggedIn, setLoggedIn] = useState(null);
    const saveToken = useSelector(state => state.app.bookingTokens);
    const [role, setRole] = useState(null);
    const [orgName, setOrgName] = useState(null);
    const [orgId, setOrgId] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [showSignUp, setShowSignUp] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const [selfCreated, setSelfCreated] = useState(login?.data?.user?.selfCreated || "");
    const [isAdminUserLandedFirstTime, setIsAdminUserLandedFirstTime] = useState(null)

    const { setOrgData} = useCurrentUser()

  useEffect(()=>{
    if(saveToken.data === "Tokens Saved"){
    alert("Your Calendar is synced")
    if(loggedIn && orgId){
      dispatch(syncCalendarRequest({
        userId: loggedIn,
        orgId: orgId
    }))
  }
    }
      }, [saveToken.status])

        useEffect(() => {
        if(Platform.OS === 'web' && orgId){
          const urlParams = new URLSearchParams(window.location.search);
          const paramsObject = {};
          urlParams.forEach((value, key) => {
            paramsObject[key] = value;
          });
    
          if(paramsObject?.code){
            dispatch(saveTokensRequest({
              code: paramsObject?.code,
              orgId: orgId
            }))
          }
  
          if(loggedIn){
          dispatch(syncCalendarRequest({
            userId: loggedIn,
            orgId: orgId
        }))
      }
    
        }
    }, [orgId]) 
   

    // 401 Handling
    useEffect(() => {
        axios.interceptors.response.use(undefined, async error => {
            if (error.response && error.response.status === 401) {
                if (error.response.config.url.includes('refresh')) {
                    // If refresh token is expired
                    setLoggedIn(false)
                } else {
                    // If access token is expired
                    setLoggedIn(false)
                }
            }
            return Promise.reject(error);
        });

        loadUserSession()
    }, [])

    // Login or Signup Handling
    useEffect(() => {
        handlUserSession()
    }, [login, login.status, login.data, register, register.status, register.data]);

    const handlUserSession = async () => {
        if (login.status == APIStates.SUCCESS || register.status == APIStates.SUCCESS) {
            // Storing in Secure Storage
            await setItemInStore('token', (login?.data?.tokens?.access?.token || register?.data?.tokens?.access?.token))
            await setItemInStore('refresh', (login?.data?.tokens?.refresh?.token || register?.data?.tokens?.refresh?.token))
            
            // Storing user's info in Normal Storage
            await setItemInStore('userId', (login?.data?.user?.id || register?.data?.user?.id))
            await setItemInStore('role', (login?.data?.user?.role || register?.data?.user?.role))

          await setItemInStore('firstName', (login?.data?.user?.firstName || register?.data?.user?.firstName))
          await setItemInStore('lastName', (login?.data?.user?.lastName || register?.data?.user?.lastName))

          if(login?.data?.user && login?.data?.user?.orgId != null) {
            await setItemInStore('orgId', login?.data?.user?.orgId)
        }
        if(login?.data?.user && login?.data?.user?.selfCreated != null) {
          await setItemInStore('selfCreated', login?.data?.user?.selfCreated)
      }
       
          if(login?.data?.org && login?.data?.org?.orgName != null) {
            await setItemInStore('orgName', login?.data?.org?.orgName)
            await setItemInStore('orgDomain', login?.data?.org?.domain)
            setOrgData({
              id: login?.data?.user?.orgId,
              name: login?.data?.org?.orgName,
              domain: login?.data?.org?.domain || ""
            })
          }
          

            setLoggedIn(login?.data?.user?.id || register?.data?.user?.id);
            setRole(login?.data?.user?.role || register?.data?.user?.role);

            const _isAdminUserLandedFirstTime = await getItemFromStore('isAdminUserLandedFirstTime')
            const valueToSet = _isAdminUserLandedFirstTime == 'false' ? _isAdminUserLandedFirstTime || true :
                login?.data?.user?.isUserLandedFirstTime || false
            setIsAdminUserLandedFirstTime(valueToSet)
            
            await setItemInStore('isAdminUserLandedFirstTime', valueToSet.toString())

            if (login.status == APIStates.SUCCESS){
                CrashLogger.getInstance().logSignin(login?.data?.user)
                AnalyticsLogger.getInstance().logLogin(true);
                AnalyticsLogger.getInstance().logSetUserProperties(login?.data?.user)
            } else if (register.status == APIStates.SUCCESS) {
                CrashLogger.getInstance().logSignin(register?.data?.user, true)
                AnalyticsLogger.getInstance().logRegister(true);
                AnalyticsLogger.getInstance().logSetUserProperties(register?.data?.user)
            }
        } else {
            setLoggedIn(null);
        }
    }

    // Logout Handling
    useEffect(() => {
        if (logout.status == APIStates.SUCCESS) {
            logoutUser()
        } else if (logout.status == APIStates.FAILURE) {
            logoutUser()
            dispatch(fetchLoginIdle())
        }
    }, [logout, logout.status])

    // Refresh Tokens Handling
    useEffect(() => {
        const handleRefresh = async () => {
            if (refresh.status == APIStates.SUCCESS) {
                await setItemInStore('token', (refresh?.data?.access?.token))
                await setItemInStore('refresh', (refresh?.data?.refresh?.token))
                loadUserSession()
            }
        }

        handleRefresh()
    }, [refresh, refresh.status])

    // Load User Session
    const loadUserSession = async () => {
        const userId = await getItemFromStore('userId')
      const firstName = await getItemFromStore('firstName')
      const lastName = await getItemFromStore('lastName')
        const _isAdminUserLandedFirstTime = await getItemFromStore('isAdminUserLandedFirstTime')
        const role = await getItemFromStore('role')
        const orgId = await getItemFromStore('orgId')
        const orgName = await getItemFromStore('orgName')
        const orgDomain = await getItemFromStore('orgDomain')
        const selfCreated = await getItemFromStore('selfCreated')
        console.log("await getItemFromStore('selfCreated')", await getItemFromStore('selfCreated'))
        setIsAdminUserLandedFirstTime(_isAdminUserLandedFirstTime)
        setLoggedIn(userId)
        setRole(role || '')
        setOrgName(orgName || '')
        setFirstName(firstName ||"")
        setLastName(lastName||"")
        setOrgId(orgId || "")
        setSelfCreated(selfCreated || "")
        setOrgData({
          id: orgId,
          name: orgName,
          domain: orgDomain
        })
    }

    useEffect(() => {
      if (login?.data?.user) {
        console.log('New user logged in:', login.data.user.selfCreated);
        setSelfCreated(login.data.user.selfCreated);  // Update selfCreated when user changes
      } else {
        setSelfCreated(null);  // Reset selfCreated when user logs out
      }
    }, [login?.data?.user]);
    
    // This useEffect will run whenever `selfCreated` changes
    useEffect(() => {
      console.log('Updated selfCreated value:', selfCreated);
    }, [selfCreated]);
    
    
    

    const logoutUser = async () => {
      dispatch(fetchLogoutIdle())
      dispatch(fetchRegisterIdle())
      dispatch(fetchLoginIdle())
      dispatch(fetchRefreshIdle())
      setLoggedIn(false)
      setIsAdminUserLandedFirstTime(null)

      await deleteItemFromStore('isAdminUserLandedFirstTime')
      await deleteItemFromStore('userId')
      await deleteItemFromStore('role')
      await deleteItemFromStore('orgId')
      await deleteItemFromStore('token')
      await deleteItemFromStore('refresh')
      await deleteItemFromStore('firstName')
      await deleteItemFromStore('lastName')
      await deleteItemFromStore('selfCreated')
    }

    const signMeOut = async () => {
        const refreshToken = await getItemFromStore('refresh')
        dispatch(fetchLogoutRequest({
            refreshToken
        }))
    };

  const LoginData = loggedIn;
  const firstname = firstName;
  const lastname = lastName;

  
  return (
    <>

      <View className={" flex-1 bg-white"}>
        {isCallingFromBooking ? (
          <>
            <StyledView className="flex flex-col flex-grow items-center justify-center">
              <BookingWeb isLogin={LoginData} signMeOut={() => signMeOut()} firstName={firstname} lastName={lastname} />
            </StyledView>
          </>
        ) : (
          <StyledView className="flex flex-col flex-grow items-center justify-center">
            {loggedIn ? (
              isAdminUserLandedFirstTime == true ||
                isAdminUserLandedFirstTime == "true" ||
                isAdminUserLandedFirstTime == null ? (
                <UpdatePassword
                  setIsAdminUserLandedFirstTime={setIsAdminUserLandedFirstTime}
                  uid={loggedIn}
                />
              ) : (
                    <View style={{ height: '100%', width: '100%' }}>
                      <DrawerNavigator role={role} uid={loggedIn} signMeOut={() => signMeOut()} orgId={orgId} orgName={orgName} selfCreated={selfCreated}/>
                    </View>
              )
              
            ) : (
              <>
                {showSignUp ? (
                  <SignUpForm setShowSignUp={setShowSignUp} auth={auth} />
                ) : showForgot ? (
                  <ForgotForm setShowForgot={setShowForgot} />
                ) : (
                  <LoginForm
                    setForgotScreen={setShowForgot}
                    setShowSignUp={setShowSignUp}
                    auth={auth}
                  />
                )}
              </>
            )}
            <StatusBar style="auto" />
          </StyledView>
        )}
      </View>
    </>
  );
}