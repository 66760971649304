import { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { styled } from "nativewind";

const StyledText = styled(Text);
const StyledView = styled(View);

export default function RecommendedClubs({ clubToUse }) {
  
  return (
    <StyledView className="flex content-center">
      <StyledText className="flex align-center text-2xl font-semibold py-2">
        Clubs to Play
      </StyledText>
      <StyledView
        className="border rounded p-2 border-gray-700 bg-blue-300 flex flex-col items-center"
      >
        {(clubToUse) ?
          <>
            {(clubToUse.firstClubNum) ?
              <StyledText className="p-1 text-2xl uppercase tracking-wider">
                Club {clubToUse.firstClubNum}: {clubToUse.firstClubDist.toFixed(1)} yards
              </StyledText>
              :
              <>
              </>
            }
            {(clubToUse.secondClubNum) ?
              <StyledText className="p-1 text-2xl font-bold uppercase">
                Club {clubToUse.secondClubNum}: {clubToUse.secondClubDist.toFixed(1)} yards
              </StyledText>
              :
              <>
              </>
            }
            {(clubToUse.thirdClubNum) ?
              <StyledText className="p-1 text-2xl uppercase tracking-wider">
                Club {clubToUse.thirdClubNum}: {clubToUse.thirdClubDist.toFixed(1)} yards
              </StyledText>
              :
              <>
              </>
            }
          </>
          :
          <>
          </>
        }
      </StyledView>
    </StyledView>
  )
};