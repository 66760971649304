import { useEffect, useState } from 'react';
import { Image, Pressable, Text, View } from "react-native";
import { styled } from "nativewind";

import { readClubDatabase } from '../../database/ReadData';
import ClubDetail from './ClubDetail';
import {
  clubCardButtonLabelStyle,
  clubCardButtonPressableStyle,
  clubCardHoselStyle
} from "../Styles";

const StyledImage = styled(Image);
const StyledPressable = styled(Pressable);
const StyledText = styled(Text);
const StyledView = styled(View);

export default function ClubData({ clubData, selectedClubNumber }) {

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      {(clubData) ?
        <>
          <ClubDetail
            isVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            clubData={clubData}
            selectedClubNumber={selectedClubNumber}
          />
          <StyledPressable
            onPress={() => setIsModalVisible(true)}
            className={clubCardButtonPressableStyle}
          >
            <StyledView className="flex flex-row content-center h-200">
              <StyledView>
                <StyledImage
                  className="rounded"
                  style={{ width: 100, height: 100 }}
                  source={{ uri: "https://cdn.shopify.com/s/files/1/0614/0379/1520/files/" + clubData.image }}
                  alt={clubData.club_full_name}
                />
              </StyledView>
              {/* <StyledView className="m-2 flex items-stretch content-center border"> */}
              <StyledView className='flex-shrink flex flex-col items-center justify-center p-2'>
                <StyledText
                  className={clubCardButtonLabelStyle}
                >
                  {clubData.club_full_name}
                </StyledText>
                {(clubData.hoselSetting) ?
                  <StyledText
                    className={clubCardHoselStyle}
                  >
                    Hosel: {clubData.hoselSetting}
                  </StyledText>
                  :
                  <></>
                }
              </StyledView>
            </StyledView>
          </StyledPressable>
        </>
        :
        <Text>Loading...</Text>
      }
    </>
  );
};
