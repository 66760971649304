// export const BASE_URL = 'http://34.207.75.236:3000/v1'
export const BASE_URL = 'https://api.golf-eq.com/v1' 
// export const BASE_URL = 'http://localhost:3000/v1'

export const LOGIN_URL = '/auth/login'
export const REGISTER_URL = '/auth/register'
export const LOGOUT_URL = '/auth/logout'
export const REFRESH_TOKENS_URL = '/auth/refresh-tokens'
export const USER_URL = '/users'
export const RE_AUTHENTICATE_URL = '/users/reauth'
export const SWING_URL = '/golf/swing'
export const USER_FITTING_RESULTS_URL = '/golf/userFittingResults'
export const UPDATE_PASSWORD = '/users/updatePassword'
export const RECOMMENDATION_URL = '/golf/recommendations'
export const CLUBS_URL = '/golf/clubs'
export const EXPORT_USERS_URL = '/utils/export'
export const FORGOT_PASSWORD_URL = '/auth/forgot-password'
export const RESET_PASSWORD_URL = '/auth/reset-password'
export const RESET_FITTING_RESULTS_URL = '/golf/resetFittingResults'
export const PAYMENTS_URL = '/payments/checkout'
export const SERVICES_URL = '/golf/services'
export const SAVE_BOOKINGS = "/bookings/saveBookings"
export const CANCEL_BOOKINGS = "/bookings/cancelBooking"
export const GET_TIMESLOTS = "/bookings/getTimeSlots"
export const SAVE_TOKENS = "/bookings/saveTokens"
export const SYNC_CALENDAR = "/bookings/syncCalendar"
export const FETCH_TRANSACTIONS = "/payments/fetchTransactions"
export const EXPORT_TRANSACTIONS = "/payments/exportTransactions"
export const CART_URL = "/golf/cart"
export const CREATE_ORDER = "/payments/createOrder"
export const FETCH_ORDERS = "/payments/fetchOrders"
export const UPDATE_ORDER = "/payments/updateOrder"
export const EXPORT_ORDER = "/payments/exportOrders"
