
export default function WhichClub(carryDistances, distanceToPin, adjustment, setClubToUse) {

  let clubList = Array();
  let nextClub = null;
  let index = -1;
  const caddieMath = 0.90;
  
  Object.keys(carryDistances).map((key) => {
    clubList.push(key);
    if ((Number(key) * Number(adjustment) / 100 * caddieMath) > Number(distanceToPin)) {
      index += 1
    }
  });

  if (index === 12) {
    setClubToUse({
      firstClubNum: carryDistances[Number(clubList[index - 1])],
      firstClubDist: Number(clubList[index - 1]) * Number(adjustment) / 100 * caddieMath,
      secondClubNum: carryDistances[Number(clubList[index])],
      secondClubDist: Number(clubList[index]) * Number(adjustment) / 100 * caddieMath,
      thirdClubNum: null,
      thirdClubDist: null,
    });
  } else if (index > 0) {
    setClubToUse({
      firstClubNum: carryDistances[Number(clubList[index - 1])],
      firstClubDist: Number(clubList[index - 1]) * Number(adjustment) / 100 * caddieMath,
      secondClubNum: carryDistances[Number(clubList[index])],
      secondClubDist: Number(clubList[index]) * Number(adjustment) / 100 * caddieMath,
      thirdClubNum: carryDistances[Number(clubList[index + 1])],
      thirdClubDist: Number(clubList[index + 1]) * Number(adjustment) / 100 * caddieMath,
    })
  } else {
    setClubToUse({
      firstClubNum: null,
      firstClubDist: null,
      secondClubNum: carryDistances[Number(clubList[0])],
      secondClubDist: Number(clubList[0]) * Number(adjustment) / 100 * caddieMath,
      thirdClubNum: carryDistances[Number(clubList[1])],
      thirdClubDist: Number(clubList[1]) * Number(adjustment) / 100 * caddieMath,
    })
  }
};
