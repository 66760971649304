import { styled } from "nativewind";
import { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import MyButton from '../MyButton';

import {
    pageTitleStyle,
    textScreenDescriptionlStyle
} from '../Styles';
import WITB from "./WITB";
import Profile from "./Profile";
import { fetchUserProfileRequest } from "../../store/ducks/app/actions";
import { APIStates } from "../../utils/Strings";
import { useCurrentUser } from "../CurrentUserProvider";
const StyledView = styled(View);
const StyledText = styled(Text);
const StyledScrollView = styled(ScrollView);

export default function ProfileLanding({}) {
    const { currentUser, role, uid  } = useCurrentUser();
    const dispatch = useDispatch()
    const [isProfilePageVisible, setIsProfilePageVisible] = useState(true);
    const [isWITBVisible, setIsWITBVisible] = useState(null);
    const getProfile = useSelector(state => state.app.getProfile);

    useEffect(() => {
        const _userId = currentUser?.id || uid
        dispatch(fetchUserProfileRequest({ userId: _userId }))
    }, [])

    useEffect(() => {
        if (getProfile.status == APIStates.SUCCESS) {
            console.log(getProfile.data)
        } else if (getProfile.status == APIStates.FAILURE) {
            alert(getProfile.message)
        }
    }, [getProfile.status])

    return (
        <StyledScrollView style={{ flex: 1, width: '100%', backgroundColor:'white',paddingLeft:'2%',paddingRight:'2%' }}>
            <StyledView className="my-2">

                {/* Page Header */}
                <View style={style.pageHeaderStyle}>
                    <StyledText className={pageTitleStyle}>
                        Golfer Profile
                    </StyledText>

                </View>

                <StyledText className={textScreenDescriptionlStyle}>
                    Complete your golfer profile to get more personalized AI Caddie and AI Fitter results
                </StyledText>
                
                {
                    getProfile.status == APIStates.SUCCESS && getProfile.data.org != null && getProfile.data.org != undefined && 
                    <StyledText className={textScreenDescriptionlStyle}>
                        {`Role: ${getProfile.data.role}\n`}
                        {`Email: ${getProfile.data.email}\n`}
                        {`Organization: ${getProfile.data.org.name}`}
                    </StyledText>
                }
               
            </StyledView>
            <View>
            
            {
                (isWITBVisible || isProfilePageVisible == null) && <MyButton
                    label={"Profile Page"}
                    theme={"viewClubs"}
                    onPress={() => {
                        setIsProfilePageVisible(true)
                        setIsWITBVisible(false)
                    }}
                />
            }
                
            {
                (isProfilePageVisible || isWITBVisible == null) && <MyButton
                    label={"What’s In The Bag"}
                    theme={"viewClubs"}
                    onPress={() => {
                        setIsWITBVisible(true)
                        setIsProfilePageVisible(false)
                    }}
                />
            }

            {
                isWITBVisible && <WITB uid={uid} currentUser={currentUser} role={role} />
            }

            {
                isProfilePageVisible && <Profile uid={uid} currentUser={currentUser} role={role} />
            }

            </View>
        </StyledScrollView>
    )
};

const style = StyleSheet.create({
    pageHeaderStyle: { flexDirection: 'row', justifyContent: 'space-between' },
    addNewUserButtonStyle: {
        color: 'rgba(87,164,251,1)',
        fontSize: 20,
        marginTop: 7.5,
         
    }
})