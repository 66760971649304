import { styled } from "nativewind";
import React, { useEffect } from 'react';
import {
    ActivityIndicator,
    KeyboardAvoidingView,
    SafeAreaView,
    StyleSheet,
    Text,
    View
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExportUsersIdle, fetchExportUsersRequest, fetchUsersIdle, fetchUsersRequest } from '../../store/ducks/app/actions';
import { APIStates } from '../../utils/Strings';
import MyButton from '../MyButton';
import { pageTitleStyle, textStyle } from '../Styles';
import AnalyticsLogger from "../managers/AnalyticsLogger";
import { ScreenNames } from "../../utils/Util";
import { getItemFromStore } from "../../utils/storage/storage";

const StyledText = styled(Text);
const StyledView = styled(View);

const ExportUsersComponent = ({ }) => {
    const dispatch = useDispatch()

    const exportedUsers = useSelector(state => state.app.export);

    useEffect(() => {
      AnalyticsLogger.getInstance().logScreenView(ScreenNames.ExportUsers)
    }, [])
    
    useEffect(() => {
        if (exportedUsers.status == APIStates.SUCCESS) {
            alert('Users list has been sent to your email.');
            dispatch(fetchExportUsersIdle())
        } else if (exportedUsers.status == APIStates.FAILURE) {
            alert(exportedUsers.message);
            dispatch(fetchExportUsersIdle())
        }
    }, [exportedUsers.status])

    const exportUsers = async () => {
        const userId = await getItemFromStore('userId')
        dispatch(fetchExportUsersRequest({
            userId
        }))
    }

    return (
        <View style={{ flex: 1, marginBottom: 40, width: '100%' }}>
            <SafeAreaView style={style.safeAreaStyle}>
                <KeyboardAvoidingView behavior="padding">
                    <StyledView className="mx-auto my-2">
                        <StyledText className={pageTitleStyle}>
                            Export Users
                        </StyledText>
                        <View style={style.mainBoxStyle} />
                        <StyledText className={textStyle}>
                            Exported Users list will be send to your email. If you are  not able to see the list in your inbox, please check your spam folder.
                        </StyledText>
                        <View style={style.mainBoxStyle} />
                        {
                            exportedUsers.status == APIStates.LOADING ?
                                <View className='m-2 flex flex-row items-center justify-center'>
                                    <ActivityIndicator size="large" color="rgba(170,218,253,1)" />
                                </View>
                                :
                                <MyButton
                                    label={"Export Users"}
                                    theme={"submit"}
                                    onPress={() => exportUsers()}
                                />
                        }

                    </StyledView>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    )
}

const style = StyleSheet.create({
    pageHeaderStyle: { flexDirection: 'row', justifyContent: 'space-between' },
    addNewUserButtonStyle: {
        color: 'rgba(87,164,251,1)',
        fontSize: 20,
        marginTop: 7.5,
         
    },
    userListButtonsStyle: {
        color: 'rgba(87,164,251,1)',
        fontSize: 17.5,
        marginTop: 7.5
    },
    flatlistContainer: { marginBottom: 130 },
    userName: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    userEmail: {
        fontSize: 18,
        fontWeight: '400'
    },
    item: {
        padding: 20,
        borderWidth: 2,
        borderRadius: 10,
        borderBottomColor: 'black',
        marginBottom: 10
    },
    mainBoxStyle: {
        padding: 20,
    },
    safeAreaStyle: {
        flex: 1,
        marginHorizontal: 20,
    },
    warningBoxStyle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    warningTextStyle: {
        fontFamily: 'semi_bold',
        fontSize: 16,
        color: 'red',
        marginLeft: 10,
    },
    descriptionTextStyle: {
        fontSize: 14,
        fontFamily: 'regular',
        color: '#000004',
        textAlign: 'justify',
        lineHeight: 22,
        marginBottom: 16,
        marginTop: 11,
    },
    enterPasswordDescriptionTextStyle: {
        fontSize: 14,
        fontFamily: 'regular',
        color: 'black',
        textAlign: 'justify',
        lineHeight: 20,
        marginTop: 10,
    },
    buttonBoxStyle: {
        flex: 1,
        justifyContent: 'flex-end',
        padding: 20,
    },
})

export default ExportUsersComponent
