import React, { useState } from 'react';
import { View, Text, StyleSheet, Modal, TouchableWithoutFeedback, FlatList, Pressable, ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import { APIStates } from '../../../utils/Strings';

const OrderStatus = ({ status, updatedStatus }) => {
    const [isModalVisible, setModalVisible] = useState(false);
    const [hoveredStatus, setHoveredStatus] = useState(null);
    const orderUpdate = useSelector(state => state.app.updateOrders);

    const statuses = ['pending', 'cancelled', 'shipped'];

    const getStatusStyle = () => {
        switch (status.toLowerCase()) {
            case 'pending':
                return styles.pending;
            case 'cancelled':
                return styles.cancelled;
            case 'shipped':
                return styles.shipped;
            default:
                return styles.default;
        }
    };

    const handleOutsideClick = () => {
        setModalVisible(false)
    };

    const handleStatusSelect = (status) => {
        updatedStatus(status);
        setModalVisible(false);
    };

    const handleHoverIn = (status) => {
        setHoveredStatus(status);
    };

    const handleHoverOut = () => {
        setHoveredStatus(null);
    };

    return (
        <View style={styles.container}>
            <Pressable
                style={({ hovered }) => [
                    styles.statusContainer,
                    getStatusStyle(),
                    hovered && styles.hovered, // Apply hover effect
                ]}
                onPress={() => setModalVisible(true)}
                onHoverIn={() => handleHoverIn(status)}
                onHoverOut={handleHoverOut}
            >
                {orderUpdate.status == APIStates.LOADING ? (
                    <ActivityIndicator />
                ) : (
                    <Text style={[styles.text, { color: getStatusStyle().color }]}>
                        {status}
                    </Text>
                )}

            </Pressable>

            <Modal
                transparent={true}
                visible={isModalVisible}
                animationType="slide"
                onRequestClose={() => setModalVisible(false)}
                handleOutsideClick={handleOutsideClick}
            >
                <TouchableWithoutFeedback onPress={handleOutsideClick}>
                    <View style={styles.modalContainer}>
                        <View style={styles.modalContent}>
                            <View style={{ marginTop: 20, alignItems: 'center', marginBottom: 30 }}>
                                <Text style={{ fontSize: 20, fontWeight: '600', }}>
                                    Status Update
                                </Text>
                            </View>
                            <FlatList
                                data={statuses}
                                keyExtractor={(item) => item}
                                renderItem={({ item }) => (
                                    <Pressable
                                        style={({ hovered }) => [
                                            styles.modalItem,
                                            hovered && styles.modalItemHovered,
                                        ]}
                                        onPress={() => handleStatusSelect(item)}
                                        onHoverIn={() => handleHoverIn(item)}
                                        onHoverOut={handleHoverOut}
                                    >
                                        <Text style={styles.modalItemText}>{item}</Text>
                                    </Pressable>
                                )}
                            />
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: 'white',
        borderRadius: 5,
        width: 300,
        height: 300,
        // alignItems: 'center',
    },
    modalItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
    },
    modalItemText: {
        fontSize: 16,
        alignSelf: 'center',
        justifyContent: 'center',
    },
    statusContainer: {
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 20,
    },
    pending: {
        backgroundColor: '#FFFBEB',
        color: '#D97706',
    },
    cancelled: {
        backgroundColor: '#FEEBEB',
        color: '#E10E0E',
    },
    shipped: {
        backgroundColor: '#DAF8E6',
        color: '#1A8245',
    },
    default: {
        backgroundColor: '#f0f0f0',
        color: '#000',
    },
    hovered: {
        backgroundColor: '#ddd',
    },
    modalItemHovered: {
        backgroundColor: '#f0f0f0',
    },
});

export default OrderStatus;
