import { useState, useEffect } from 'react';
import { Image, Pressable, Text, View, TouchableOpacity, StyleSheet, ScrollView , ActivityIndicator,} from "react-native";
import { styled } from "nativewind";
import {
  clubCardButtonLabelStyle,
  clubCardButtonPressableStyle,
  clubCardHoselStyle
} from "../../Styles";
import SuccessModal from '../../modals/SuccessModal';
import MyButton from '../../MyButton';
import { useDispatch, useSelector } from 'react-redux';
import { createOrdersIdle, createOrdersRequest } from '../../../store/ducks/app/actions';
import { useCurrentUser } from "../../CurrentUserProvider";
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import {  getItemFromStore} from "../../../utils/storage/storage"
import { APIStates } from '../../../utils/Strings';
import { useResponsiveValues } from '../../booking/useResponsiveValues';


const StyledImage = styled(Image);
const StyledPressable = styled(Pressable);
const StyledText = styled(Text);
const StyledView = styled(View);

export default function Cart() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { currentUser, uid, cart, setCart, handleRemoveCart } = useCurrentUser();
  const createOrders = useSelector(state => state.app.createOrders);
  const [orderStatus, setOrderStatus] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [isLoading, setLoading] = useState(false);
  const { modelWidth } = useResponsiveValues();


  useEffect(() => {
    if (createOrders.status == APIStates.SUCCESS) {
      setLoading(false);
      setCart({})
      setOrderStatus(createOrders?.data?.status || '');
      setDeliveryDate(createOrders?.data?.deliveryDate || '');
      console.log("created Order",createOrders?.data?.status,createOrders?.data?.deliveryDate)
      setIsModalVisible(true)
    }

    return () => {
      dispatch(createOrdersIdle())
    }
  }, [createOrders.status])

  const dispatch = useDispatch();

  const handleCart = async () => {
    setLoading(true);
    const orgId = await getItemFromStore('orgId')
    dispatch(createOrdersRequest({
      golferId: currentUser?.id || uid,
      orgId: orgId,
      order: cart?.cartData?.[0]
    }));
  };

  const handleOutsideClick = () => {
    setIsModalVisible(false)
  };

  return (
    <View style={{ flex: 1, backgroundColor: 'white', paddingTop: 70, paddingLeft: '2%', paddingRight: '2%' }}>
      {cart?.cartData?.length > 0 ? (
        <>
          <StyledView style={{ flexDirection: 'row', marginTop: 20, justifyContent: "flex-end", marginRight: 10 }}>
            <TouchableOpacity
              style={style.loginBtn}
              onPress={() => {setCart({})}}
            >
              <Text
                style={{
                  fontSize: 15,
                  fontWeight: "700",
                }}
              >
                Clear Cart
              </Text>
            </TouchableOpacity>
          </StyledView>
          <ScrollView>
            {cart?.cartData.map((v, index) => {
              const item = v?.productData || {}
              return (
                <View
                  key={index}
                  className={clubCardButtonPressableStyle}
                >
                  <StyledView className='flex flex-row justify-between'>
                    <StyledView className="flex flex-row content-center h-200">
                      <StyledView>
                        <StyledImage
                          className="rounded"
                          style={{ width: 100, height: 100 }}
                          source={{ uri: "https://cdn.shopify.com/s/files/1/0614/0379/1520/files/" + item?.image }}
                          alt={item?.club_full_name}
                        />
                      </StyledView>
                      <StyledView>
                        <View>
                          <StyledView  className='flex w-9/12 ml-0'>
                          <StyledText className={clubCardButtonLabelStyle}>
                            {item?.club_full_name}
                          </StyledText>
                          </StyledView>
                          {item?.hoselSetting ? (
                            <StyledText className={clubCardHoselStyle}>
                              Hosel: {item?.hoselSetting}
                            </StyledText>
                          ) : null}
                        </View>

                      </StyledView>
                    </StyledView>
                    <StyledPressable onPress={() => handleRemoveCart(item._id)}>
                      <MaterialIcons name="close" color="#000" size={30} />
                    </StyledPressable>
                  </StyledView>
                </View>
              )
            })}
            {isLoading ? (
              <View className="m-2 flex flex-row items-center justify-center">
                <ActivityIndicator size="large" color="rgba(170,218,253,1)" />
              </View>
            ) :(
            <MyButton
              label={"Proceed"}
              theme={"viewClubs"}
              onPress={() => handleCart()}
            />)}
          </ScrollView>
        </>
      ) : (
        <View style={{ flex: 1, justifyContent: 'center', alignSelf: 'center' }}>
          <Text style={{ fontSize: 17 }}> No item is in the cart.</Text>
        </View>
      )}
      <SuccessModal
        modalVisible={isModalVisible}
        modelWidth={modelWidth}
        handleOutsideClick={handleOutsideClick}
        description={"Your Order Created Successfully"}
        status={orderStatus}
        date={deliveryDate}
        btnLabel1={"Ok"}
      />
    </View>
  );
}

const style = StyleSheet.create({
  loginBtn: {
    backgroundColor: "#80CAFF",
    borderRadius: 5,
    padding: 10,
    justifyContent: 'center',
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20
  },
});
