
export default class CrashLogger {
  static instance;

  static getInstance() {
    if (!CrashLogger.instance) {
      CrashLogger.instance = new CrashLogger();
    }
    return CrashLogger.instance;
  }

  async logAppLaunch() {
    console.log('CRASH LOGS: App launched.')
  }

  async logSignin(user, hasCreatedAccount = false) {
    console.log(`CRASH LOGS: ${hasCreatedAccount ? 'User created account and signed in.' : 'User signed in.'}`)
    crashlytics().log(hasCreatedAccount ? 'User created account and signed in.' : 'User signed in.');
  }
  
}