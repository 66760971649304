import React from 'react'
import { createStackNavigator } from '@react-navigation/stack';
import Cart from '../../components/sideMenu/Cart/Cart';

const Stack = createStackNavigator()

const TabFiveStack = () => {
  return (
    <Stack.Navigator screenOptions={{
      headerShown: false,
    }}>
      <Stack.Screen name="Cart" component={Cart}  options={{ title: 'golf-eq-app' }} />
    </Stack.Navigator>
  )
}

export default TabFiveStack