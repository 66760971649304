import { StyleSheet, View, Pressable, Text } from "react-native";
import { styled } from "nativewind";
import { 
  submitButtonLabelStyle,
  submitButtonPressableStyle,
  submitButtonViewStyle,
  navMenuButtonLabelStyle,
  navMenuButtonPressableStyle,
  navMenuButtonViewStyle,
  viewClubsButtonLabelStyle,
  viewClubsButtonPressableStyle,
  viewClubsButtonViewStyle
} from "./Styles";

// https://www.nativewind.dev/core-concepts/states
const StyledPressable = styled(Pressable);
const StyledText = styled(Text);
const StyledView = styled(View);

export default function MyButton({ label, theme, onPress, image }) {

  const colorDark = "#8AD6E6";

  if (theme === "primary") {
    return (
      <View 
        // style={[
        // styles.buttonContainer,
        // { borderWidth: 3, borderColor: colorDark }
      // ]}
      >
        <Pressable
          style={
            [
              styles.button,
              { 
                backgroundColor: colorDark,
              }
            ]
          }
          onPress={onPress}
        >
          <Text style={[styles.buttonLabel, { color: "#25292e" }]}>{label}</Text>
        </Pressable>
      </View>
    );
  } else if (theme === "submit") {
    return (
      <StyledPressable
        onPress={onPress}
        className={submitButtonPressableStyle}
      >
        <StyledText 
          className={submitButtonLabelStyle}
        >
          {label}
        </StyledText>
      </StyledPressable>
    );
  } else if (theme === "navMenu") {
    return (
      <StyledPressable
        onPress={onPress}
        className={navMenuButtonPressableStyle}
      >
        <StyledText 
          className={navMenuButtonLabelStyle}
        >
          {label}
        </StyledText>
      </StyledPressable>
    );
  } else if (theme === "viewClubs") {
    return (
      <StyledPressable
        onPress={onPress}
        className={viewClubsButtonPressableStyle}
      >
        <StyledText 
          className={viewClubsButtonLabelStyle}
        >
          {label}
        </StyledText>
      </StyledPressable>
    );
    } else if (theme === "clubCard") {
  return (
    <StyledPressable
      onPress={onPress}
      className={viewClubsButtonPressableStyle}
    >
      <StyledText 
        className={viewClubsButtonLabelStyle}
      >
        {label}
      </StyledText>
    </StyledPressable>
  );
}

  return (
    <View style={styles.buttonContainer}>
      <Pressable 
        style={styles.button}
        onPress={onPress}
      >
        <Text style={styles.buttonLabel}>{label}</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    width: 200,
    height: 40,
    marginHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    borderRadius: 10,
  },
  button: {
    borderRadius: 4,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 6,
    paddingVertical: 2,
    padding: 10,
  },
  buttonIcon: {
    paddingRight: 8,
  },
  buttonLabel: {
    color: '#fff',
    fontSize: 16,
    fontWeight: "bold"
  },
});
