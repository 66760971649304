import { ErrorMessages } from "./Strings";

export const showError = (errorCode) => {
    switch (errorCode) {
        case 'auth/user-not-found':
            alert(ErrorMessages.UserNotFound)
            break;
        case 'auth/wrong-password':
            alert(ErrorMessages.WrongCredentials)
            break;
        case 'auth/email-already-in-use':
            alert(ErrorMessages.Account_Exists)
            break;
        case 'auth/weak-password':
            alert(ErrorMessages.WeakPassword)
            break;
        case 'auth/too-many-requests':
            alert(ErrorMessages.TooManyRequests)
            break;
        default:
            alert(ErrorMessages.GeneralError)
            break;
    }
}

export const recommendedCategories = [
    {
        number: 1,
        label: "Driver"
    },
    {
        number: 2,
        label: "Club 2"
    },
    {
        number: 3,
        label: "Club 3",
    },
    {
        number: 4,
        label: "Club 4",
    },
    {
        number: 5,
        label: "Club 5"
    },
    {
        number: 6,
        label: "Club 6"
    },
    {
        number: 7,
        label: "Club 7"
    },
    {
        number: 8,
        label: "Club 8"
    },
    {
        number: 9,
        label: "Club 9"
    },
    {
        number: 10,
        label: "Club 10"
    },
    {
        number: 11,
        label: "Club 11"
    },
    {
        number: 12,
        label: "Club 12"
    },
    {
        number: 13,
        label: "Club 13"
    },
    {
        number: 14,
        label: "Putter"
    },
    {
        number: 15,
        label: "Golf Ball"
    }
]

export const readableFittingParamNames = {
    clubLoft: "Loft",
    clubLie: "Lie",
    clubFaceAngle: "Face Angle",
    clubCgx: "Center of Gravity X",
    clubCgyz: "Center of Gravity YZ",
    clubShaftKickPoint: "Shaft Kick Point",
    clubShaftWeight: "Shaft Weight",
    clubShaftFlexes: "Shaft Flex",
    clubLength: "Length",
    clubSwingWeight: "Swing Weight",
    optimizedCarryDistance: "Optimized Carry Distance",
    optimizedTotalDistance: "Optimized Total Distance",
    shaftKickPoint: "Shaft Kick Point",
    shaftHandleFirmness: "Shaft Handle Firmness",
    shaftMidSectionFirmness: "Shaft Mid Section Firmness",
    shaftTipFirmness: "Shaft Tip Firmness",
    shaftTorque: "Shaft Torque",
    gripName: "Grip Name",
    clubType: "Club Type",
    clubName: "Club Name",
    hoselSetting: "Hosel Setting",
    offset: "Off Set",
    shaftName: "Shaft Name",
    clubShaftType: "Club Shaft Type",
}
export const routeNames = [
    'Builds',
    'Dashboard',
    'Transaction',
    'manageAccount',
    'Payout',
    'Store',
    'Contact',
    'Booking',
    'Profile',
    'AiFitterHome',
    'AiCaddie'

  ];
  export const routeNameBottom = [
    'Dashboard',
    'Booking',
    'Cart',
  ];
  export const routeNamesWithBack = [
    'SwingDataFrom',
    'ViewClubs',
    'AiFitterHome',
    'FittingResults',
    'FittingResultsEditable',
    'AiCaddie',
    'ExportUsers',
    'ImportUsers',
    'UserProfile',
    'DeleteAccount',
    'Profile',
    'CreateUser',
    'RecommendedClubs',
    'RecommendedGrips',
    'RecommendedBalls',
    'RecommendedShaft',
    'CalendarComponent',
    'WITB',
    'Putter',
    'GolfBall',
    'OrderDetail'
  ];

export const editableFittingParamNames = [
    {
        index: 1,
        label: "Club Name",
        name: "clubName",
        list: false,
        combobox:true,
        pickerItems: ["Driver", "Fairway", "Hybrid", "Iron", "Wedge", "Putter"] ,
    },
    {
        index: 2,
        label: "Club Type",
        name: "clubType",
        list: true,
        pickerItems: ["Driver", "Fairway", "Hybrid", "Iron", "Wedge", "Putter"],
        placeholder:"Driver"
    },
    {
        index: 3,
        label: "Hosel Setting",
        name: "hoselSetting",
        list: false,
        combobox:false,
        textinput:true,
        placeholder:'STD',
    },
    {
        index: 4,
        label: "Loft",
        name: "clubLoft",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((70 - 1) / 0.25) + 1 }, (_, i) => 1 + i * 0.25),
    },
    {
        index: 5,
        label: "Lie",
        name: "clubLie",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((70 - 50) / 0.5) + 1 }, (_, i) => 50 + i * 0.5),
    },
    {
        index: 6,
        label: "Face Angle",
        name: "clubFaceAngle",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((10 - -10) / 0.5) + 1 }, (_, i) => -10 + i * 0.5),
    },
    {
        index: 7,
        label: "Center of Gravity X",
        name: "clubCgx",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((3 - 0) / 0.5) + 1 }, (_, i) => 0 + i * 0.5),
    },
    {
        index: 8,
        label: "Center of Gravity YZ",
        name: "clubCgyz",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((14 - 0) / 0.5) + 1 }, (_, i) => 0 + i * 0.5),
    },
    {
        index: 9,
        label: "Offset",
        name: "offset",
        list: false,
        textinput:true,
        pickerItems: Array.from({ length: Math.ceil((5 - 0) / 1) + 1 }, (_, i) => 0 + i * 1),
        placeholder:'0',
    },
    {
        index: 10,
        label: "Shaft Name",
        name: "shaftName",
        list: false,
        combobox:true,
        pickerItems: ["Stock shaft"],
        placeholder:"Stock shaft"
    },
    {
        index: 11,
        label: "Shaft Type",
        name: "clubShaftType",
        list: true,
        pickerItems: ["wood", "hybrid", "iron", "wedge", "putter"],
        placeholder:'wood'
    },
    {
        index: 12,
        label: "Shaft Kick Point",
        name: "clubShaftKickPoint",
        list: true,
        pickerItems:["Low", "Mid","High"],
    },
    {
        index: 13,
        label: "Shaft Weight",
        name: "clubShaftWeight",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((150 - 30) / 5) + 1 }, (_, i) => 0 + i * 5),
    },
    {
        index: 14,
        label: "Shaft Flex",
        name: "clubShaftFlexes",
        list: true,
        pickerItems: ["Amateur" ,"Ladies","Regular","Stiff","X-Stiff","TX","Other"],
    },
    {
        index: 15,
        label: "Club Lenght",
        name: "clubLength",
        list: false,
        textinput:true,
        placeholder:'lenght'
    },
    // {
    //     index: 16,
    //     label: "Swing Weight",
    //     name: "clubSwingWeight",
    //     list: true,
    //     pickerItems: Array.from({ length: 63 }, (_, i) => {
    //         const letter = String.fromCharCode(65 + Math.floor(i / 9));
    //         const num = i % 9 + 1;
    //         return `${letter}${num}`;
    //     })    
    // },
    {
        index: 17,
        label: "Shaft Kick Point",
        name: "shaftKickPoint",
        list: true,
        pickerItems: ["High", "Medium","Low"],
        placeholder:'Medium'

    },
    {
        index: 18,
        label: "Shaft Handle Firmness",
        name: "shaftHandleFirmness",
        list: true,
        pickerItems: ["Ultra - Stiff","Stiff","Firm", "Soft"],
        placeholder:'Firm',

    },
    {
        index: 19,
        label: "Shaft Mid Section Firmness",
        name: "shaftMidSectionFirmness",
        list: true,
        pickerItems: ["Ultra - Stiff", "Stiff", "Firm", "Soft"],
        placeholder:'Firm'

    },
    {
        index: 20,
        label: "Shaft Tip Firmness",
        name: "shaftTipFirmness",
        list: true,
        pickerItems: ["Ultra - Stiff", "Stiff", "Firm", "Soft"],
        placeholder:'Firm'

    },
    {
        index: 21,
        label: "Shaft Torque",
        name: "shaftTorque",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((5 - 0.5) / 0.5) + 1 }, (_, i) => 0.5 + i * 0.5),
        placeholder:2

    },
    {
        index: 22,
        label: "Grip Name",
        name: "gripName",
        list: false,
        combobox:true,
        placeholder:"Stock grip",
        pickerItems:["Stock grip"],
      

    },
    {
        index: 23,
        label: "Optimized Carry Distance",
        name: "optimizedCarryDistance",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((400 - 0) / 5) + 1 }, (_, i) => 0 + i * 5),
    },
    {
        index: 24,
        label: "Optimized Total Distance",
        name: "optimizedTotalDistance",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((400 - 0) / 5) + 1 }, (_, i) => 0 + i * 5),
    }
]
export const putter = [
    {
        index: 1,
        label: "Club Type",
        name: "clubShaftKickPoint",
        list: true,
        pickerItems: ["Driver", "Fairway", "Hybrid", "Iron", "Wedge", "Putter" ],
    },

    {
        index: 2,
        label: "Loft",
        name: "clubLoft",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((75 - 1) / 0.25) + 1 }, (_, i) => 1 + i * 0.25),
    },
    {
        index: 3,
        label: "Lie",
        name: "clubLie",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((65 - 55) / 0.5) + 1 }, (_, i) => 55 + i * 0.5),
    },
    {
        index: 4,
        label: "Face Angle",
        name: "clubFaceAngle",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((5 - -5) / 0.5) + 1 }, (_, i) => -5 + i * 0.5),
    },
    {
        index: 5,
        label: "CGx",
        name: "clubCgx",
        list: true,
        pickerItems: ["Heel", "Mid", "Toe"]
    },
    {
        index: 6,
        label: "CGy",
        name: "clubCgy",
        list: true,
        pickerItems: ["Low","Mid","High"],
    },
    {
        index: 7,
        label: "CGz",
        name: "clubCgz",
        list: true,
        pickerItems: ["Rare","Mid","Front"],
    },
    {
        index: 8,
        label: "Offset",
        name: "offset",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((5 - 0) / 1) + 1 }, (_, i) => 0 + i * 1),
    },
    {
        index: 9,
        label: "Shaft Type",
        name: "clubShaftType",
        list: true,
        pickerItems: ["wood", "hybrid", "iron", "wedge", "putter"]
    },
    {
        index: 10,
        label: "Shaft Weight",
        name: "clubShaftWeight",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((150 - 30) / 5) + 1 }, (_, i) => 30 + i * 5),
    },
    {
        index: 11,
        label: "Shaft Flex",
        name: "shaftFlex",
        list: true,
        pickerItems: ["Regular", "Stiff", "X - Stiff", "TX - Stiff", "Other"],
    },
    {
        index: 12,
        label: "Shaft Flex(CPM)",
        name: "shaftFlexCPM",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((350 - 200) / 5) + 1 }, (_, i) => 200 + i * 5)

    },
    {
        index: 13,
        label: "Shaft Kick Point",
        name: "shaftKickPoint",
        list: true,
        pickerItems: ["High", "Low","Mid"]

    },
    {
        index: 14,
        label: "Shaft Kick Point(Handle)",
        name: "shaftKickPoint(Handle)",
        list: true,
        pickerItems: ["Ultra - Stiff","Stiff","Firm", "Soft"]

    },
    {
        index: 15,
        label: "Shaft Kick Point (Mid Section)",
        name: "shaftKickPoint(MidSection)",
        list: true,
        pickerItems: ["Ultra - Stiff", "Stiff", "Firm", "Soft"]

    },
    {
        index: 16,
        label: "Shaft Kick Point (Tip)",
        name: "shaftKickPoint(Tip)",
        list: true,
        pickerItems: ["Ultra - Stiff", "Stiff", "Firm", "Soft"]

    },
    {
        index: 17,
        label: "Shaft Torque",
        name: "shaftTorque",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((5 - 0.5) / 0.5) + 1 }, (_, i) => 0.5 + i * 0.5),

    },
   
]
export const golfBall = [

    {
        index: 1,
        label: "Compression",
        name: "compression",
        list: true,
        pickerItems: Array.from({ length: Math.ceil((120 - 50) / 1) + 1 }, (_, i) => 50 + i * 1),
    },
]

export const defaultValues = {
    speed: 90,
    clubPath: -3,
    attackAngle: -2,
    miss: 'Slice',
    priority: 'Both',
    shaftFeel: 'Softer',
    shaftWeight: 'Lighter',
    ironSwingSpeed: 90,
    ironClubPath: -3,
    ironAttackAngle: -2,
    ironMiss: 'Slice',
    brands: []
}

export const swingInputs = [
    {
        index: 1,
        label: "Driver Swing Speed",
        name: "speed",
        list: true,
        pickerItems: [70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130],
    },
    {
        index: 2,
        label: "Driver Club Path",
        name: "clubPath",
        list: true,
        pickerItems: [-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
        index: 3,
        label: "Driver Attack Angle",
        name: "attackAngle",
        pickerItems: [-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        list: true,
    },
    {
        index: 4,
        label: "What is your most common driver miss?",
        name: "miss",
        list: true,
        pickerItems: ["hook", "pull", "push", "slice"],
    },
    {
        index: 8,
        label: "Include Iron Data?",
        name: "hasIronData",
        list: false,
        checkbox: true,
    },
    {
        index: 5,
        label: "What is your priority?",
        name: "priority",
        list: true,
        pickerItems: ["distance", "accuracy", "both"],
    },
    {
        index: 6,
        label: "Do you prefer a softer or firmer shaft?",
        name: "shaftFeel",
        list: true,
        pickerItems: ["softer", "firmer"],
    },
    {
        index: 7,
        label: "Do you prefer a lighter or heavier shaft?",
        name: "shaftWeight",
        list: true,
        pickerItems: ["lighter", "heavier"],
    },
    {
        index: 15,
        label: "Brand Preferences",
        name: "brands",
        list: false,
        tags: true,
        checkbox: false,
        pickerItems: ["Ping", "Cobra", "Mizuno", "Srixon", "Callaway", "Cleveland", "TaylorMade", "Titleist", "PXG"],
    },
]

export const ironInputs = [{
    index: 9,
    label: "Iron Swing Speed",
    name: "ironSwingSpeed",
    list: true,
    selection: defaultValues.ironSwingSpeed,
    pickerItems: [70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130],
},
{
    index: 10,
    label: "Iron Club Path",
    name: "ironClubPath",
    list: true,
    selection: defaultValues.ironClubPath,
    pickerItems: [-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
},
{
    index: 11,
    label: "Iron Attack Angle",
    name: "ironAttackAngle",
    selection: defaultValues.ironAttackAngle,
    pickerItems: [-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    list: true,
},
{
    index: 12,
    label: "What is your most common miss with your Iron?",
    name: "ironMiss",
    list: true,
    selection: defaultValues.ironMiss,
    pickerItems: ["hook", "pull", "push", "slice"],
}]

export const WITBInputs = [
    {
        index: 1,
        label: "Driver",
        name: "driver",
        selection: '',
    },
    {
        index: 2,
        label: "Club 2",
        name: "club2",
        selection: '',
    },
    {
        index: 3,
        label: "Club 3",
        name: "club3",
        selection: '',
    },
    {
        index: 4,
        label: "Club 4",
        name: "club4",
        selection: '',
    },
    {
        index: 5,
        label: "Club 5",
        name: "club5",
        selection: '',
    },
    {
        index: 6,
        label: "Club 6",
        name: "club6",
        selection: '',
    },
    {
        index: 7,
        label: "Club 7",
        name: "club7",
        selection: '',
    },
    {
        index: 8,
        label: "Club 8",
        name: "club8",
        selection: '',
    },
    {
        index: 9,
        label: "Club 9",
        name: "club9",
        selection: '',
    },
    {
        index: 10,
        label: "Club 10",
        name: "club10",
        selection: '',
    },
    {
        index: 11,
        label: "Club 11",
        name: "club11",
        selection: '',
    },
    {
        index: 12,
        label: "Club 12",
        name: "club12",
        selection: '',
    },
    {
        index: 13,
        label: "Club 13",
        name: "club13",
        selection: '',
    },
    {
        index: 14,
        label: "Putter",
        name: "putter",
        selection: '',
    },
    {
        index: 15,
        label: "Golf Ball",
        name: "golfBall",
        selection: '',
    },
    {
        index: 16,
        label: "Grips",
        name: "grips",
        selection: '',
    },
    {
        index: 17,
        label: "Golf Glove",
        name: "golfGlove",
        selection: '',
    },
    {
        index: 18,
        label: "Golf Bag",
        name: "golfBag",
        selection: '',
    },
];

export const ProfileInputs = [
    {
        index: 1,
        label: "First Name",
        name: "firstName",
        selection: '',
    },
    {
        index: 2,
        label: "Last Name",
        name: "lastName",
        selection: '',
    },
    {
        index: 3,
        label: "Height",
        name: "height",
        list: true,
        pickerItems: Array.from({ length: (7 * 12 + 12) - (3 * 12 + 1) + 1 }, (_, i) => {
            const totalInches = (3 * 12 + 1) + i;
            const feet = Math.floor(totalInches / 12);
            const inches = totalInches % 12;
            return `${feet}'${inches}"`;
        }),
    },
    {
        index: 4,
        label: "Weight",
        name: "weight",
        list: true,
        pickerItems: Array.from({ length: (200 - 40) / 0.5 + 1 }, (_, i) => 40 + (i * 0.5)),
    },
    {
        index: 5,
        label: "Handicap",
        name: "handicap",
        list: true,
        pickerItems: Array.from({ length: 41 }, (_, i) => 10 - i),
    },
    {
        index: 6,
        label: "Rounds played per year",
        name: "roundsPerYear",
        selection: '',
    },
    {
        index: 7,
        label: "Handedness",
        name: "handedness",
        list: true,
        pickerItems:["Right handed","Left handed"],
        placeholder:"Right handed",
    },
];

export const OrgnasationSideMenu = [
   
    {   
        label: "Dashboard",
        screen: "Dashboard"
    },
    {
        label: "Golfers",
        screen: "Golfers"
    },
    {
        label: "Orders",
        screen: "Orders"
    },
    {
        label: "Transaction",
        screen: "Transactions"
    },
    {
        label: "Booking",
        screen: "Booking"
    },
    {
        label: "Manage Store",
        screen: "Store"
    },
    {
        label: "Payouts",
        screen: "Payout"
    },
    {
        label: "Manage Team",
        screen: "UserList"
    }, 
   
]

export const ViewerSideMenu = [
    {
        label: "Dashboard",
        screen: "viewGolfer"
    },
    {
        label: "Golfers",
        screen: "Golfers"
    },
    {
        label: "Export Users",
        screen: "ExportUsers"
    },
    {
        label: "Manage Team",
        screen: "UserList"
    },
    {
        label: "Manage Account",
        screen: "manageAccount"
    },
    {
        label: "Contact",
        screen: "Contact"
    },
]

export const GolferSideMenu = [
    {
        label: "AI Caddie",
        screen: "AiCaddie"
    },
    {
        label: "AI Fitter",
        screen: "AiFitterHome"
    },
    {
        label: "Golfer Profile",
        screen: "Profile"
    },
    {
        label: "Manage Account",
        screen: "manageAccount"
    },
    {
        label: "Contact",
        screen: "Contact"
    },
]

export const BasicInfoInputs = [
    {
        index: 1,
        label: "First Name",
        name: "firstName",
        selection: '',
    },
    {
        index: 2,
        label: "Last Name",
        name: "lastName",
        selection: '',
    },
    {
        index: 3,
        label: "Email Address",
        name: "email",
        selection: '',
        // disabled: true,
    },
    {
        index: 4,
        label: "Website",
        name: "website",
        selection: '',
    },
];

export const ScreenNames = {
    LoginForm: 'login',
    RegisterForm: 'register',
    Home: 'home',
    UsersListing: 'usersListing',
    UserProfile: 'userProfile',
    ExportUsers: 'exportUsers',
    GolfersListing: 'golfersListing',
    AddGolfers: 'addGolfers',
    AddTeamMembers: 'addTeamMembers',
    SwingDataForm: 'swingDataForm',
    ClubsListing: 'clubsListing',
    ManageAccount: 'manageAccount',
    DeleteAccount: 'deleteAccount',
    AIFitter: 'aIFitter',
    AICaddie: 'aICaddie',
    FittingResults: 'fittingResults',
    ForgotPassword : 'forgotPassword',
    ImportUsers: 'ImportUsers',
    FittingResultsEditable:'fittingResultsEditable',
    TabNavigation: 'tabNavigation',
    Dashboard:'dashboard',
    Transactions:'transcations',
    Builds:'builds',
    Payout:'payout',
    Store:'store',
    AIFitterHome: 'aiFitterHome',
    RecommendedClubs: 'recommendedClubs',
    RecommendedShaft:'recommendedShaft',
    RecommendedGrips:'recommendedGrips',
    RecommendedBalls:'recommendedBalls',
    GolfBall:'golfBall',
    Putter :'putter',

}

export const EventNames = {
    productOpening : 'productOpening'
}

export const generateRandomPassword = (length, includeSymbols = true) => {
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseChars = lowercaseChars.toUpperCase();
    const numbers = "0123456789";
    const symbols = includeSymbols ? "!@#$%&*" : "";
    const allChars = lowercaseChars + uppercaseChars + numbers + symbols;

    let password = "";
    let charGroupsMet = { lowercase: false, uppercase: false, number: false };

    // Ensure at least one character from each group
    while (!(charGroupsMet.lowercase && charGroupsMet.uppercase && charGroupsMet.number)) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        const char = allChars.charAt(randomIndex);

        if (/[a-z]/.test(char) && !charGroupsMet.lowercase) {
            charGroupsMet.lowercase = true;
        } else if (/[A-Z]/.test(char) && !charGroupsMet.uppercase) {
            charGroupsMet.uppercase = true;
        } else if (/[0-9]/.test(char) && !charGroupsMet.number) {
            charGroupsMet.number = true;
        }

        password += char;
    }

    // Fill remaining characters with any character from the pool
    while (password.length < length) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        password += allChars.charAt(randomIndex);
    }

    return password;
}

export const userFittingResultsKeys = {
    clubLoft: null,
    clubLie: null,
    clubFaceAngle: null,
    clubCgx: null,
    clubCgyz: null,
    clubShaftKickPoint: null,
    clubShaftWeight: null,
    clubShaftFlexes: null,
    clubLength: "lenght",
    clubSwingWeight: null,
    optimizedCarryDistance: null,
    optimizedTotalDistance: null,
    clubName: "",
    clubType: "Driver",
    hoselSetting: "STD",
    offset: "0",
    shaftName: "Wedge",
    clubShaftType: "wood",
    shaftKickPoint : "Medium",
    shaftMidSectionFirmness : "Firm",
    shaftHandleFirmness : "Firm",
    shaftTipFirmness : "Firm",
    shaftTorque : 2,
    gripName : "Stock grip",
}