import { styled } from "nativewind";
import { useEffect, useState } from 'react';
import { ActivityIndicator, Image, KeyboardAvoidingView, Pressable, SafeAreaView, ScrollView, Text, View, Platform } from 'react-native';

import MyButton from '../MyButton';
import MyTextInput from '../MyTextInput';

import { useDispatch, useSelector } from 'react-redux';
import { fetchForgotPasswordIdle, fetchForgotPasswordRequest, fetchRegisterRequest, fetchResetPasswordIdle, fetchResetPasswordRequest } from '../../store/ducks/app/actions';
import { APIStates } from '../../utils/Strings';
import {
  pageTitleStyle,
  textDescriptionStyle,
  textFunStyle,
  textStyle,
  textStyleXL
} from '../Styles';
import AnalyticsLogger from "../managers/AnalyticsLogger";
import { ScreenNames } from "../../utils/Util";

const StyledPressable = styled(Pressable);
const StyledView = styled(View);
const StyledText = styled(Text);
const StyledImage = styled(Image);

export default function ForgotForm({ setShowForgot }) {

  const dispatch = useDispatch()
  const forgotPassword = useSelector(state => state.app.forgotPassword)
  const resetPassword = useSelector(state => state.app.resetPassword)
  const [email, onChangeEmail] = useState("");
  const [password, onChangePassword] = useState("");
  const [otp, onChangeOTP] = useState("");
  const [passwordConfirm, onChangePasswordConfirm] = useState("");
  const [currentStep, setCurrentStep] = useState(1)

  useEffect(() => {
    AnalyticsLogger.getInstance().logScreenView(ScreenNames.ForgotPassword)
  }, [])

  const onSubmit = () => {
    if (currentStep == 1) {
      if (email == '') {
        alert('Email is required.')
        return
      }

      // Hit Forgot Password API
      dispatch(fetchForgotPasswordRequest({
        email: email
      }))

    } else if (currentStep == 2) {
      if (email == '') {
        alert('OTP is required.')
        return
      }
      if (password == '') {
        alert('Password is required.')
        return
      }
      if (password !== passwordConfirm) {
        alert("Passwords do not match, please try again.");
        return null;
      }

      // Hit Reset Password API
      dispatch(fetchResetPasswordRequest({
        token: otp,
        password: password
      }))
    }
  };

  useEffect(() => {
    if (forgotPassword.status == APIStates.SUCCESS) {
      alert('Please check your email for the OTP.')
      setCurrentStep(2)
      dispatch(fetchForgotPasswordIdle())
    } else if (forgotPassword.status == APIStates.FAILURE) {
      alert(forgotPassword.message)
      dispatch(fetchForgotPasswordIdle())
    }
  }, [forgotPassword.status])

  useEffect(() => {
    if (resetPassword.status == APIStates.SUCCESS) {
      alert('Password reset successfully. Please login.')
      setShowForgot(false)
      dispatch(fetchResetPasswordIdle())
    } else if (resetPassword.status == APIStates.FAILURE) {
      alert(resetPassword.message)
      dispatch(fetchResetPasswordIdle())
    }
  }, [resetPassword.status])


  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={50}
      >
        <ScrollView>
          <StyledView className="flex flex-col" >
            <StyledView
              className='flex flex-row items-center justify-center'
            >
              <StyledImage
                className="rounded"
                style={{ width: 200, height: 200 }}
                source={require('../../assets/icon.png')}
                alt={"logo"}
              />
            </StyledView>
            <StyledText className={pageTitleStyle + " m-2"}>
              Forgot Password
            </StyledText>
            </StyledView>
            {
              currentStep == 1 ?
                // <View className='flex flex-column items-center justify-center'>
                <View>
                  <StyledView className='m-2 flex flex-row items-center justify-center'>
                    <StyledPressable
                      onPress={() => setShowForgot(true)}
                    >
                      <StyledText className={textStyleXL}>
                        Please enter your email address to reset your password.
                      </StyledText>
                    </StyledPressable>
                  </StyledView>
                  <View style={{width:'70%',flex:1,justifyContent:'center',alignSelf:'center'}}>
                  <MyTextInput
                    label={"Email Address"}
                    type={"email"}
                    value={email}
                    onChangeValue={onChangeEmail}
                  />
                  </View>
                </View>
                : currentStep == 2 &&
                <View>

                  <MyTextInput
                    label={"One-Time Password (OTP) "}
                    type={"email"}
                    placeholder={"Enter OTP"}
                    value={otp}
                    onChangeValue={onChangeOTP}
                  />

                  <MyTextInput
                    label={"Password"}
                    type={"password"}
                    value={password}
                    onChangeValue={onChangePassword}
                  />

                  <MyTextInput
                    label={"Confirm Password"}
                    type={"password"}
                    value={passwordConfirm}
                    onChangeValue={onChangePasswordConfirm}
                  />
                </View>
            }


            {
              forgotPassword.status == APIStates.LOADING || resetPassword.status == APIStates.LOADING ?
                <View className='m-2 flex flex-row items-center justify-center'>
                  <ActivityIndicator size="large" color="rgba(170,218,253,1)" />
                </View>
                :
                <MyButton
                  label={"Reset"}
                  theme={"submit"}
                  onPress={() => onSubmit()}
                />
            }


            <StyledView className='m-2 flex flex-row items-center justify-center'>
              <StyledText className={textStyle}>
                Remember Password?{' '}
              </StyledText>
              <StyledPressable
                onPress={() => setShowForgot(false)}
              >
                <StyledText className={textFunStyle}>
                  Sign in here
                </StyledText>
              </StyledPressable>
            </StyledView>
          {/* </StyledView> */}
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
