import React, { useState, useEffect } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  View,
  Dimensions,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
  TextInput,
  StyleSheet
} from "react-native";
import {
  textInputLabelStyle,
  textInputStyle,
} from "../../Styles";
import { fetchCheckoutRequest, fetchCheckoutIdle, saveBookingRequest } from "../../../store/ducks/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { APIStates } from "../../../utils/Strings";
import { Box } from "native-base";
import { getItemFromStore, setItemInStore } from '../../../utils/storage/storage';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const height = Dimensions.get("window").height;

const PaymentForm = ({
  onPaymentResult,
  selectedService,
  uid,
  timeStamp,
  payment,
  endTime,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const dispatch = useDispatch();
  const Checkout = useSelector((state) => state.app.Checkout);
  const [isCardNumberValid, setIsCardNumberValid] = useState(false);
  const [isExpiryValid, setIsExpiryValid] = useState(false);
  const [isCvcValid, setIsCvcValid] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [codSuccess, setCodSuccess] = useState(false);
  const [purchasedServiceId, setPurchasedServiceId] = useState(false);
  const [cardholderName, setCardholderName] = useState('');
  const [servicePrice, setServicePrice] = useState('');
  const [totalPrice, setTotalPrice] = useState('');

  const serviceId = selectedService?.id;

  const handleCardNumberChange = (event) => {
    setIsCardNumberValid(event.complete);
  };

  const handleExpiryChange = (event) => {
    setIsExpiryValid(event.complete);
  };

  const handleCvcChange = (event) => {
    setIsCvcValid(event.complete);
  };
  useEffect(() => {
    if (serviceId) {
      handleServices();
    }
  }, [serviceId]);

  useEffect(() => {
    handleServices()
  }, [])

  const handleServices = async () => {
    try {
      await setItemInStore('serviceid', selectedService?.id);
      const purchasedServiceId = await getItemFromStore('serviceid');
      setPurchasedServiceId(purchasedServiceId)
    } catch (error) {
      console.error("Error handling services:", error);
    }
  };

  const allInputsValid = isCardNumberValid && isExpiryValid && isCvcValid;

  useEffect(() => {
    if (Checkout.status === APIStates.SUCCESS) {
      if (paymentMethod === "cod" || selectedService?.price === 0) {
        setCodSuccess(true);
      } else if (paymentMethod === "card") {
        setClientSecret(Checkout.data.paymentIntent);
      }
      setApiSuccess(true);
    } else if (Checkout.status === APIStates.FAILURE) {
      alert(Checkout.message);
      dispatch(fetchCheckoutIdle());
      setLoading(false);
    }
  }, [Checkout.status]);

  useEffect(() => {
    if (apiSuccess) {
      if (paymentMethod === "cod" || selectedService?.price === 0) {
        onPaymentResult(true);
        setLoading(false);
      } else if (paymentMethod === "card") {
        handleSubmit();
      }
    }
  }, [apiSuccess]);

  const fetchCheckoutData = async ({ method }) => {
    setLoading(true);
    setPaymentMethod(method);
    dispatch(
      fetchCheckoutRequest({
        userId: uid,
        serviceId: selectedService.id,
        orgId: selectedService.orgId,
        bookingStartDateTime: dayjs(timeStamp).tz("utc").format(),
        bookingEndDateTime: dayjs(timeStamp).add(selectedService?.duration, "minute").tz("utc").format(),
        paymentMethod: method,
        channel: Platform.OS.toLowerCase(),
        cardholderName: cardholderName
      })
    );
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      setError("Stripe.js has not loaded yet.");
      return;
    }

    if (!clientSecret) {
      setError("Missing client_secret for confirming the payment.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          },
        }
      );

      if (error) {
        setError(error.message);
        onPaymentResult(false);
      } else if (paymentIntent.status === "succeeded") {
        onPaymentResult(true);
        dispatch(
          saveBookingRequest({
            userId: uid,
            serviceId: selectedService.id,
            orgId: selectedService.orgId,
            bookingStartDateTime: dayjs(timeStamp).tz("utc").format(),
            bookingEndDateTime: dayjs(timeStamp).add(selectedService?.duration, "minute").tz("utc").format(),
            paymentMethod: "card",
            channel: Platform.OS.toLowerCase(),
            cardholderName: cardholderName
          })
        );
      }
    } catch (error) {
      setError(error.message);
      onPaymentResult(false);
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    const processingFee = 0.045; // 4.5% fee
    const servicePrice = selectedService.price * processingFee;
    const totalPrice = selectedService.price + selectedService.price * processingFee;
    setServicePrice(servicePrice)
    setTotalPrice(totalPrice)
    // Round to 2 decimal places
  }, [selectedService.price]);


  return (
    <View>
      {payment.label === "Cash on delivery" ? (
        <Box>
          <View style={{ flex: 1 / 2, justifyContent: "center", alignItems: "center" }}>
            {isLoading ? (
              <View className="m-2 flex flex-row items-center justify-center">
                <ActivityIndicator size="large" color="rgba(170,218,253,1)" />
              </View>
            ) : codSuccess ? (
              <Text style={{ fontSize: 20, marginTop: 30 }}>
                You have booked your slot
              </Text>
            ) : (
              <TouchableOpacity
                style={{ paddingLeft: 20 }}
                onPress={() => fetchCheckoutData({ method: "cod" })}
              >
                <Text
                  style={{ color: "white" }}
                  className={
                    "text-xl mt-2 mx-auto px-10 py-2 flex flex-row items-center justify-center shadow rounded-md bg-blue-500"
                  }
                >
                  Book Slot
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </Box>
      ) : (
        <form>
          <View>
            <Text className={textInputLabelStyle}>CardHolder Name</Text>
            <View
              className={textInputStyle}
              style={{
                height: height * 0.0612,
                marginVertical: 8,
                padding: 8,
                fontSize: 24,
                borderWidth: 1,
                borderColor: "#cccccc",
                borderRadius: 8,
                justifyContent: "center",
                alignContent: "center",

              }}
            >
              <TextInput
                style={{
                  fontSize: 16,
                  color: "#424770",
                  letterSpacing: 0.7,
                  fontFamily: "Source Code Pro, monospace",
                  borderWidth: 0,
                  outlineWidth: 0,
                  fontWeight: '400'
                }}

                placeholderTextColor={"#aab7c4"}
                placeholder="CardHolder Name"
                value={cardholderName}
                onChangeText={setCardholderName}
              />
            </View>
          </View>
          {/* Card Number */}
          <label style={{ width: "90%", marginBottom: height * 0.04 }}>
            <Text className={textInputLabelStyle}>Card Number</Text>
            <View
              className={textInputStyle}
              style={{
                height: height * 0.0612,
                marginVertical: 8,
                padding: 8,
                fontSize: 24,
                borderWidth: 1,
                borderColor: "#cccccc",
                borderRadius: 8,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <CardNumberElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      letterSpacing: "0.025em",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
                onChange={handleCardNumberChange}
              />
            </View>
          </label>
          {/* Expiry Date */}
          <label>
            <Text className={textInputLabelStyle}>Expiry Date</Text>
            <View
              className={textInputStyle}
              style={{
                height: height * 0.0612,
                marginVertical: 8,
                padding: 8,
                fontSize: 24,
                borderWidth: 1,
                borderColor: "#cccccc",
                borderRadius: 8,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <CardExpiryElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      letterSpacing: "0.025em",
                      fontFamily: "Source Code Pro, monospace",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
                onChange={handleExpiryChange}
              />
            </View>
          </label>
          <label>
            <Text className={textInputLabelStyle}>CVC</Text>
            <View
              className={textInputStyle}
              style={{
                height: height * 0.0612,
                marginVertical: 8,
                padding: 8,
                fontSize: 24,
                borderWidth: 1,
                borderColor: "#cccccc",
                borderRadius: 8,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <CardCvcElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      letterSpacing: "0.025em",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
                onChange={handleCvcChange}
              />
            </View>
          </label>
          <View>
            <Text style={styles.slotTitle}>
              Slot Price:{""}
                <Text style={styles.slotValue}>
                   ${selectedService.price}
                </Text>
            </Text>
            <Text style={styles.slotTitle}>
               Service Fee (4.5%):
                 <Text style={styles.slotValue}>
                   ${servicePrice}
                 </Text>
            </Text>
            <Text style={styles.slotTitle}> 
              Booking Price:
                  <Text style={styles.slotValue}>
                    ${totalPrice}
                  </Text>
            </Text>
          </View>
          {error && (
            <Text
              className="text-text-11 text-base font-medium text-center"
              style={{ color: "red" }}
            >
              Something went wrong with the payment, please try again in a few moments.
            </Text>
          )}

          <View style={{ flex: 1 / 2, justifyContent: "center", alignItems: "center" }}>
            {isLoading ? (
              <View className="m-2 flex flex-row items-center justify-center">
                <ActivityIndicator size="large" color="rgba(170,218,253,1)" />
              </View>
            ) : (
              allInputsValid && (
                <TouchableOpacity
                  style={{ paddingLeft: 20 }}
                  onPress={() => fetchCheckoutData({ method: "card" })}
                >
                  <Text
                    style={{ color: "white" }}
                    className={"text-xl mt-2 mx-auto px-10 py-2 flex flex-row items-center justify-center shadow rounded-md bg-blue-500"}
                  >
                    Pay ${totalPrice}
                  </Text>


                </TouchableOpacity>
              )
            )}
          </View>
        </form>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  slotTitle:{ 
       fontSize: 20, 
       marginRight: 20, 
       color: 'grey' 
  },
  slotValue:{ 
       color: "black",
       marginLeft: 10,
       fontWeight:'600' 
  }
})


export default PaymentForm;
