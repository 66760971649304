import React, { createContext, useState, useContext } from 'react';

// Create the CurrentUserContext
const CurrentUserContext = createContext();

// Create a provider component
export const CurrentUserProvider = ({ children }) => {
  const [currentUser, setSelectedUser] = useState(null);
  const [role, setUserRole] = useState(null);
  const [uid, setUserId] = useState(null);
  const [cart, setCart] = useState({});
  const [orgData, setOrgData] = useState(null);

  const handleRemoveCart = (id) => {
    setCart((prevCart) => {
      const filteredCartData = prevCart?.cartData.filter((item) => {
        // Check if the product IDs match
        return item.productData?._id !== id;
      });
      return {
        golferId: currentUser?.id,
        cartData: filteredCartData,
      };
      
    });
    
  };


  const handleAddCart = (productData, fittingResults) => {
    setCart((prevCart) => {
      const oldCartData = prevCart?.cartData || [];
        return {
        golferId: currentUser?.id,
        cartData: [...oldCartData, { productData, fittingResults }],
      };
    });
   
  };
  

  return (
    <CurrentUserContext.Provider value={{ currentUser, setSelectedUser,orgData, setOrgData, role, setUserRole, uid, setUserId, cart, setCart, handleAddCart, handleRemoveCart,}}>
      {children}
    </CurrentUserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useCurrentUser = () => {
  return useContext(CurrentUserContext);
};
