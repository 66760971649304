import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Platform, Dimensions, SafeAreaView, KeyboardAvoidingView } from 'react-native';
import NoteComponent from './NoteComponent';
import HeaderButton from '../../HeaderButton';
import { updateOrdersRequest, fetchOrdersRequest, updateOrdersIdle } from '../../../../store/ducks/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { APIStates } from '../../../../utils/Strings';
import { getItemFromStore } from '../../../../utils/storage/storage';


const width = Dimensions.get("window").width;
const height = Dimensions.get("window").height
const OrderDetail = ({ route }) => {
    const updateOrders = useSelector(state => state.app.updateOrders);
    const { order } = route.params || {};
    const [note, setUpdatedNote] = useState();
    const dispatch = useDispatch();
    const orderUpdate = useSelector(state => state.app.updateOrders);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (orderUpdate.status == APIStates.SUCCESS) {
            setLoading(false)
            fetchOrders()
            dispatch(updateOrdersIdle())
            alert('Data Updated Successfully')
        }
    }, [orderUpdate.status])


    useEffect(() => {
        if (order.details.notes) {
            setUpdatedNote(order.details.notes);
        }
    }, [order?.details?.notes])

    const fetchOrders = async () => {
        const orgId = await getItemFromStore('orgId')
        dispatch(fetchOrdersRequest({
            orgId: orgId,
        }));
    }


    const handleNoteChange = (newNote) => {
        setUpdatedNote(newNote);
    };

    const handleUpdate = () => {
        setLoading(true)
        const orderDetail = order.details;
        dispatch(updateOrdersRequest({
            orderId: order.id,
            updateObj: {
                details: {
                    ...orderDetail,
                    notes: note
                }
            }
        }))
    }
    
    const columnsData = [
        {
            header: 'Club Spec',
            items: [
                { label: 'Model:', key: 'model' },
                { label: 'Loft:', key: 'clubLoft' },
                { label: 'Lie:', key: 'clubLie' },
                { label: 'Face Angle:', key: 'clubFaceAngle' },
                { label: 'Setting:', key: 'hoselSetting' },
                { label: 'Swing Weight:', key: 'clubSwingWeight' },
            ],
        },
        {
            header: 'Shaft Spec',
            items: [
                { label: 'Model:', key: 'shaftModel' },
                { label: 'Weight:', key: 'clubShaftWeight' },
                { label: 'Flex:', key: 'clubShaftFlexes' },
                { label: 'Length:', key: 'clubLength' },
            ],
        },
        {
            header: 'Grip Spec',
            items: [
                { label: 'Model:', key: 'gripModel' },
                { label: 'Size:', key: 'gripSize' },
            ],
        },
    ];


    return (
        <View style={{ flex: 1, justifyContent: 'center', alignSelf: 'center', backgroundColor: 'white' }}>
            <SafeAreaView style={styles.safeAreaStyle}>
                <KeyboardAvoidingView behavior="padding">
                    <HeaderButton title={"Orders"} btn1={"Add Order"} btn2={"Export Order"} description={"Manage and View details of your Orders"} />
                    <View style={styles.container}>
                        <View style={styles.row}>
                            {columnsData.map((column, index) => (
                                <View key={index} style={styles.column}>
                                    <Text style={styles.header}>{column.header}</Text>
                                    {column.items.map((item, itemIndex) => (
                                        <View>
                                            <View key={itemIndex} style={styles.item}>
                                                <Text style={styles.label}>{item.label}</Text>
                                                <Text style={styles.value}>
                                                        {typeof order.details?.[item.key] === 'number' ? order.details?.[item.key].toFixed(1) :order.details?.[item.key] || '--'}
                                                </Text>
                                            </View>
                                            <View style={styles.divider} />
                                        </View>
                                    ))}
                                </View>
                            ))}
                        </View>
                        <NoteComponent updateOrders={handleUpdate} updatedNote={handleNoteChange} notes={note} isLoading={isLoading}/>
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
};

const styles = StyleSheet.create({
    safeAreaStyle: {
        flex: 1,
        marginHorizontal: 20,
        ...Platform.select({
            ios: {
                width: Dimensions.get("screen").width,
            },
            android: {
                width: width,
            },
            web: {
                width: width - 40,
            },
        }),
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        marginBottom: 10, paddingTop: 20

    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    column: {
        flex: 1,
        padding: 10,
        borderWidth: 1,
        borderColor: 'grey',
    },
    header: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
        borderBottomWidth: 2,
        borderBottomColor: 'lightgrey',
        paddingBottom: 5,
        color: '#637381'
    },
    item: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        marginTop: 10
    },
    noteContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 'auto'
    },
    label: {
        fontSize: 14,
        fontWeight: '500',
    },
    value: {
        fontSize: 14,
    },
    notesInput: {
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 10,
        marginVertical: 20,
        borderRadius: 5,
        height: 60,
    },
    button: {
        width: '60%',
        backgroundColor: '#3758f9',
        padding: 15,
        borderRadius: 5,
        alignItems: 'center',
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
    },
    divider: {
        marginVertical: 15,
        borderBottomColor: 'lightgrey',
        borderWidth: .25
    },
    btnContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default OrderDetail;
