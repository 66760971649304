const mainFontColor = "text-gray-900";
const funFontColor = "text-blue-500";

export const textInputStyle = "my-1 p-2 text-2xl border border-gray-700 rounded-md text-gray-900";
export const textDropDownStyle = "p-2 text-lg rounded-md text-gray-900 capitalize";
export const textInputLabelStyle = "block text-2xl font-semibold leading-6 " + mainFontColor;

export const pageTitleStyle = "text-center text-3xl font-bold leading-9 tracking-tight " + mainFontColor;

export const textStyleXL = "block text-xl leading-6 " + mainFontColor;
export const textStyle = "block text-2xl leading-6 " + mainFontColor;
export const textScreenDescriptionlStyle = "text-xl my-25" + mainFontColor;
export const textDescriptionStyle = "block text-md leading-6 " + mainFontColor;
export const textDescriptionBoldWarningStyle = "block text-md leading-6" + mainFontColor;
export const textFunStyle = "block text-2xl font-bold leading-6 " + funFontColor;

export const submitButtonLabelStyle = "block text-2xl font-semibold hover:text-white leading-6";
export const submitButtonPressableStyle = "mt-2 mx-auto px-10 py-2 flex flex-row items-center justify-center shadow rounded-md bg-blue-300 hover:bg-blue-500 active:bg-blue-500 rounded-md";

export const navMenuButtonLabelStyle = "block font-semibold text-3xl hover:text-white";
export const navMenuButtonPressableStyle = "m-2 my-4 hover:bg-off-white active:bg-off-white";

// export const viewClubsButtonLabelStyle = "block text-2xl text-center font-medium hover:text-white leading-6";
// export const viewClubsButtonPressableStyle = "bg-white hover:bg-gray-300 active:bg-gray-700 rounded-sm py-2";
// export const viewClubsButtonViewStyle = "mt-1 shadow rounded-sm";

export const viewClubsButtonLabelStyle = "block font-semibold text-2xl text-center";
export const viewClubsButtonPressableStyle = "p-2 my-2 rounded-md bg-blue-300 active:bg-blue-700";

export const clubCardButtonLabelStyle = "block font-semibold text-2xl text-center";
export const clubCardButtonPressableStyle = "m-1 p-2 rounded-md active:bg-gray-700 border-2 rounded-md";
export const clubCardHoselStyle = "block font-medium text-xl text-center";
