import React, { useEffect, useState, TextInput } from 'react';
import {
    Dimensions,
    FlatList,
    KeyboardAvoidingView,
    SafeAreaView,
    StyleSheet,
    Text,
    View,
    Platform,
    ActivityIndicator
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { exportTransactionsRequest, fetchTransactionsRequest } from "../../../store/ducks/app/actions";
import { getItemFromStore } from "../../../utils/storage/storage";
import HeaderButton from "../HeaderButton";
import { APIStates } from '../../../utils/Strings';


const width = Dimensions.get("window").width;
const height = Dimensions.get("window").height
const titles = ["Customer Name", "Transaction ID", "Customer SwingID", "Amount", "payment Method"];

const Transactions = () => {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState({})
    const transactionsData = useSelector(state => state.app.fetchTransactions);
    const exportData = useSelector(state => state.app.exportTransactions);
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (exportData.status === "success") {
            alert("exported successfully")
        }
    }, [exportData.status])

    const fetchData = async () => {
        const userId = await getItemFromStore('userId')
        const orgId = await getItemFromStore('orgId')
        let obj = {}
        if (userId) {
            obj.userId = userId
        }
        if (orgId) {
            obj.orgId = orgId
        }
        dispatch(fetchTransactionsRequest(obj))
        setUserData(obj)
    }

    const Item = ({ customerName, transactionId, amount, itemsSold, paymentMethod, CustomerSwingID }) => (
        <View style={style.row}>
            <Text style={style.cell}>{customerName}</Text>
            <Text style={style.cell}>{transactionId}</Text>
            <Text style={style.cell}>{CustomerSwingID}</Text>
            <Text style={style.cellPrice}>${amount}</Text>
            <Text style={style.cell}>{paymentMethod}</Text>
        </View>
    );

    const renderItem = ({ item }) => (
        <Item
            customerName={item.userName}
            transactionId={item.transactionId}
            CustomerSwingID={item.userEmail}
            amount={item.servicePrice}
            paymentMethod={item.paymentMethod}
        />
    );
    const handleExport = () => {
        dispatch(exportTransactionsRequest(userData))
    }

    return (
        <View style={{ flex: 1, marginBottom: 0, backgroundColor: 'white' }}>
            <SafeAreaView style={style.safeAreaStyle}>
                <KeyboardAvoidingView behavior="padding">
                    {Platform.OS === 'web' ?
                        <>
                        {transactionsData.status === APIStates.LOADING ?
                        (   
                            <View style={{marginTop:height*.3}}>
                            <ActivityIndicator />
                            </View>
                        ) : (
                        <>
                            {transactionsData?.data ?
                                <>
                                    <HeaderButton title={"Transactions"} btn1={""} btn2={"Export Transactions"} description={"Manage and View details of your Transactions"} onExport={handleExport} />
                                    <View style={{ flex: 1 }}>
                                        <View style={style.pageBodyStyle}>
                                            {/* <SearchBar/> */}
                                            <View style={style.transactionsContainer}>
                                                <FlatList
                                                    data={transactionsData?.data || []}
                                                    renderItem={renderItem}
                                                    keyExtractor={item => item.id}
                                                    ListHeaderComponent={() => (
                                                        <View style={style.header}>
                                                            {titles.map((title, index) => (
                                                                <Text key={index} style={style.headerCell}>
                                                                    {title}
                                                                </Text>
                                                            ))}
                                                        </View>
                                                    )} />

                                            </View>

                                        </View>
                                    </View>
                                </>
                                :
                                <>
                                    <View style={style.webContainer}>
                                        <Text style={{ fontSize: 15, width: '100%', textAlign: 'center' }}>No transactions found.</Text>
                                    </View>

                                </>
                            }
                            </>
                        )}
                        </> :
                        <View style={style.container}>
                            <Text style={{ fontSize: 15, width: '80%', textAlign: 'center' }}>Page currently under development, check back soon</Text>
                        </View>
                    }

                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
};

const style = StyleSheet.create({
    transactionsContainer: {
        borderRadius: 10,
        // iOS shadow properties
        shadowColor: "#171717",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        // Android shadow property
        elevation: 5,
        paddingBottom: height * 0.08,
        marginBottom: height * 0.08,
        justifyContent: 'center',
        marginTop: height * 0.02,
        height: Dimensions.get('window').height - 300,
    },
    searchContainer: {
        flexDirection: 'row',
        borderRadius: 10,
        backgroundColor: "#f8f9fd",
        shadowOffset: 1,
        shadowColor: "#171717",
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        paddingBottom: height * .02,
        paddingTop: height * .01,
        paddingLeft: 10,
        paddingRight: 10,
        justifyContent: 'space-between',
        marginTop: 10,
        alignItems: 'center'

    },
    loginBtn: {
        backgroundColor: "#3758f9",
        borderRadius: 5,
        padding: 10,
        justifyContent: 'center',
        alignItems: "center",
        marginRight: 10
    },
    pageHeaderStyle: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: Platform.OS === "ios" ? 20 : null,
        paddingRight: Platform.OS === "ios" ? 20 : null,
    },
    pageBodyStyle: {
        flex: 1,
        paddingLeft: Platform.OS === "ios" ? 20 : null,
        paddingRight: Platform.OS === "ios" ? 20 : null,

    },

    addNewUserButtonStyle: {
        color: "rgba(87,164,251,1)",
        fontSize: 20,
        marginTop: 7.5,
        cursor: 'pointer'
    },
    userListButtonsStyle: {
        color: "rgba(87,164,251,1)",
        fontSize: 17.5,
        marginTop: 7.5,
    },
    userDeleteButtonsStyle: {
        color: "#b22222",
        fontSize: 15.5,
        marginTop: 7.5,
    },
    flatlistContainer: { marginBottom: 130 },
    userName: {
        fontSize: 20,
        fontWeight: "bold",
    },
    userEmail: {
        fontSize: 18,
        fontWeight: "400",
    },
    item: {
        padding: 20,
        borderWidth: 2,
        borderRadius: 10,
        borderBottomColor: "black",
        marginBottom: 10,
    },
    mainBoxStyle: {
        padding: 20,
    },
    safeAreaStyle: {
        flex: 1,
        marginHorizontal: 20,
        ...Platform.select({
            ios: {
                width: Dimensions.get("screen").width,
            },
            android: {
                width: width,
            },
            web: {
                width: width - 40,
            },
        }),
    },
    btnStyling: {
        flexDirection: "row",
        justifyContent: "space-between",
        ...Platform.select({
            ios: {
                width: Dimensions.get("screen").width - 80,
            },
            android: {
                width: Dimensions.get("screen").width - 80,
            },
            web: {
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                width: Dimensions.get("window").width - 100,
                alignContent: "flex-end",
            },
        }),
    },
    warningBoxStyle: {
        flexDirection: "row",
        alignItems: "center",
    },
    warningTextStyle: {
        fontFamily: "semi_bold",
        fontSize: 16,
        color: "red",
        marginLeft: 10,
    },
    descriptionTextStyle: {
        fontSize: 14,
        fontFamily: "regular",
        color: "#000004",
        textAlign: "justify",
        lineHeight: 22,
        marginBottom: 16,
        marginTop: 11,
    },
    enterPasswordDescriptionTextStyle: {
        fontSize: 14,
        fontFamily: "regular",
        color: "black",
        textAlign: "justify",
        lineHeight: 20,
        marginTop: 10,
    },
    buttonBoxStyle: {
        flex: 1,
        justifyContent: "flex-end",
        padding: 20,
    },
    headerIos: {
        backgroundColor: "red",
    },
    headerAndroid: {
        backgroundColor: "#CCA7B1",
    },
    webContainer: {
        flex: 1,
        padding: 16,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: height * .4
    },
    header: {
        height: height * .1,
        flexDirection: 'row',
        paddingTop: 8,
        paddingBottom: 8,
        marginBottom: 8,
        backgroundColor: '#f9fafb',
        justifyContent: 'center',
        alignItems: 'center'

    },
    headerCell: {
        flex: 1,
        fontWeight: 'bold',
        textAlign: 'center',
        color: "text-gray-900",
        textTransform: 'uppercase',
        color: 'grey'
    },
    row: {
        flexDirection: 'row',
        paddingVertical: 8,
        borderBottomWidth: 1,
        borderBottomColor: 'lightgrey',
        width: '98%',
        paddingVertical: 30
    },
    cell: {
        flex: 1,
        textAlign: 'center',
        color: 'grey',
        fontSize: 15
    },
    cellPrice: {
        flex: 1,
        textAlign: 'center',
        color: 'grey',
        fontSize: 15,
        fontWeight: 'bold'
    },
    editButton: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    dateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#d1d1d1',
        borderRadius: 8,
        padding: 10,
        backgroundColor: '#f8f8f8',
    },
    calendarIcon: {
        width: 10,
        height: 10,
        marginRight: 5,
    },
    dateRange: {
        fontSize: 16,
        color: 'grey',
        marginLeft: 10,
    },
    cuscontainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 5,
        padding: 10,

    },
    icon: {
        width: 20,
        height: 20,
        marginRight: 10,
    },
    container: {
        width: '100%',
        height: '100%',
        margin: 0,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',

    },


});

export default Transactions;
