import { styled } from "nativewind";
import React, { useEffect, useState, } from 'react';
import {
    Dimensions,
    FlatList,
    StyleSheet,
    Text,
    View,
    Platform,
    TouchableOpacity,
    ActivityIndicator
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { getItemFromStore } from "../../../utils/storage/storage";
import dayjs from "dayjs";
import OrderStatus from "./OrderStatus";
import { updateOrdersRequest, fetchOrdersRequest, updateOrdersIdle } from '../../../store/ducks/app/actions';
import { APIStates } from '../../../utils/Strings';

const width = Dimensions.get("window").width;
const height = Dimensions.get("window").height

const OrderList = ({ titles }) => {
    const [searchKeyword, onChangeSearchKeyword] = useState("");
    const [newSearchKeyword, onChangeNewSearchKeyword] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [status, setSelectedStatus] = useState("");
    const [isLoading, setLoading] = useState(false);
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const OrdersData = useSelector(state => state.app.fetchOrders);
    const orderUpdate = useSelector(state => state.app.updateOrders);

    useEffect(() => {
        if (orderUpdate.status == APIStates.SUCCESS) {
            fetchOrders()
            dispatch(updateOrdersIdle())
            setLoading(false)
        }
    }, [orderUpdate.status])

    useEffect(() => {
        fetchData()
    }, [])

    const fetchOrders = async () => {   
        const orgId = await getItemFromStore('orgId')
            dispatch(fetchOrdersRequest({
                orgId: orgId,
              }));
    }

    useEffect(() => {
        if(status){
            setSelectedStatus(status);
        }
    }, [status])

    const handleStatus = (id, status) => {
        dispatch(updateOrdersRequest({
            orderId: id,
            updateObj: {
                status
            }
        }))
    };


    const fetchData = async () => {   
        const orgId = await getItemFromStore('orgId')
            dispatch(fetchOrdersRequest({
                orgId: orgId,
              }));
    }

    useEffect(() => {
        const filterData = () => {
            let filtered = OrdersData;
            if (searchKeyword) {
                filtered = filtered.filter(item =>
                    item.customerName.toLowerCase().includes(searchKeyword.toLowerCase())
                );
            }
            if (newSearchKeyword) {
                filtered = filtered.filter(item =>
                    item.transactionId.toLowerCase().includes(newSearchKeyword.toLowerCase())
                );
            }
            setFilteredData(filtered);
        };

        filterData();
    }, [searchKeyword, newSearchKeyword]);

    const Item = (props) => {
        const { customerName, email, id, status, deliveryDate, details } = props;
        return (
            <View style={style.row}>
                <Text style={style.cell}>{customerName}</Text>
                <Text style={style.cell}>{email}</Text>
                <Text style={style.cell}>{id}</Text>
                <OrderStatus updatedStatus={(stat) => handleStatus(id, stat)} status={status} />
                <Text style={style.cell}> {dayjs(deliveryDate).format("DD MMM YYYY")}</Text>
                           <TouchableOpacity style={[style.button, { width:width*.15 }]} onPress={() => navigation.navigate("OrderDetail", { order: props })}>
                              <Text style={style.buttonText}>See Order Details</Text>
                           </TouchableOpacity>
            </View>
        )
    };

    const renderItem = ({ item }) => (
        <Item
            customerName={item.golferName}
            email={item.golferEmail}
            id={item.id}
            orderNumber={item.orderNumber}
            status={item.status}
            deliveryDate={item.deliveryDate}
            details = {item.details}
            
        />
    );


    return (
        <View style={{ flex: 1 }}>
            <View style={style.pageBodyStyle}>
                    <View style={style.transactionsContainer}>
                 {   OrdersData.status == APIStates.LOADING ? (
                <ActivityIndicator />
              ) : (
                <FlatList
                data={OrdersData?.data || []}
                renderItem={renderItem}
                keyExtractor={item => item.id}
                ListHeaderComponent={() => (
                    <View style={style.header}>
                        {titles.map((title, index) => (
                            <Text key={index} style={style.headerCell}>
                                {title}
                            </Text>
                        ))}
                    </View>
                )}
            />
              )}
                    </View>
            </View>
        </View>

    );
};

const style = StyleSheet.create({
    transactionsContainer: {
        borderRadius: 10,
        // iOS shadow properties
        shadowColor: "#171717",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        // Android shadow property
        elevation: 5,
        paddingBottom: height * 0.08,
        marginBottom: height * 0.08,
        justifyContent: 'center',
        marginTop: height * 0.02,
        height: Dimensions.get('window').height - 250,
    },
    searchContainer: {
        flexDirection: 'row',
        borderRadius: 10,
        backgroundColor: "#f8f9fd",
        shadowOffset: 1,
        shadowColor: "#171717",
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        paddingBottom: height * .02,
        paddingTop: height * .01,
        paddingLeft: 10,
        paddingRight: 10,
        justifyContent: 'space-between',
        marginTop: 10,
        alignItems: 'center'

    },
    loginBtn: {
        backgroundColor: "#3758f9",
        borderRadius: 5,
        padding: 10,
        justifyContent: 'center',
        alignItems: "center",
        marginRight: 10,
        paddingLeft: 20,
        paddingRight: 20
    },
    pageHeaderStyle: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: Platform.OS === "ios" ? 20 : null,
        paddingRight: Platform.OS === "ios" ? 20 : null,
    },
    pageBodyStyle: {
        flex: 1,
        paddingLeft: Platform.OS === "ios" ? 20 : null,
        paddingRight: Platform.OS === "ios" ? 20 : null,

    },

    addNewUserButtonStyle: {
        color: "rgba(87,164,251,1)",
        fontSize: 20,
        marginTop: 7.5,
        cursor: 'pointer'
    },
    userListButtonsStyle: {
        color: "rgba(87,164,251,1)",
        fontSize: 17.5,
        marginTop: 7.5,
    },
    userDeleteButtonsStyle: {
        color: "#b22222",
        fontSize: 15.5,
        marginTop: 7.5,
    },
    flatlistContainer: { marginBottom: 130 },
    userName: {
        fontSize: 20,
        fontWeight: "bold",
    },
    userEmail: {
        fontSize: 18,
        fontWeight: "400",
    },
    item: {
        padding: 20,
        borderWidth: 2,
        borderRadius: 10,
        borderBottomColor: "black",
        marginBottom: 10,
    },
    mainBoxStyle: {
        padding: 20,
    },
    safeAreaStyle: {
        flex: 1,
        marginHorizontal: 20,
        ...Platform.select({
            ios: {
                width: Dimensions.get("screen").width,
            },
            android: {
                width: width,
            },
            web: {
                width: width - 40,
            },
        }),
    },
    btnStyling: {
        flexDirection: "row",
        justifyContent: "space-between",
        ...Platform.select({
            ios: {
                width: Dimensions.get("screen").width - 80,
            },
            android: {
                width: Dimensions.get("screen").width - 80,
            },
            web: {
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                width: Dimensions.get("window").width - 100,
                alignContent: "flex-end",
            },
        }),
    },
    warningBoxStyle: {
        flexDirection: "row",
        alignItems: "center",
    },
    warningTextStyle: {
        fontFamily: "semi_bold",
        fontSize: 16,
        color: "red",
        marginLeft: 10,
    },
    descriptionTextStyle: {
        fontSize: 14,
        fontFamily: "regular",
        color: "#000004",
        textAlign: "justify",
        lineHeight: 22,
        marginBottom: 16,
        marginTop: 11,
    },
    enterPasswordDescriptionTextStyle: {
        fontSize: 14,
        fontFamily: "regular",
        color: "black",
        textAlign: "justify",
        lineHeight: 20,
        marginTop: 10,
    },
    buttonBoxStyle: {
        flex: 1,
        justifyContent: "flex-end",
        padding: 20,
    },
    headerIos: {
        backgroundColor: "red",
    },
    headerAndroid: {
        backgroundColor: "#CCA7B1",
    },
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#fff',
    },
    header: {
        height: height * .1,
        flexDirection: 'row',
        paddingTop: 8,
        paddingBottom: 8,
        marginBottom: 8,
        backgroundColor: '#f9fafb',
        justifyContent: 'center',
        alignItems: 'center'

    },
    headerCell: {
        flex: 1,
        fontWeight: 'bold',
        textAlign: 'center',
        color: "text-gray-900",
        textTransform: 'uppercase',
        color: 'grey'
    },
    row: {
        flexDirection: 'row',
        paddingVertical: 8,
        borderBottomWidth: 1,
        borderBottomColor: 'lightgrey',
        width: '98%',
        paddingVertical: 30,
      
    },
    cell: {
        flex: 1,
        textAlign: 'center',
        color: 'grey',
        fontSize: 15,
        justifyContent: 'space-between',
        alignContent: 'center',
        marginLeft: 5,
    },
    cellPrice: {
        flex: 1,
        textAlign: 'center',
        color: 'grey',
        fontSize: 15,
        fontWeight: 'bold'
    },
    editButton: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    dateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#d1d1d1',
        borderRadius: 8,
        padding: 10,
        backgroundColor: '#f8f8f8',
    },
    calendarIcon: {
        width: 10,
        height: 10,
        marginRight: 5,
    },
    dateRange: {
        fontSize: 16,
        color: 'grey',
        marginLeft: 10,
    },
    cuscontainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 5,
        padding: 10,

    },
    icon: {
        width: 20,
        height: 20,
        marginRight: 10,
    },
    itemContainer: {
        alignItems: 'center',
        marginVertical: 8,
    },
    statusContainer: {
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 20,
    },
    text: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    pending: {
        backgroundColor: '#FFFBEB',
        color: '#D97706',
    },
    cancelled: {
        backgroundColor: '#FEEBEB',
        color: '#E10E0E',
    },
    shipped: {
        backgroundColor: '#DAF8E6',
        color: '#1A8245',
    },
    default: {
        backgroundColor: '#f0f0f0',
        color: '#000',
    },
    separator: {
        height: 1,
        backgroundColor: '#e0e0e0',
        width: '100%',
    },
    button: {
        borderWidth: 1,
        borderColor: '#007BFF', // Blue color for border
        borderRadius: 6,
        paddingVertical: 10,
        paddingHorizontal: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    view: {
        width: '17%'
    },
    buttonText: {
        color: '#007BFF', // Blue color for text
        fontSize: 16,
        fontWeight: '500',
        textAlign:'center'
    },


});

export default OrderList;
