import React, { useState, useRef } from 'react';
import { View, Text, TextInput, TouchableOpacity, FlatList, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { styled } from 'nativewind';
import {textInputLabelStyle} from './Styles';


const StyledText = styled(Text);
const ComboBox = ({ label, items, placeholder,selection, setSelection, index }) => {
    const [inputValue, setInputValue] = useState(selection || '');
    const [filteredItems, setFilteredItems] = useState(items);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const inputRef = useRef(null); // Create a ref for the TextInput

    const handleInputChange = (text) => {
        setInputValue(text);
        setFilteredItems(
            items.filter((item) =>
                item.toLowerCase().includes(text.toLowerCase())
            )
        );
        setDropdownVisible(true);
    };

    const handleItemSelect = (item) => {
        setInputValue(item);
                  setSelection(index, item)
        setDropdownVisible(false);
    };

    return (
        <TouchableOpacity 
            activeOpacity={1} 
            style={[styles.touchableContainer, { zIndex: 1000 }]} 
            onPress={() => inputRef.current.focus()} // Focus on the TextInput when the screen is tapped
        >
            <View style={styles.container}>
                <StyledText className={textInputLabelStyle}>
                     {label}
                 </StyledText>
                <View style={styles.inputContainer}>
                    <TextInput
                        ref={inputRef} // Attach the ref to the TextInput
                        style={styles.input} 
                        value={inputValue}
                        onChangeText={handleInputChange}
                        onFocus={() => setDropdownVisible(true)}
                        onBlur={() => setTimeout(() => setDropdownVisible(false), 100)}
                        placeholder={placeholder ? placeholder : "Select or type..."}
                    />
                    <View style={styles.iconContainer}>
                        <Ionicons
                            name="chevron-up"
                            size={20}
                            color="#888"
                            style={styles.icon}
                        />
                        <Ionicons
                            name="chevron-down"
                            size={20}
                            color="#888"
                            style={styles.icon}
                        />
                    </View>
                </View>
                {dropdownVisible && (
                    <View style={styles.dropdown}>
                        <FlatList
                            data={filteredItems}
                            keyExtractor={(item) => item}
                            renderItem={({ item }) => (
                                <TouchableOpacity
                                    style={styles.dropdownItem}
                                    onPress={() => handleItemSelect(item)}
                                >
                                    <Text style={styles.itemText}>{item}</Text>
                                    {item === inputValue && (
                                        <Ionicons name="checkmark" size={20} color="#3B82F6" />
                                    )}
                                </TouchableOpacity>
                            )}
                        />
                    </View>
                )}
            </View>
        </TouchableOpacity>
    );
};
const styles = StyleSheet.create({
    touchableContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    container: {
        marginVertical: 2,
        position: 'relative',
        zIndex: 10000, // Increase zIndex here
    },
    label: {
        marginBottom: 15,
        fontSize: 16,
        color: '#333',
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 2,
        borderColor: 'rgb(156 163 175)',
        borderRadius: 5,
        paddingHorizontal: 8,
        justifyContent: 'space-between',
        marginTop: 7,
    },
    input: {
        flex: 1,
        height: 40,
        fontSize: 16,
        color: 'rgb(55 65 81)',
        borderWidth: 0,
        outlineWidth: 0,
    },
    iconContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        marginVertical: 1,
    },
    dropdown: {
        position: 'absolute',
        top: 80,
        left: 0,
        right: 0,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        backgroundColor: '#e8e7e8',
    },
    dropdownItem: {
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    itemText: {
        fontSize: 16,
        color: '#333',
    },
});

export default ComboBox;
