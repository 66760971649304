import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { DrawerContentScrollView } from '@react-navigation/drawer';
import { MaterialIcons, Octicons, FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useNavigation as useCustomNavigation } from './NavigationProvider'; // Import the custom hook
import { getItemFromStore } from '../utils/storage/storage';
import { GolferSideMenu, OrgnasationSideMenu, ViewerSideMenu } from '../utils/Util';
import { styled } from 'nativewind';

const StyledView = styled(View);

const CustomDrawer = ({ signMeOut, ...props }) => {
    const [locations, setLocations] = useState([]);
    const [type, setType] = useState(null); 
    const navigation = useNavigation();
    const { setSelectedTab } = useCustomNavigation(); 

    useEffect(() => {
        loadMenuBasedOnRole();
    }, []);

    useEffect(() => {
        if (type) {
            refreshScreen(); 
        }
    }, [type]);

    const loadMenuBasedOnRole = async () => {
        const role = await getItemFromStore('role');
        if (['provider', 'owner', 'admin', 'contributor'].includes(role)) {
            setLocations(OrgnasationSideMenu);
        } else if (role === 'golfer') {
            setLocations(GolferSideMenu);
        } else if (role === 'viewer') {
            setLocations(ViewerSideMenu);
        }
    };

    const refreshScreen = () => {
        navigation.navigate(navigation.getCurrentRoute().name, { type });
    };

    const handleNavigation = (screen) => {
        setSelectedTab(screen); // Update selected tab in context

        if (screen === "Golfers") {
            setType("golfer");
            navigation.navigate(screen, { type: "golfer" });
        } else if (screen === "UserList") {
            setType("usersList");
            navigation.navigate(screen, { type: "usersList" });
        } else {
            setType(null);
            navigation.navigate(screen);
        }
        props.navigation.closeDrawer();
    };

    const onLogOut = () => {
        signMeOut();
    };

    return (
        <DrawerContentScrollView {...props}>
            {/* Close Button */}
            <TouchableOpacity onPress={() => props.navigation.closeDrawer()} style={{ padding: 16 }}>
                <Text style={{ fontSize: 30, color: '#000' }}>✖️</Text>
            </TouchableOpacity>

            {/* Custom Drawer Items */}
            {locations.map((buttonData,index) => (
                <>
                    <TouchableOpacity
                        key={index}
                        onPress={() => handleNavigation(buttonData.screen)}
                        style={{ flexDirection: 'row', alignItems: 'center', padding: 16 }}
                    >
                        <Text style={{ fontSize: 25, fontWeight: '600' }}>{buttonData.label}</Text>
                    </TouchableOpacity>
                    {/* Divider */}
                    <StyledView className="flex flex-row border border-gray-700" />
                </>
            ))}

            {/* Custom Options */}
            <View style={{ paddingHorizontal: 16, marginVertical: 10 }}>
                <TouchableOpacity style={styles.container} onPress={() => handleNavigation('manageAccount')}>
                    <MaterialIcons name="settings" size={20} color="#000" />
                    <Text style={styles.Text}>Settings</Text>
                </TouchableOpacity>

                <TouchableOpacity style={styles.container} onPress={() => handleNavigation('Contact')}>
                    <Octicons name="mail" size={20} color="#000" />
                    <Text style={styles.Text}>Help & Support</Text>
                </TouchableOpacity>

                <TouchableOpacity style={styles.container} onPress={onLogOut}>
                    <FontAwesome name="sign-out" size={20} color="red" style={styles.icon} />
                    <Text style={[styles.Text, { color: 'red' }]}>Logout</Text>
                </TouchableOpacity>
            </View>
        </DrawerContentScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16
    },
    Text: {
        marginLeft: 10,
        fontSize: 15,
        fontWeight: "600"
    },
    icon: {
        transform: [{ rotate: '180deg' }],
    },
});

export default CustomDrawer;
