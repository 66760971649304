import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { styled } from "nativewind";
import React from 'react';
import {
    ActivityIndicator,
    KeyboardAvoidingView,
    Linking,
    SafeAreaView,
    StyleSheet,
    Text,
    View
} from 'react-native';
import { useDispatch, useSelector } from "react-redux";
import { APIStates } from '../../utils/Strings';
import MyButton from '../MyButton';
import { pageTitleStyle, textStyle } from '../Styles';

const StyledText = styled(Text);
const StyledView = styled(View);

dayjs.extend(utc);

const CalendarComponent = ({ uid}) => {
const dispatch = useDispatch()
const loginData = useSelector(state => state.app.login);
const register = useSelector(state => state.app.register);

    return (
        <View style={{ flex: 1, marginBottom: 0, width: '100%',backgroundColor:'white',paddingLeft:"2%",paddingRight:'2%' }}>
            <SafeAreaView style={style.safeAreaStyle}>
                <KeyboardAvoidingView behavior="padding">
                    <StyledView className="mx-auto my-2">
                        <StyledText className={pageTitleStyle}>
                            Sync Outlook Calender
                        </StyledText>
                        <View style={style.mainBoxStyle} />
                        <StyledText className={textStyle}>
                            Sync your Outlook calendar with our system. This will allow you to view, add, and delete events from your calendar.
                        </StyledText>
                        <View style={style.mainBoxStyle} />
                        {
                            false == APIStates.LOADING ?
                                <View className='m-2 flex flex-row items-center justify-center'>
                                    <ActivityIndicator size="large" color="rgba(170,218,253,1)" />
                                </View>
                                : <>

                                <MyButton
                                    label={"Sync Calender"}
                                    theme={"submit"}
                                    onPress={()=>{
                                        const clientId = "664debcc-0b64-4fe5-96f3-df63e16cec41"
                                        // const redirectURI = window.location.href
                                        const redirectURI = "https://app.golf-eq.com"
                                        // const redirectURI = "http://localhost:19006"

                                        const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectURI}&response_mode=query&scope=offline_access User.Read Calendars.ReadWrite&state=12345&sso_reload=true`

          Linking.openURL(url)
                                    }}
                                />
                                
                                </>
                        }

                    </StyledView>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    )
}

const style = StyleSheet.create({
    pageHeaderStyle: { flexDirection: 'row', justifyContent: 'space-between' },
    addNewUserButtonStyle: {
        color: 'rgba(87,164,251,1)',
        fontSize: 20,
        marginTop: 7.5,
         
    },
    userListButtonsStyle: {
        color: 'rgba(87,164,251,1)',
        fontSize: 17.5,
        marginTop: 7.5
    },
    flatlistContainer: { marginBottom: 130 },
    userName: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    userEmail: {
        fontSize: 18,
        fontWeight: '400'
    },
    item: {
        padding: 20,
        borderWidth: 2,
        borderRadius: 10,
        borderBottomColor: 'black',
        marginBottom: 10
    },
    mainBoxStyle: {
        padding: 20,
    },
    safeAreaStyle: {
        flex: 1,
        marginHorizontal: 20,
    },
    warningBoxStyle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    warningTextStyle: {
        fontFamily: 'semi_bold',
        fontSize: 16,
        color: 'red',
        marginLeft: 10,
    },
    descriptionTextStyle: {
        fontSize: 14,
        fontFamily: 'regular',
        color: '#000004',
        textAlign: 'justify',
        lineHeight: 22,
        marginBottom: 16,
        marginTop: 11,
    },
    enterPasswordDescriptionTextStyle: {
        fontSize: 14,
        fontFamily: 'regular',
        color: 'black',
        textAlign: 'justify',
        lineHeight: 20,
        marginTop: 10,
    },
    buttonBoxStyle: {
        flex: 1,
        justifyContent: 'flex-end',
        padding: 20,
    },
})

export default CalendarComponent;
