import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  FlatList,
  Image,
  TouchableOpacity,
  ScrollView,
  Linking,
  Modal,
  TouchableWithoutFeedback,
  ActivityIndicator,
  Platform
} from "react-native";
import { Box, Menu, Pressable } from "native-base";
import MyTextInput from "../MyTextInput";
import { textDropDownStyle, textInputLabelStyle, textScreenDescriptionlStyle } from "../Styles";
import RadioButton from "../RadioButton";
import MyButton from "../MyButton";
import CustomDatePicker from "./CustomCalender.web";
import PaymentForm from "./PaymentMethods/PaymentForm";
import LoginForm from "../auth/LoginForm";
import SignUpForm from "../auth/SignUpForm";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { fetchServicesIdle, fetchServicesRequest } from '../../store/ducks/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { APIStates } from '../../utils/Strings';
import BookedView from "./BookedView";
import { useResponsiveValues } from './useResponsiveValues';


const width = Dimensions.get("window").width;
const height = Dimensions.get("window").height;

const BookingWeb = ({ isLogin, signMeOut, firstName, lastName, orgDomain }) => {
  const [serviceType, onChangeServiceType] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [hoveredItem, setHoveredItem] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [customModalVisible, setCustomModalVisible] = useState(false);
  const [formType, setFormType] = useState("login");
  const [isCallingFromBooking, setIsCallingFromBooking] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [paymentResult, setPaymentResult] = useState(null);
  const [selectedDate, setSelectdDate] = useState(false);
  const [dateTimeStamp, setDateTimeStamp] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [hardReload, setHardReload] = useState(true);
  const dispatch = useDispatch()
  const Services = useSelector(state => state.app.Services);
  const { numColumns, Width, modelWidth, containerWidth, loginModelWidth, inputsWidth, fontSize, marginTop, isSmallScreen ,Top,Right} = useResponsiveValues();
  const [selectedTab, setSelectedTab] = useState('');

  
  useEffect(() => {
    {Platform.OS === "web" &&
      dispatch(fetchServicesRequest({
        // domain: "ttv-booking.golf-eq.com",
        domain: orgDomain || window.location.host,
        userId: isLogin || ""
      }))}
  }, []) 

  useEffect(()=>{
    setHardReload(!hardReload)
  },
  [firstName, lastName])
  
  const options = [
    { label: "Credit Card" },
    { label: "Cash on delivery" },
  ];

  useEffect(() => {
    if (Services.status == APIStates.SUCCESS) {
    } else if (Services.status == APIStates.FAILURE) {
      alert(Services.message)
      dispatch(fetchServicesIdle())
    }
  }, [Services.status])

  const toggleModal = (type, fromBooking = false, service = null) => {
    setFormType(type);
    setIsCallingFromBooking(fromBooking);
    setModalVisible(!modalVisible);
    // setSelectedService(service);
  };

  const customToggleModal = (service = null) => {
    setCustomModalVisible(service);
  };

  const renderForm = () => {
    if (formType === "login") {
      return (
        <View>
          <LoginForm isCallingFromBooking={isCallingFromBooking} />
        </View>
      );
    } else if (formType === "signup") {
      return (
        <View>
          <SignUpForm isCallingFromBooking={isCallingFromBooking} />
        </View>
      );
    }
  };

  const handleOutsideClick = () => {
    setModalVisible(false);
    setCustomModalVisible(false)
  };
  
  const handleSelectItem = (item, index) => {
    onChangeServiceType(item.name);
    setSelectedIndex(index);
    setSelectedService(item);
  };

  const convertDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    if (hours > 0 && mins > 0) {
      return `${hours} hr ${mins} min`;
    } else if (hours > 0) {
      return `${hours} hr`;
    } else {
      return `${mins} min`;
    }
  };

  const renderModalContent = () => {
    return (
      <>
        <Modal
          animationType="slide"
          transparent={true}
          visible={customModalVisible}
          onRequestClose={customToggleModal}
        >
          <TouchableWithoutFeedback onPress={handleOutsideClick}>
            <Box
              style={{
                flex: 1,
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,.05)",
              }}
            >
              <Box style={[styles.cusModalContainer, { width: modelWidth }]}>
                <Box style={{flex:1}}>
                <Box style={{ justifyContent: 'center', alignItems: 'center', marginTop: width * 0.03,marginBottom:10}}>
                  <Box style={styles.modelicon}>
                    <Text style={{ fontSize: 20, color: 'white', fontWeight: 'bold' }}>i</Text>
                  </Box>
                </Box>
                <Box style={{ flex: 1 / 6, justifyContent: 'center', alignItems: 'center', marginTop: width * .02, marginBottom: width * .01 }}>
                  <Text className={textScreenDescriptionlStyle}>{customModalVisible?.name || ''}</Text>
                </Box>

                <Box style={{ marginBottom: width * .01 }}>
                  <Box
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Text className={textScreenDescriptionlStyle}>Duration:  </Text>
                    <Text className={textScreenDescriptionlStyle}>
                      {convertDuration(customModalVisible?.duration || '')}

                    </Text>

                  </Box>
                  <Box
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Text className={textScreenDescriptionlStyle}>Price:  </Text>
                    <Text className={textScreenDescriptionlStyle}>${customModalVisible?.price? customModalVisible.price:0} </Text>


                  </Box>
                </Box>
                <Box style={{ width: '80%', justifyContent: 'center', alignSelf: 'center', paddingBottom: width * 0.06 }}>
                  <Text style={{ textAlign: "center" }} className={textDropDownStyle} >
                    {customModalVisible?.description || ''}
                  </Text>
                </Box>
                </Box>
                <Box style={{flex:.5}}>
                  <View style={styles.dottedLine} />

                  <MyButton
                    label={"OK"}
                    theme={"submit"}
                    onPress={() =>  customToggleModal() }
                  />
                </Box>
              </Box>
            </Box>
          </TouchableWithoutFeedback>
        </Modal>

      </>
    )
  }
  // Services Array
  const renderItem = (item, index) => (
    <TouchableOpacity
      onPress={() => handleSelectItem(item, index)}
    >
      <Box>
        <Box style={[styles.list,{width:Width}]}>
          <Box
            style={{
              flex: 5,
              justifyContent: "space-evenly",
              alignSelf: "center",
              width: Width,
              paddingLeft: width*.015,
            }}
          >
            <Box style={{ paddingBottom: width * 0.01 }}>
              <Text style={styles.title}>{item.name} </Text>
            </Box>
            <Box
              style={{
                flex: 1,
                width: "80%",
                flexDirection: 'row',
                paddingBottom: width * 0.01
              }}
            >
              <Text style={styles.title}>Duration:  </Text>
              <Text style={styles.title}>{convertDuration(item.duration)} </Text>
            </Box>
            <Box
              style={{
                flex: 1,
                width: "80%",
                flexDirection: 'row'
              }}
            >
              <Text style={styles.title}>Price:  </Text>
              <Text style={styles.title}>${item.price? item.price:0} </Text>
            </Box>
          </Box>
          <Box >
            <TouchableOpacity
              style={{ flex: 1, marginLeft: 15, paddingTop: width * .01, width: width * .04 }}
              onPress={() => {
                customToggleModal({
                  name: item.name,
                  duration: item.duration,
                  price: item.price,
                  description: item.description,
                })
              }}
            >
              <Box style={styles.logo}>
                <MaterialIcons name={"question-mark"} size={20} color={"white"} />
              </Box>
            </TouchableOpacity>
          </Box>
        </Box>
      </Box>
    </TouchableOpacity>
  );
  // ServicesTypes & Calender
  const Inputs = () => {
    return (
      <Box style={{ flex: 1 ,width:inputsWidth,justifyContent:'center',alignSelf:'center'}}>
        <MyTextInput
          label={"Service Type:"}
          placeholder={"Service Type"}
          value={serviceType}
          onChangeValue={selectedService?.name || ''}
        />
        <Box marginBottom={1}>
          <Text className={textInputLabelStyle}>Select Date:</Text>
        </Box>
        <CustomDatePicker dateCheck={(date) => {
          setSelectdDate(date)
        }} selectedStartTime={setTime} selectedDate={setDate} pickedDate={date} setTime={time} setDateTimeStamp={setDateTimeStamp} availableTime={Services.data.availability} selectedService={selectedService} setEndTime={setEndTime}/>
      </Box>
    );
  };
  useEffect(() => {
    if (paymentResult === false) {
      // This will refresh the page by reloading it
      window.location.reload();
    }
  }, [paymentResult]);

  const handlePaymentResult = (isSuccessful) => {
    if (isSuccessful) {
      setPaymentResult(true)
      console.log('Payment was successful');
    } else {
      setPaymentResult(false)
      console.log('Payment failed');
    }
  };

  // Payments Methods
  const PaymentMethods = () => {
    return (
      <View style={{ width: inputsWidth ,justifyContent:'center',alignSelf:'center'}}>
        <Text
          className={textInputLabelStyle}
          style={{
            flex: 1,
            justifyContent: "center",
            alignSelf: "center",
            marginTop: height * 0.05,
            marginBottom: height * 0.05,
          }}
        >
          Select Payment Methods
        </Text>
        {options?.map((option, index) => (
          <RadioButton
            key={index}
            label={option.label}
            selected={selectedOption && selectedOption.label === option.label}
            onPress={() => setSelectedOption(option)}
          />
        ))}
        {selectedOption && (
          <View>
            <PaymentForm  onPaymentResult={handlePaymentResult}  selectedService={selectedService} uid={isLogin} timeStamp={dateTimeStamp} payment={selectedOption} endTime={endTime} />
          </View>
        )}
      </View>
    );
  };

  const handleDropdownClick = () => {
    setShowLogout(!showLogout);
  };  

  useEffect(() => {
    if (!selectedTab && Services?.data?.menu?.[1]?.name) {
      setSelectedTab(Services.data.menu[1].name); 
    }
  }, [selectedTab, Services?.data?.menu]);
  
  return (
    <View style={{flex:1,backgroundColor:'white'}}>
    {Services.status == APIStates.LOADING ? (
      <View style={{marginTop:height*.3}}>
      <ActivityIndicator />
      </View>
    ) : (
    <Box style={styles.mainContainer}>
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <Box style={[styles.dataContainer, { width: containerWidth }]}>
          {/* Top dropdown Icon */}
          {isLogin ?
          
              <Box
              style={{
                marginTop:15,
                alignItems: "flex-end",
                marginRight: width * .02,
                
              }}
            >
              <TouchableOpacity onPress={handleDropdownClick}>
              <Box style={{
                flexDirection: 'row',
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: width * .02,
                marginTop: width * .02,
                borderColor: 'lightgrey',
                borderWidth: 1,
                borderRadius: 30,
               
              }}>
                  <Text className={textDropDownStyle}>{`${firstName != 'undefined' ? firstName : '   '} ${lastName != 'undefined' ? lastName : '   '}`}</Text>

                <MaterialIcons name="arrow-drop-down" size={40} color="rgb(56,66,82)"  />
               

                </Box></TouchableOpacity>
                
              {showLogout && (
                <TouchableOpacity style={[styles.logoutContainer, { marginLeft: Right,}]} onPress={() => signMeOut()}>
                  <Box >
                    <Text>Logout</Text>
                  </Box>
                </TouchableOpacity>
              )}
             
            </Box> : null
          }
       
          {/* Page Header */}
          <Box style={styles.logoContainer}>
            <Box style={styles.logoIcon}>
              <Image
                source={{
                  uri: Services.data.logo,
                }}
                style={styles.logoImage}
              />
            </Box>
            <Text style={[styles.headerTxt, { fontSize: fontSize, marginTop: marginTop }]}>
              {/* Your Day At OnCore Golf Awaits! */}
              {`Your Day At ${(Services.data.name) || ''} Golf Awaits!`}

            </Text>
          </Box>
          {/* Navigationbar */}
          <Box style={isSmallScreen ? styles.smallScreenBarStyle : [styles.barStyle,{  backgroundColor: Services.data?.navbarColor,}]}>
            {isSmallScreen ? (
              <Menu className='p-6 basis-3/4 bg-off-white'
                trigger={(triggerProps) => (
                  <Pressable {...triggerProps}>
                    <Text style={styles.burgerIcon}>☰</Text>
                  </Pressable>
                )}
              >
                {Services.data && Services.data.menu ? (
                  Services.data.menu.map((data) => (
                    <Menu.Item
                      key={data.name}
                      style={[
                        styles.navMenu,
                        hoveredItem === data.name && styles.hoveredNavMenu,
                        
                      ]}
                      onPress={() => (data.link ? Linking.openURL(data.link) : null)}
                      
                    >
                      {data.name}
                    </Menu.Item>
                  ))
                ) : null}
              </Menu>
            ) : (
              Services.data && Services.data.menu ? (
                Services.data.menu.map((data) => (
                  <TouchableOpacity
                    key={data.name}
                    style={[
                      styles.navMenu,
                      (hoveredItem === data.name || selectedTab === data.name) && styles.hoveredNavMenu, // Apply hover style to hovered or selected tab
                    ]}
                    onMouseEnter={() => setHoveredItem(data.name)}
                    onMouseLeave={() => setHoveredItem(null)}
                    onPress={() => (data.link ? Linking.openURL(data.link) : null)}
                  >
                    <Text style={styles.navTxt}>{data.name}</Text>
                  </TouchableOpacity>
                ))
              ) : null
            )}
          </Box>
          {/* Flatlist Rendering */}
          {paymentResult ? (
            <Box style={{flex:1,flexGrow:1,paddingBottom:height*.15,paddingTop:height*.05}}>
              <BookedView selectedService={selectedService} name={`${firstName || ''} ${lastName || ''}`} cancel={paymentResult} pickedTime={time} selectedDate={selectedDate} address={Services.data.address} uid={isLogin} booking ={setPaymentResult}/>
            </Box>
          ) : <Box>
            <Box
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View className="flex flex-wrap flex-row items-center justify-center">
              {
                Services.data?.services?.map(renderItem)
              }

              </View>
              {renderModalContent()}
            </Box>
            {selectedIndex !== null && (
              <Box
                style={{
                  flex: 1,
                  width: "85%",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              >
                <Inputs />
                {/*Login & SignUp with SwingID's  Buttons  */}
                {isLogin ? null : (
                  <Box style={styles.btnView}>
                    <Box style={styles.btncontainer}>
                      <TouchableOpacity
                        style={{
                          width: "100%",
                          backgroundColor: "#B3DFFF",
                          padding: 10,
                          borderRadius: 5,
                          justifyContent: 'center',
                          alignItems: "center"
                        }}
                        onPress={() => toggleModal("login", true)}
                      // onPress={() => setShowSwingInputs(!showSwingInputs)}
                      >
                        <Text style={styles.swingIDBtn}>
                          Login with SwingID
                        </Text>
                      </TouchableOpacity>
                      <Text
                        style={{
                          justifyContent: "center",
                          alignSelf: "center",
                          paddingTop: 10,
                          paddingBottom: 10
                        }}
                      >
                        Or
                      </Text>
                      <TouchableOpacity
                        style={styles.loginBtn}
                        onPress={() => toggleModal("signup", true)}
                      >
                        <Text
                          style={{
                            color: "white",
                            fontSize: 15,
                            fontWeight: "700",
                          }}
                        >
                          Register with SwingID
                        </Text>
                      </TouchableOpacity>
                      <Modal
                        animationType="slide"
                        transparent={true}
                        visible={modalVisible}
                        onRequestClose={toggleModal}
                      >
                          <TouchableWithoutFeedback onPress={handleOutsideClick}>
                            <Box
                              style={{
                                flex: 1,
                                justifyContent: "center",
                                backgroundColor: "rgba(0,0,0,.5)",
                              }}
                            >
                              <TouchableWithoutFeedback>
                                <Box style={[styles.modalContainer, { width: loginModelWidth }]}>
                                  <Box style={styles.modalView}>
                                    <Box style={{ flex: 1 / 2 }}>
                                      <TouchableOpacity
                                        style={styles.closeBtn}
                                        onPress={toggleModal}
                                      >
                                        <Text style={styles.closeText}>X</Text>
                                      </TouchableOpacity>
                                    </Box>
                                    {renderForm()}
                                  </Box>
                                </Box>
                              </TouchableWithoutFeedback>
                            </Box>
                          </TouchableWithoutFeedback>
                      </Modal>
                    </Box>
                  </Box>
                )}
                {selectedDate && isLogin ? <PaymentMethods /> : null}

              </Box>
            )}
          </Box>}



        </Box>
      </ScrollView>

      <Box style={styles.bottomBar}></Box>
    </Box>
     )}
     </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "rgb(238, 245, 255)",
    alignItems: "center",
    width: width,
  },
  dataContainer: {
    borderRadius: 10,
    backgroundColor: "rgb(255,255,255)",
    shadowOffset: 1,
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    paddingBottom: height * 0.08,
    marginBottom: height * 0.08,
  },
  scrollContainer: {
    flexGrow: 1,
    justifyContent: "center",
    marginTop:40,
  },
  logoContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: height * 0.03,
    marginBottom: height * 0.04,
  },
  coreTxt: {
    fontSize: 15,
    paddingBottom: 5,
  },
  logoIcon: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTxt: {
    fontWeight: "bold",
    textAlign:'center',
  },
  list: {
    backgroundColor: "rgb(243,244,246)",
    margin: width * 0.012,
    borderRadius: 10,
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    paddingTop: height * 0.02,
    paddingBottom: height * 0.02,
    justifyContent:'center',
    alignSelf:'center'
  },
  cusList: {
    backgroundColor: "rgb(243,244,246)",
    width: width * 0.18,
    margin: width * 0.012,
    borderRadius: 10,
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    paddingTop: height * 0.02,
    paddingBottom: height * 0.02,
  },
  bottomBar: {
    backgroundColor: "rgb(236,244,255)",
  },
  title: {
    fontSize: 17,
    color: "rgb(57,66,78)",
    fontWeight: "500",
  },
 
  logo: {
    height: 30,
    width: 30,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(56,66,82)",
  },
  modelicon: {
    height: 50,
    width: 50,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#007dba",
  },
  logoImage: {
    height: height * 0.22,
    width: width * 0.22,
    resizeMode: "contain",
  },
  barStyle: {
    height: height * 0.07,
    width: "85%",
    borderRadius: 10,
    justifyContent: "space-evenly",
    alignSelf: "center",
    flexDirection: "row",
  },
  smallScreenBarStyle:{
    position:'absolute',
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginLeft: width * .06,
    marginTop: width * .05,
  },
burgerIcon:{
  color:"black",
  fontSize:30
},
  navTxt: {
    color: "white",
    fontSize: 20,
  },
  navMenu: {
    alignContent: "center",
    justifyContent: "center",
    paddingLeft: 10,
    paddingRight: 10,
  },
  hoveredNavMenu: {
    backgroundColor: "#406580",
    borderRadius: 10,
  },
  datePickerStyle: {
    width: "100%",
  },
  btnView: { justifyContent: "center", alignSelf: "center", },
  btncontainer: {
    marginTop: height * 0.06,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
  loginBtn: {
    backgroundColor: "#3758F9",
    borderRadius: 5,
    width: "100%",
    padding: 10,
    justifyContent: 'center',
    alignItems: "center"
  },
  swingIDBtn: {
    color: "white",
    fontSize: 15,
    fontWeight: "700",
  },
  modalContainer: {
    flex: 1 / 1.5,
    alignSelf: "center",
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  cusModalContainer: {
    flex: 1 / 2,
    justifyContent:"space-between",
    alignSelf: "center",
    backgroundColor: "white",
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
   
  },

  logoutContainer: {
    alignSelf: "center",
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // position: 'absolute',
  

   
  },
  closeBtn: {
    justifyContent: "flex-end",
    alignSelf: "flex-end",
  },
  closeText: {
    flex: 1,
    width: "100%",
    justifyContent: "flex-end",
    fontWeight: "bold",
    fontSize: 25,
    // position:'absolute'
  },

  dottedLine: {
    // width: '80%',
    borderWidth: 1,
    borderColor: '#000',
    borderStyle: 'dotted',
  },
});

export default BookingWeb;
