import { styled } from "nativewind";
import { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, View,TouchableWithoutFeedback } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import MyButton from '../MyButton';

import { fetchUserProfileRequest } from "../../store/ducks/app/actions";
import { APIStates } from "../../utils/Strings";
import {
    pageTitleStyle,
    textScreenDescriptionlStyle
} from '../Styles';
import UpdatePassword from "../auth/UpdatePassword";
import BasicInfo from "./BasicInfo";
import AnalyticsLogger from "../managers/AnalyticsLogger";
import { ScreenNames } from "../../utils/Util";
import { useNavigation } from '@react-navigation/native';
import { useCurrentUser } from "../CurrentUserProvider";


const StyledView = styled(View);
const StyledText = styled(Text);
const StyledScrollView = styled(ScrollView);

export default function AccountLanding({}) {
    const { currentUser,role,uid } = useCurrentUser();
    const navigation = useNavigation();
    const dispatch = useDispatch()
    const [isProfilePageVisible, setIsProfilePageVisible] = useState(true);
    const [isChangePassword, setIsChangePassword] = useState(null);
    const getProfile = useSelector(state => state.app.getProfile);

    useEffect(() => {
        AnalyticsLogger.getInstance().logScreenView(ScreenNames.ManageAccount)

        const _userId = currentUser?.id || uid
        dispatch(fetchUserProfileRequest({ userId: _userId }))
    }, [])

    useEffect(() => {
        if (getProfile.status == APIStates.SUCCESS) {
            console.log(getProfile.data)
        } else if (getProfile.status == APIStates.FAILURE) {
            alert(getProfile.message)
        }
    }, [getProfile.status])

    const onDeleteAccount = () => {
        navigation.navigate("DeleteAccount")
    };


    return (
        <StyledScrollView style={{ flex: 1, width: '100%',backgroundColor:'white',padding:"2%" }}>
            <StyledView className="my-2">

                {/* Page Header */}
                <View style={style.pageHeaderStyle}>
                    <StyledText className={pageTitleStyle}>
                        Basic Profile
                    </StyledText>
                    <StyledView
                    className='flex flex-col'
                    style={{ position: 'static'}}
                >
                    <TouchableWithoutFeedback
                        onPress={() => onDeleteAccount()}
                    >
                        <View>
                                <Text className={textScreenDescriptionlStyle}>Delete Account</Text>
                        </View>
                    </TouchableWithoutFeedback>
                    <StyledView
                    className='flex flex-col'
                    style={{ position: 'static'}}
                >
                    <TouchableWithoutFeedback
                        onPress={() =>{navigation.navigate("CalendarComponent")}}
                    >
                        <View>
                                <Text className={textScreenDescriptionlStyle}>Sync Outlook Calendar</Text>
                        </View>
                    </TouchableWithoutFeedback>
                </StyledView>
                    </StyledView>
             
                </View>
              
                {/* <StyledText className={textScreenDescriptionlStyle}>
                    
                </StyledText> */}
                <StyledView className="flex-row justify-between">
                {
                    getProfile.status == APIStates.SUCCESS && getProfile.data.org != null && getProfile.data.org != undefined && 
                    <StyledText className={textScreenDescriptionlStyle}>
                        {`Role: ${getProfile.data.role}\n`}
                        {`Email: ${getProfile.data.email}\n`}
                        {`Organization: ${getProfile.data.org.name}`}
                    </StyledText>
                }
               
                </StyledView>
            </StyledView>
            <View>
            
            {
                (isChangePassword || isProfilePageVisible == null) && <MyButton
                    label={"Basic Information"}
                    theme={"viewClubs"}
                    onPress={() => {
                        setIsProfilePageVisible(true)
                        setIsChangePassword(false)
                    }}
                />
            }
                
            {
                (isProfilePageVisible || isChangePassword == null) && <MyButton
                    label={"Update Password"}
                    theme={"viewClubs"}
                    onPress={() => {
                        setIsChangePassword(true)
                        setIsProfilePageVisible(false)
                    }}
                />
            }

            {
                isChangePassword && <UpdatePassword uid={uid} currentUser={currentUser}  />
            }

            {
                isProfilePageVisible && <BasicInfo uid={uid} currentUser={currentUser} role={role} />
            }

            </View>
        </StyledScrollView>
    )
};

const style = StyleSheet.create({
    pageHeaderStyle: { flexDirection: 'row', justifyContent: 'space-between' },
    addNewUserButtonStyle: {
        color: 'rgba(87,164,251,1)',
        fontSize: 20,
        marginTop: 7.5,
         
    }
})