import { styled } from "nativewind";
import { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableWithoutFeedback, View, TouchableOpacity, Dimensions, Platform } from 'react-native';
import MyButton from '../MyButton';
import ClubNumber from './ClubNumber';
import { useSelector } from 'react-redux';
import { ScreenNames, readableFittingParamNames, recommendedCategories, userFittingResultsKeys } from "../../utils/Util";
import { pageTitleStyle, textScreenDescriptionlStyle } from '../Styles';
import AnalyticsLogger from "../managers/AnalyticsLogger";
import { useNavigation } from '@react-navigation/native';
import { useCurrentUser } from "../CurrentUserProvider";
const width = Dimensions.get("screen").width;
const StyledView = styled(View);
const StyledText = styled(Text);
const StyledScrollView = styled(ScrollView);

export default function FittingResults({route}) {
  const {fittingValuesUpdated} = route.param || {};
  const navigation = useNavigation();
  const { currentUser,uid,role } = useCurrentUser();
  const recommendations = useSelector(state => state.app.recommendations);
  const [selectedClubNumber, setSelectedClubNumber] = useState(null);
  const [fittingValues, setFittingValues] = useState(null);
  const clubNumbers = [
    ...recommendedCategories
  ]
  useEffect(() => {
    if (selectedClubNumber == 0) {
      navigation.navigate('FittingResults')
    }
  }, [selectedClubNumber]);
  

  useEffect(() => {
    AnalyticsLogger.getInstance().logScreenView(ScreenNames.FittingResults)
  }, [])

  useEffect(() => {
    console.log("Fitting value in fitting Page",fittingValues)
    if(fittingValues?.idealBagNumber != undefined) {
      recommendations.data.idealBag[`${fittingValues?.idealBagNumber}`] = fittingValues.fittingValues  
      setSelectedClubNumber(fittingValues.idealBagNumber)
    }
  }, [])

  const getValueFormat = (value)=>{
    if (typeof value === 'string'){
      return value
    } else {
      return value.toFixed(1)
    }
  }

  const getArrayFromObjectKeys = (object) => {
    // write a function that takes an object and returns an array of the key and value pairs
    const array = Object.entries(object).map(([key, value]) => {
      return { value: value, key: key }
    })

    const finalArray = []
    array.forEach((item) => {
      if(item.key != '_id'){
        if (typeof item.value === 'string'){
          finalArray.push(item)
        } else {
          const _item = { key : item.key, value : item.value.toFixed(1) }
          finalArray.push(_item)
        }
      }
    })

    return finalArray
  }

  return (
    <StyledScrollView style={style.mainContainer}>
      <StyledView className="my-2">

        {/* Page Header */}
        <View style={style.pageHeaderStyle}>
          <StyledText className={pageTitleStyle}>
            Fitting Results
          </StyledText>

          <TouchableWithoutFeedback onPress={() => { navigation.navigate('ViewClubs') }}>
            <Text
              style={style.addNewUserButtonStyle}
            >See AI Fitter</Text>
          </TouchableWithoutFeedback>
        </View>

        <StyledText className={textScreenDescriptionlStyle}>
          Click on one of the following links to see fitting results for each club.
        </StyledText>
      </StyledView>
      <View>
        <>
          {
            <View>
              {(selectedClubNumber) ?
                <ScrollView>
                  <MyButton
                    label={"Fitting Results"}
                    theme={"viewClubs"}
                    onPress={() => setSelectedClubNumber(null)}
                  />
                  <View style={{ flex: 1, flexDirection: "row", justifyContent: 'space-between', paddingBottom: width * .05, paddingLeft:Platform.OS==='ios'?"4%":"2%", paddingRight:Platform.OS==='ios'?"4%":"2%",  }}>
                    <TouchableWithoutFeedback 
                     onPress={() => { 
                      const newFittingValues = {
                        idealBagNumber: selectedClubNumber,
                        fittingValues: getArrayFromObjectKeys({...userFittingResultsKeys,...recommendations.data.idealBag?.[`${selectedClubNumber}`]} ||{})
                      };
              
                      setFittingValues(newFittingValues);
              
                      navigation.navigate("FittingResultsEditable", {
                        fittingValues: newFittingValues,
                      });
                    }}
                    >
                      <Text
                        style={style.addNewUserButtonStyle}
                      >
                        Edit Fitting Results
                      </Text>
                    </TouchableWithoutFeedback>
                  </View>
                  {
                    Object.keys(userFittingResultsKeys || {}).map((key) => {
                      return (
                        <View key={key}>
                          <View style={{ justifyContent: 'space-between', flexDirection: 'row', marginBottom: 5 }}>
                            <View>
                              <StyledText className={textScreenDescriptionlStyle}>
                                {readableFittingParamNames[key]}
                              </StyledText>
                            </View>

                            <View>
                              <StyledText className={textScreenDescriptionlStyle}>
                                {getValueFormat(recommendations.data?.idealBag?.[`${selectedClubNumber}`]?.[key] || userFittingResultsKeys?.[key] || "")}
                              </StyledText>
                            </View>
                          </View>
                        </View>
                      )
                    })
                  }
                </ScrollView>
                :
                <>
                  {clubNumbers.map((data) => {
                    return (
                      <ClubNumber
                        key={data.number}
                        number={data.number}
                        label={data.label}
                        setSelectedClubNumber={setSelectedClubNumber}
                      />
                    )
                  })}
                </>
              }
            </View>
          }
         
        </>
      </View>
    </StyledScrollView>
  )
};

const style = StyleSheet.create({
  mainContainer:{ 
    flex: 1, 
    marginBottom: 0, 
    width: '100%' ,
    paddingLeft:Platform.OS==='ios'?"4%":"2%",
    paddingRight:Platform.OS==='ios'?"4%":"2%", 
    backgroundColor:'white'
  },
  pageHeaderStyle:{ 
    flexDirection: 'row', 
    justifyContent: 'space-between' 
  },
  addNewUserButtonStyle: {
    color: 'rgba(87,164,251,1)',
    fontSize: 20,
    marginTop: 7.5,
     
  }
})