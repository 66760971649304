import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from "react-native";

export const getItemFromStore = async (key) => {
    return AsyncStorage.getItem(key);
}

export const setItemInStore = async (key, value) => {
    return AsyncStorage.setItem(key, value);
}

export const deleteItemFromStore = async (key) => {
    return AsyncStorage.removeItem(key);
}