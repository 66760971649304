import { NativeWindStyleSheet, styled } from "nativewind";
import { useEffect, useState } from "react";
import { View } from 'react-native';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import RootView from './components/RootView';
import CrashLogger from "./components/managers/CrashLogger";
import { persistor, store } from './store';
import { deleteItemFromStore } from "./utils/storage/storage";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { NativeBaseProvider, Box } from "native-base";
import { NavigationContainer } from "@react-navigation/native";
import DrawerNavigator from "./navigation/DrawerNavigation";
import { NavigationProvider } from "./navigation/NavigationProvider";
import {  CurrentUserProvider } from "./components/CurrentUserProvider";

const stripePromise = loadStripe('pk_test_51PYAOEBkuqOUjMWLKxVbXMVgBGBU61BrYDRndtQilVl3YJz5WGiNGieOxu78mLu70ZEOaMeNNMdOuqxDtbrD3QhX00GTIaHgq8');

NativeWindStyleSheet.setOutput({
  web: "native",
  default: "native",
});

const StyledView = styled(View);

export default function App() {

  useEffect(() => {
    CrashLogger.getInstance().logAppLaunch();
  }, [])

  const clearStorages = async () => { 
    await deleteItemFromStore('isAdminUserLandedFirstTime')
    await deleteItemFromStore('userId')
    await deleteItemFromStore('role')
    await deleteItemFromStore('orgId')
    await deleteItemFromStore('token')
    await deleteItemFromStore('refresh')
  }
  
  return (
    <NativeBaseProvider>
      <CurrentUserProvider>
        <NavigationProvider>
           <NavigationContainer>
              <Elements stripe={stripePromise}>
                  <Provider store={store}>
                      <PersistGate loading={null} persistor={persistor}>
                           <RootView isCallingFromBooking={ window.location.host.includes('booking')} />
                      </PersistGate>
                  </Provider>
              </Elements>
            </NavigationContainer>
        </NavigationProvider>
      </CurrentUserProvider>
    </NativeBaseProvider>
  )
}
