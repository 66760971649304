import { getDatabase, ref, child, get, query, orderByChild, orderByKey, limitToLast, limitToFirst, equalTo } from "firebase/database";

export const readSwingData = (userId, setSwingDataKey) => {
  const dbRef = ref(getDatabase());
  get(child(dbRef, "swingData/" + userId))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setSwingDataKey(snapshot.val().key);
      } else {
        setSwingDataKey("noData");
      }
    })
    .catch((error) => {
      console.error(error);
      setSwingDataKey(null);
    });
}

export const readSavedConfig = (swingDataKey, clubNumber, setClubUids) => {
  const dbRef = ref(getDatabase());
  get(child(dbRef, "savedConfigs/" + swingDataKey + "/" + clubNumber))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setClubUids(JSON.parse(snapshot.val().uids));
      } else {
        setClubUids([]);
      }
    })
    .catch((error) => {
      console.error(error);
      setClubUids([]);
    });
}

// https://firebase.google.com/docs/database/web/lists-of-data
export const readClubDatabase = (uid, setClubData) => {
  const dbRef = ref(getDatabase());
  get(child(dbRef, "clubDatabase/" + uid))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setClubData(snapshot.val());
      } else {
        setClubData(null);
      }
    })
    .catch((error) => {
      console.error(error);
      setClubUids(null);
    });
}

// TODO: use the carryDistances in the AI caddie, round up for clubs, use slider to allow user to adjust recs
export const readCarryDistance = (swingDataKey, setCarryDistances) => {
  const dbRef = ref(getDatabase());
  get(child(dbRef, "savedConfigs/" + swingDataKey + "/idealBag"))
    .then((snapshot) => {
      if (snapshot.exists()) {
        let result = JSON.parse(snapshot.val());
        let returnResult = {};
        Object.entries(result).map((entry) => {
          returnResult[entry[1].optimizedCarryDistance] = entry[0];
        });
        setCarryDistances(returnResult);
      } else {
        setCarryDistances(null);
      }
    })
    .catch((error) => {
      console.error(error);
      setCarryDistances(null);
    });
}
