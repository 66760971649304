import { styled } from "nativewind";
import { useEffect, useState } from "react";
import {
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
  Platform,
  ActivityIndicator
} from "react-native";
import { useNavigation } from '@react-navigation/native';
import MyButton from "../MyButton";
import { pageTitleStyle, textScreenDescriptionlStyle } from "../Styles";
import { useCurrentUser } from "../CurrentUserProvider";
import { fetchRecommendationRequest } from "../../store/ducks/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { getItemFromStore } from "../../utils/storage/storage";

const StyledView = styled(View);
const StyledText = styled(Text);
const StyledScrollView = styled(ScrollView);

export default function AiFitterHome() {
  const { currentUser } = useCurrentUser();
  const navigation = useNavigation();
  const dispatch = useDispatch()
  const recommendations = useSelector(state => state.app.recommendations);

  const buttonData = [
    {
      id: 1,
      title: "Fitting Results",
      onPress: () => {
        if(recommendations?.data?.idealBag){
          navigation.navigate('FittingResults', {uid:currentUser?.id})
        } else {
          navigation.navigate('ViewClubs')
        }
      },
    },
    {
      id: 2,
      title: "Recommended Clubs",
      onPress: () => {
        navigation.navigate("RecommendedClubs");
      },
    },
    {
      id: 3,
      title: "Recommended Shaft",
      onPress: () => {
        navigation.navigate("RecommendedShaft");
      },
    },
    {
      id: 4,
      title: "Recommended Grips",
      onPress: () => {
        navigation.navigate("RecommendedGrips");
      },
    },
    {
      id: 5,
      title: "Recommended Balls",
      onPress: () => {
        navigation.navigate("RecommendedBalls");
      },
    },
  ];

  useEffect(()=>{
    fetchRecommendation()
  }, [currentUser?.id])

  const fetchRecommendation = async () => {
    const userId = await getItemFromStore('userId')
    const _userId = currentUser?.id || userId
    dispatch(fetchRecommendationRequest({
        userId: _userId
    }))
}

  return (
    <StyledScrollView
      style={style.mainContainer}
      refreshControl={
        <RefreshControl
          onRefresh={() => {
            setPullToRefresh(true);
          }}
        />
      }
    >
      {
        recommendations?.status === "loading" ? <ActivityIndicator /> : <>
        <StyledView className="my-2">
        {/* Page Header */}
        <View style={style.pageHeaderStyle}>
          <StyledText className={pageTitleStyle}>AI Fitter Home</StyledText>

          <TouchableWithoutFeedback
            onPress={() => {
              navigation.navigate("AiCaddie");
            }}
          >
            <Text style={style.addNewUserButtonStyle}>See AI Caddie</Text>
          </TouchableWithoutFeedback>
        </View>

        <StyledText className={textScreenDescriptionlStyle}>
          Click on one of the links to go to that page.
        </StyledText>
      </StyledView>
      <View>
        {buttonData.map((button) => (
          <View key={button.id}>
            <MyButton
              label={button.title}
              theme={"viewClubs"}
              onPress={button.onPress}
            />
          </View>
        ))}
      </View>
        </>
      }
    </StyledScrollView>
  );
}

const style = StyleSheet.create({
  mainContainer:{ 
    flex: 1, 
    marginBottom: 0, 
    width: '100%',
    backgroundColor:'white',
    paddingLeft:Platform.OS==='ios'?"4%":"2%",
    paddingRight:Platform.OS==='ios'?"4%":"2%",
   },
  pageHeaderStyle:{
     flexDirection: "row", 
     justifyContent: "space-between" 
    },
  addNewUserButtonStyle: {
    color: "rgba(87,164,251,1)",
    fontSize: 20,
    marginTop: 7.5,
     
  },
  resetToDefaultsButton: {
    color: "rgba(87,164,251,1)",
    fontSize: 15,
  },
});
