import { EmailAuthProvider, reauthenticateWithCredential } from '@firebase/auth';
import { styled } from "nativewind";
import React, { useEffect, useState } from 'react';
import {
    Alert,
    KeyboardAvoidingView,
    SafeAreaView,
    StyleSheet,
    Text,
    View
} from 'react-native';
import { auth } from '../../firebaseConfig';
import MyButton from '../MyButton';
import MyTextInput from '../MyTextInput';
import { pageTitleStyle, textDescriptionBoldWarningStyle, textDescriptionStyle } from '../Styles';
import { showError } from '../../utils/Util';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeleteUserIdle, fetchDeleteUserRequest, fetchLoginIdle, fetchLogoutIdle, fetchReAuthIdle, fetchReAuthRequest, fetchRefreshIdle, fetchRegisterIdle } from '../../store/ducks/app/actions';
import { APIStates } from '../../utils/Strings';
import { deleteItemFromStore, getItemFromStore } from '../../utils/storage/storage';
import AlertModal from '../modals/AlertModal';
import { useResponsiveValues } from '../booking/useResponsiveValues';

const StyledView = styled(View);
const StyledText = styled(Text);

const DeleteAccountComponent = ({ }) => {
    const dispatch = useDispatch()
    const [userId, setUserId] = useState("");
    const [email, onChangeEmail] = useState("");
    const [password, onChangePassword] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const reauth = useSelector(state => state.app.reauth);
    const deleteUser = useSelector(state => state.app.deleteUser);
    const { modelWidth } = useResponsiveValues();

    useEffect(() => {
        loadUserId()
    }, [])

    useEffect(() => {
        if (reauth.status == APIStates.SUCCESS) {
            dispatch(fetchReAuthIdle())
            dispatch(fetchDeleteUserRequest({
                userId: userId
            }))
        } else if (reauth.status == APIStates.FAILURE) {
            alert(reauth.message)
            dispatch(fetchReAuthIdle())
        }
    }, [reauth.status])

    useEffect(() => {
        if (deleteUser.status == APIStates.SUCCESS) {
            dispatch(fetchLogoutIdle())
            dispatch(fetchRegisterIdle())
            dispatch(fetchLoginIdle())
            dispatch(fetchRefreshIdle())
            dispatch(fetchDeleteUserIdle())
            deleteItemFromStore('userId')
            deleteItemFromStore('role')
            deleteItemFromStore('orgId')
            deleteItemFromStore('token')
            deleteItemFromStore('refresh')
            alert("User deleted Successfully")
        }
    }, [deleteUser.status])

    const deleteAccount = async () => {
        try {
            dispatch(fetchReAuthRequest({
                userId,
                email,
                password
            }))
        } catch (error) {
            showError(error.code)
        }
    }

    const loadUserId = async () => {
        const userId = await getItemFromStore('userId')
        setUserId(userId)
    }

    const openModal = () => {
        setIsModalVisible(true);
    };
    const handleOutsideClick = () => {
        setIsModalVisible(false)
    };

    const handleDismissCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <View style={{ flex: 1, marginBottom: 0, width: '100%', backgroundColor: "white", height: '100%' }}>
            <SafeAreaView style={style.safeAreaStyle}>
                <KeyboardAvoidingView behavior="padding">
                    <StyledView className="mx-auto my-2">
                        <StyledText className={pageTitleStyle}>
                            Delete Account
                        </StyledText>
                        <StyledText className={textDescriptionBoldWarningStyle} style={{ marginTop: 50 }}>
                            Delete your account will:
                        </StyledText>
                        <StyledText className={textDescriptionStyle}>
                            We're sorry to see you go. If you're sure you want to delete your Golf EQ app account, please be aware that this action is permanent and cannot be undone. All of your personal information, including your information and settings, will be permanently deleted.
                        </StyledText>
                        <StyledText className={textDescriptionStyle}>
                            If you're having trouble with your account or have concerns, please reach out to us at info@golf-eq.com before proceeding with the account deletion. We'd love to help you resolve any issues and keep you as a valued Golf EQ user.
                        </StyledText>

                        <MyTextInput
                            type={"email"}
                            value={email}
                            onChangeValue={onChangeEmail}
                        />

                        <MyTextInput
                            type={"password"}
                            value={password}
                            onChangeValue={onChangePassword}
                        />

                        <StyledText className={textDescriptionStyle}>
                            To delete your account, please enter your email & password in the above fields and confirm your decision by clicking the 'Delete My Account' button.                    </StyledText>

                        <MyButton
                            label={"Delete My Account"}
                            theme={"submit"}
                            onPress={() => openModal()}
                        />
                        <AlertModal
                            modalVisible={isModalVisible}
                            handleConfirmCancel={() => { deleteAccount(email, password) }}
                            handleDismissCancel={handleDismissCancel}
                            handleOutsideClick={handleOutsideClick}
                            modelWidth={modelWidth}
                            title={"Delete Account"}
                            description={"Are you sure you want to delete account?"}
                            btnLabel1={"Yes"}
                            btnLabel2={"No"}
                        />

                    </StyledView>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    )
}

const style = StyleSheet.create({
    mainBoxStyle: {
        padding: 20,
    },
    safeAreaStyle: {
        flex: 1,
        marginHorizontal: 20,
    },
    warningBoxStyle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    warningTextStyle: {
        fontFamily: 'semi_bold',
        fontSize: 16,
        color: 'red',
        marginLeft: 10,
    },
    descriptionTextStyle: {
        fontSize: 14,
        fontFamily: 'regular',
        color: '#000004',
        textAlign: 'justify',
        lineHeight: 22,
        marginBottom: 16,
        marginTop: 11,
    },
    enterPasswordDescriptionTextStyle: {
        fontSize: 14,
        fontFamily: 'regular',
        color: 'black',
        textAlign: 'justify',
        lineHeight: 20,
        marginTop: 10,
    },
    buttonBoxStyle: {
        flex: 1,
        justifyContent: 'flex-end',
        padding: 20,
    },
})

export default DeleteAccountComponent
