import React,{useState} from "react";
import { View, Text, StyleSheet, TouchableOpacity,ActivityIndicator} from "react-native";
import MaterialIcons from "@expo/vector-icons/MaterialCommunityIcons";
import SimpleLineIcons from "@expo/vector-icons/SimpleLineIcons";
import Feather from "@expo/vector-icons/Feather";
import {
  textDescriptionStyle,
  textDropDownStyle,
  textScreenDescriptionlStyle,
} from "../Styles";
import { useResponsiveValues } from './useResponsiveValues';
import { useDispatch,useSelector } from "react-redux";
import {  cancelBookingRequest } from "../../store/ducks/app/actions";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import AlertModal from "../modals/AlertModal";
import { APIStates } from "../../utils/Strings";


dayjs.extend(utc);
dayjs.extend(timezone); 

const BookedView = ({ selectedService, uid, selectedDate,name, cancel, pickedTime, address,booking }) => {
  const { flexDirection, screenDivider, screenPosition, screenBlock, topBlock, buttonAlignment, rightBlock, modelWidth } = useResponsiveValues();
  const [newBooking, setNewBooking] = useState(cancel);
  const [modalVisible, setModalVisible] = useState(false);
  const cancelBooking = useSelector(state => state.app.cancelBooking);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  
  booking(newBooking);

  const dispatch = useDispatch()

  const handleCancel = () => {
    setModalVisible(true);
  };

  const handleConfirmCancel = () => {
    dispatch(cancelBookingRequest({
      userId: uid,
        orgId: selectedService.orgId,
        bookingStartDateTime: dayjs(selectedDate).tz("utc").format(),
        bookingEndDateTime: dayjs(selectedDate).add(selectedService?.duration, "minute").tz("utc").format(),
    }))
    setModalVisible(false)
    setConfirmationVisible(true);
    
  };

  const handleDismissCancel = () => {
    setModalVisible(false); 
  };
  const handleOutsideClick = () => {
    setModalVisible(false)
  };
  const CancelConformation = () => {
    setModalVisible(false); 
    setConfirmationVisible(false);
    setNewBooking(v => !v)
  };

  

  const convertDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    if (hours > 0 && mins > 0) {
      return `${hours} hr ${mins} min`;
    } else if (hours > 0) {
      return `${hours} hr`;
    } else {
      return `${mins} min`;
    }
  };


  return (
    <View style={{flex:1}}>
      {selectedService ?(<>
        <View style={[styles.topTitle,{flex:topBlock}]}>
          <Text className={textScreenDescriptionlStyle}>
            {selectedService.name}
          </Text>
          <Text className={textDropDownStyle}>Price { selectedService.price }$</Text>
        </View>
        <View style={[styles.bookedContainer,{flexDirection:flexDirection}]}>
          {/* Left Container */}
          <View style={[styles.lContainer,{screenPosition,flex:screenBlock}]}>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <MaterialIcons name="timetable" size={30} color={"#007dba"} />
              <Text className={textDescriptionStyle}>Duration {""} {convertDuration(selectedService?.duration || '')} </Text>
              <Text className={textDescriptionStyle}>Time {""}{pickedTime}</Text>
            </View>
            
            <View style={styles.addressContainer}>
              <SimpleLineIcons name="location-pin" size={30} color={"#007dba"} />
              <View style={styles.txt}>
                <Text
                  className={textDescriptionStyle}
                  style={{ textAlign: "center" }}
                >
                  {address}
                </Text>
              </View>
            </View>

            <View style={styles.address}>
              <Feather name="user" size={30} color={"#007dba"} />
              <View style={{ flex: 1, marginTop: 10 }}>
                <Text className={textDescriptionStyle}>{name}</Text>
              </View>
            </View>
          </View>
          {/* Right Container */}
          <View
            style={[styles.rContainer, { flex: rightBlock, marginTop: buttonAlignment }]}
          >
            <View style={styles.button}>
              <TouchableOpacity onPress={() => {setNewBooking(!newBooking)}}>
                <Text style={styles.buttonText}>New Booking</Text>
              </TouchableOpacity>
            </View>
            <TouchableOpacity style={styles.button} onPress={() => { handleCancel() }}>
            {cancelBooking.status === APIStates.LOADING ?
                (
                    <ActivityIndicator />
                ) : (
                  <Text style={styles.buttonText}>Cancel Booking</Text>
                )}

            </TouchableOpacity>
          </View>
          {screenDivider === true ? <View style={styles.separator} />:null}
          
          <AlertModal 
            modalVisible={modalVisible}
            handleConfirmCancel={handleConfirmCancel}
            handleDismissCancel={handleDismissCancel}
            handleOutsideClick={handleOutsideClick}
            modelWidth={modelWidth}
            description= {"Are you sure you want to cancel your booking?"}
            title={"Cancel Booking"}
            btnLabel1={"Yes"}
            btnLabel2={"No"}
             />

          <AlertModal
            modalVisible={confirmationVisible}
            handleConfirmCancel={CancelConformation}
            handleOutsideClick={handleOutsideClick}
            modelWidth={modelWidth}
            description={"Your booking has been canceled successfully"}
            btnLabel1={"Yes"}
          />

        </View>
      </>):(null)}
      
    
    </View>
  );
};
export default BookedView;
const styles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
    justifyContent: "center",
    marginTop: 10,
  },
  bookedContainer: {
    flex: 1.5,
    flexGrow:1,
  },
  topTitle: {
    justifyContent: "center",
    alignItems: "center",
  },
  lContainer: {
    justifyContent: "center",
    alignItems: "center",
    flexGrow:1
  },
    rContainer: {
        paddingBottom: 30,
        alignItems: "center",
        justifyContent:'center',
    },
    address:{
        justifyContent:'center',
        alignItems:'center',
        marginTop: 20,
    },
  addressContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  txt: {
    flex: 1,
    width: "80%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
  separator: {
    position: "absolute",
    left: "50%",
    top: 0,
    bottom: 0,
    width: 1,
    backgroundColor: "lightgrey",
  },
  button: {
    backgroundColor: "#007dba",
    paddingVertical: 15,
    alignItems: "center",
    width: "50%",
    marginBottom: 10,
  },
 
  buttonText: {
    color: "white",
    fontSize: 16,
  },
  

});
