import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import * as constants from './constants';

const initialState = {
    login: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    register: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    logout: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    refresh: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    deleteUser: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    reauth: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    swing: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    postSwing: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    postUserFittingResults: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    users: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    createUser: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    updatePassword: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    recommendations: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    clubs: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    export: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    forgotPassword: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    resetPassword: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    updateUser: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    getProfile: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    resetFittingResults : {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    Checkout: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    Bookings: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    cancelBooking: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    timeSlots: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    Services: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    bookingTokens: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    syncCalendar: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    exportTransactions: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    fetchTransactions: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    exportOrders: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    createOrders: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    fetchOrders: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
    updateOrders: {
        data: [],
        status: 'idle',
        message: {},
        payload: {}
    },
};

// Login Request
const fetchLoginRequest = (state, action) => update(state, {
    login: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchLoginSuccess = (state, action) => update(state, {
    login: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchLoginFailure = (state, action) => update(state, {
    login: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchLoginIdle = state => update(state, {
    login: {
        data: { $set: initialState.login.data },
        status: { $set: 'idle' },
    }
});

// Register Request
const fetchRegisterRequest = (state, action) => update(state, {
    register: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchRegisterSuccess = (state, action) => update(state, {
    register: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchRegisterFailure = (state, action) => update(state, {
    register: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchRegisterIdle = state => update(state, {
    register: {
        data: { $set: initialState.register.data },
        status: { $set: 'idle' },
    }
});

// Logout Request
const fetchLogoutRequest = (state, action) => update(state, {
    logout: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchLogoutSuccess = (state, action) => update(state, {
    logout: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchLogoutFailure = (state, action) => update(state, {
    logout: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchLogoutIdle = state => update(state, {
    logout: {
        data: { $set: initialState.logout.data },
        status: { $set: 'idle' },
    }
});

// Save Tokens Request
const saveTokensRequest = (state, action) => update(state, {
    bookingTokens: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const saveTokensSuccess = (state, action) => update(state, {
    bookingTokens: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const saveTokensFailure = (state, action) => update(state, {
    bookingTokens: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const saveTokensIdle = state => update(state, {
    bookingTokens: {
        data: { $set: initialState.bookingTokens.data },
        status: { $set: 'idle' },
    }
});

// Sync Calendar Request
const syncCalendarRequest = (state, action) => update(state, {
    syncCalendar: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const syncCalendarSuccess = (state, action) => update(state, {
    syncCalendar: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const syncCalendarFailure = (state, action) => update(state, {
    syncCalendar: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const syncCalendarIdle = state => update(state, {
    syncCalendar: {
        data: { $set: initialState.syncCalendar.data },
        status: { $set: 'idle' },
    }
});

// Export Transactions Request
const exportTransactionsRequest = (state, action) => update(state, {
    exportTransactions: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const exportTransactionsSuccess = (state, action) => update(state, {
    exportTransactions: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const exportTransactionsFailure = (state, action) => update(state, {
    exportTransactions: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const exportTransactionsIdle = state => update(state, {
    exportTransactions: {
        data: { $set: initialState.exportTransactions.data },
        status: { $set: 'idle' },
    }
});

// Fetch Transactions Request
const fetchTransactionsRequest = (state, action) => update(state, {
    fetchTransactions: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchTransactionsSuccess = (state, action) => update(state, {
    fetchTransactions: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchTransactionsFailure = (state, action) => update(state, {
    fetchTransactions: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchTransactionsIdle = state => update(state, {
    fetchTransactions: {
        data: { $set: initialState.fetchTransactions.data },
        status: { $set: 'idle' },
    }
});

// Refresh Request
const fetchRefreshRequest = (state, action) => update(state, {
    refresh: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchRefreshSuccess = (state, action) => update(state, {
    refresh: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchRefreshFailure = (state, action) => update(state, {
    refresh: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchRefreshIdle = state => update(state, {
    refresh: {
        data: { $set: initialState.refresh.data },
        status: { $set: 'idle' },
    }
});

// Delete Accouunt Request
const fetchDeleteUserRequest = (state, action) => update(state, {
    deleteUser: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchDeleteUserSuccess = (state, action) => update(state, {
    deleteUser: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchDeleteUserFailure = (state, action) => update(state, {
    deleteUser: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchDeleteUserIdle = state => update(state, {
    deleteUser: {
        data: { $set: initialState.deleteUser.data },
        status: { $set: 'idle' },
    }
});

// Delete Accouunt Request
const fetchReAuthRequest = (state, action) => update(state, {
    reauth: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchReAuthSuccess = (state, action) => update(state, {
    reauth: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchReAuthFailure = (state, action) => update(state, {
    reauth: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchReAuthIdle = state => update(state, {
    reauth: {
        data: { $set: initialState.reauth.data },
        status: { $set: 'idle' },
    }
});

// Get Swing Data Against User ID
const fetchGetSwingRequest = (state, action) => update(state, {
    swing: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchGetSwingSuccess = (state, action) => update(state, {
    swing: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchGetSwingFailure = (state, action) => update(state, {
    swing: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchGetSwingIdle = state => update(state, {
    swing: {
        data: { $set: initialState.swing.data },
        status: { $set: 'idle' },
    }
});

// Post Swing Data Against User ID
const fetchPostSwingRequest = (state, action) => update(state, {
    postSwing: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchPostSwingSuccess = (state, action) => update(state, {
    postSwing: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchPostSwingFailure = (state, action) => update(state, {
    postSwing: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchPostSwingIdle = state => update(state, {
    postSwing: {
        data: { $set: initialState.postSwing.data },
        status: { $set: 'idle' },
    }
});


// Post UserFittingRequest Data Against User ID
const fetchPostUserFittingResultsRequest = (state, action) => update(state, {
    postUserFittingResults: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchPostUserFittingResultsSuccess = (state, action) => update(state, {
    postUserFittingResults: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchPostUserFittingResultsFailure = (state, action) => update(state, {
    postUserFittingResults: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});


const fetchPostUserFittingResultsIdle = state => update(state, {
    postUserFittingResults: {
        data: { $set: initialState.postUserFittingResults.data },
        status: { $set: 'idle' },
    }
});

// Fetch Users for Admin
const fetchUsersRequest = (state, action) => update(state, {
    users: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchUsersSuccess = (state, action) => update(state, {
    users: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchUsersFailure = (state, action) => update(state, {
    users: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchUsersIdle = state => update(state, {
    users: {
        data: { $set: initialState.users.data },
        status: { $set: 'idle' },
    }
});

// Fetch Create for Admin
const fetchCreateUserRequest = (state, action) => update(state, {
    createUser: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchCreateUserSuccess = (state, action) => update(state, {
    createUser: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchCreateUserFailure = (state, action) => update(state, {
    createUser: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchCreateUserIdle = state => update(state, {
    createUser: {
        data: { $set: initialState.createUser.data },
        status: { $set: 'idle' },
    }
});

// Fetch Update Password for Admin
const fetchUpdatePasswordRequest = (state, action) => update(state, {
    updatePassword: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchUpdatePasswordSuccess = (state, action) => update(state, {
    updatePassword: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchUpdatePasswordFailure = (state, action) => update(state, {
    updatePassword: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchUpdatePasswordIdle = state => update(state, {
    updatePassword: {
        data: { $set: initialState.updatePassword.data },
        status: { $set: 'idle' },
    }
});

// Fetch Recommendations
const fetchRecommendationRequest = (state, action) => update(state, {
    recommendations: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchRecommendationSuccess = (state, action) => update(state, {
    recommendations: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchRecommendationFailure = (state, action) => update(state, {
    recommendations: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchRecommendationIdle = state => update(state, {
    recommendations: {
        data: { $set: initialState.recommendations.data },
        status: { $set: 'idle' },
    }
});

// Fetch Recommended Clubs
const fetchClubsRequest = (state, action) => update(state, {
    clubs: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchClubsSuccess = (state, action) => update(state, {
    clubs: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchClubsFailure = (state, action) => update(state, {
    clubs: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchClubsIdle = state => update(state, {
    clubs: {
        data: { $set: initialState.clubs.data },
        status: { $set: 'idle' },
    }
});

// Fetch Export Clubs
const fetchExportUsersRequest = (state, action) => update(state, {
    export: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchExportUsersSuccess = (state, action) => update(state, {
    export: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchExportUsersFailure = (state, action) => update(state, {
    export: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchExportUsersIdle = state => update(state, {
    export: {
        data: { $set: initialState.export.data },
        status: { $set: 'idle' },
    }
});

// Fetch Forgot Password 
const fetchForgotPasswordRequest = (state, action) => update(state, {
    forgotPassword: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchForgotPasswordSuccess = (state, action) => update(state, {
    forgotPassword: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchForgotPasswordFailure = (state, action) => update(state, {
    forgotPassword: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchForgotPasswordIdle = state => update(state, {
    forgotPassword: {
        data: { $set: initialState.forgotPassword.data },
        status: { $set: 'idle' },
    }
});

// Fetch Reset Password API
const fetchResetPasswordRequest = (state, action) => update(state, {
    resetPassword: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchResetPasswordSuccess = (state, action) => update(state, {
    resetPassword: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchResetPasswordFailure = (state, action) => update(state, {
    resetPassword: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchResetPasswordIdle = state => update(state, {
    resetPassword: {
        data: { $set: initialState.resetPassword.data },
        status: { $set: 'idle' },
    }
});

// Fetch Update User API
const fetchUpdateUserRequest = (state, action) => update(state, {
    updateUser: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchUpdateUserSuccess = (state, action) => update(state, {
    updateUser: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchUpdateUserFailure = (state, action) => update(state, {
    updateUser: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchUpdateUserIdle = state => update(state, {
    updateUser: {
        data: { $set: initialState.updateUser.data },
        status: { $set: 'idle' },
    }
});

// Fetch Get User Profile API
const fetchUserProfileRequest = (state, action) => update(state, {
    getProfile: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchUserProfileSuccess = (state, action) => update(state, {
    getProfile: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchUserProfileFailure = (state, action) => update(state, {
    getProfile: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchUserProfileIdle = state => update(state, {
    getProfile: {
        data: { $set: initialState.getProfile.data },
        status: { $set: 'idle' },
    }
});

// Fetch Reset Fitting Results to Default
const fetchResetFittingResultsRequest = (state, action) => update(state, {
    resetFittingResults: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchResetFittingResultsSuccess = (state, action) => update(state, {
    resetFittingResults: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchResetFittingResultsFailure = (state, action) => update(state, {
    resetFittingResults: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchResetFittingResultsIdle = state => update(state, {
    resetFittingResults: {
        data: { $set: initialState.resetFittingResults.data },
        status: { $set: 'idle' },
    }
});

// post Payments 
const fetchCheckOutRequest = (state, action) => update(state, {
    Checkout: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchCheckOutSuccess = (state, action) => update(state, {
  Checkout: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchCheckOutFailure = (state, action) => update(state, {
   Checkout: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchCheckOutIdle = state => update(state, {
    Checkout: {
        data: { $set: initialState.Checkout.data },
        status: { $set: 'idle' },
    }
});

// SAVE BOOKINGS 
const saveBookingRequest = (state, action) => update(state, {
    Bookings: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const saveBookingSuccess = (state, action) => update(state, {
  Bookings: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const saveBookingFailure = (state, action) => update(state, {
   Bookings: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const saveBookingIdle = state => update(state, {
    Bookings: {
        data: { $set: initialState.Bookings.data },
        status: { $set: 'idle' },
    }
});

// CANCEL BOOKINGS 
const cancelBookingRequest = (state, action) => update(state, {
    cancelBooking: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const cancelBookingSuccess = (state, action) => update(state, {
  cancelBooking: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const cancelBookingFailure = (state, action) => update(state, {
   cancelBooking: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const cancelBookingIdle = state => update(state, {
    cancelBooking: {
        data: { $set: initialState.cancelBooking.data },
        status: { $set: 'idle' },
    }
});

// GET TIMESLOTS 
const getTimeSlotsRequest = (state, action) => update(state, {
    timeSlots: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const getTimeSlotsSuccess = (state, action) => update(state, {
  timeSlots: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const getTimeSlotsFailure = (state, action) => update(state, {
   timeSlots: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const getTimeSlotsIdle = state => update(state, {
    timeSlots: {
        data: { $set: initialState.timeSlots.data },
        status: { $set: 'idle' },
    }
});

// post Services

const fetchServicesRequest = (state, action) => update(state, {
    Services: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchServicesSuccess = (state, action) => update(state, {
    Services: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchServicesFailure = (state, action) => update(state, {
    Services: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchServicesIdle = state => update(state, {
    Services: {
        data: { $set: initialState.Services.data },
        status: { $set: 'idle' },
    }
});

// Fetch Cart Data
const exportOrdersRequest = (state, action) => update(state, {
    exportOrders: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const exportOrdersSuccess = (state, action) => update(state, {
    exportOrders: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const exportOrdersFailure = (state, action) => update(state, {
    exportOrders: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const exportOrdersIdle = state => update(state, {
    exportOrders: {
        data: { $set: initialState.exportOrders.data },
        status: { $set: 'idle' },
    }
});


// Fetch Orders Request
const fetchOrdersRequest = (state, action) => update(state, {
    fetchOrders: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const fetchOrdersSuccess = (state, action) => update(state, {
    fetchOrders: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const fetchOrdersFailure = (state, action) => update(state, {
    fetchOrders: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const fetchOrdersIdle = state => update(state, {
    fetchOrders: {
        data: { $set: initialState.fetchOrders.data },
        status: { $set: 'idle' },
    }
});


// Create Orders Request
const createOrdersRequest = (state, action) => update(state, {
    createOrders: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const createOrdersSuccess = (state, action) => update(state, {
    createOrders: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const createOrdersFailure = (state, action) => update(state, {
    createOrders: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const createOrdersIdle = state => update(state, {
    createOrders: {
        data: { $set: initialState.createOrders.data },
        status: { $set: 'idle' },
    }
});


// Update Orders Request
const updateOrdersRequest = (state, action) => update(state, {
    updateOrders: {
        status: { $set: 'loading' },
        payload: { $set: action.payload }
    }
});

const updateOrdersSuccess = (state, action) => update(state, {
    updateOrders: {
        message: { $set: action.payload.message },
        data: { $set: action.payload.data },
        status: { $set: 'success' },
    }
});

const updateOrdersFailure = (state, action) => update(state, {
    updateOrders: {
        message: { $set: action.payload.message },
        status: { $set: 'failure' },
    }
});

const updateOrdersIdle = state => update(state, {
    updateOrders: {
        data: { $set: initialState.updateOrders.data },
        status: { $set: 'idle' },
    }
});


export default handleActions({
    [constants.FETCH_LOGIN_IDLE]: fetchLoginIdle,
    [constants.FETCH_LOGIN_REQUEST]: fetchLoginRequest,
    [constants.FETCH_LOGIN_SUCCESS]: fetchLoginSuccess,
    [constants.FETCH_LOGIN_FAILURE]: fetchLoginFailure,

    [constants.FETCH_REGISTER_IDLE]: fetchRegisterIdle,
    [constants.FETCH_REGISTER_REQUEST]: fetchRegisterRequest,
    [constants.FETCH_REGISTER_SUCCESS]: fetchRegisterSuccess,
    [constants.FETCH_REGISTER_FAILURE]: fetchRegisterFailure,

    [constants.FETCH_LOGOUT_IDLE]: fetchLogoutIdle,
    [constants.FETCH_LOGOUT_REQUEST]: fetchLogoutRequest,
    [constants.FETCH_LOGOUT_SUCCESS]: fetchLogoutSuccess,
    [constants.FETCH_LOGOUT_FAILURE]: fetchLogoutFailure,

    [constants.SAVE_TOKENS_IDLE]: saveTokensIdle,
    [constants.SAVE_TOKENS_REQUEST]: saveTokensRequest,
    [constants.SAVE_TOKENS_SUCCESS]: saveTokensSuccess,
    [constants.SAVE_TOKENS_FAILURE]: saveTokensFailure,

    [constants.SYNC_CALENDAR_IDLE]: syncCalendarIdle,
    [constants.SYNC_CALENDAR_REQUEST]: syncCalendarRequest,
    [constants.SYNC_CALENDAR_SUCCESS]: syncCalendarSuccess,
    [constants.SYNC_CALENDAR_FAILURE]: syncCalendarFailure,

    [constants.EXPORT_TRANSACTIONS_IDLE]: exportTransactionsIdle,
    [constants.EXPORT_TRANSACTIONS_REQUEST]: exportTransactionsRequest,
    [constants.EXPORT_TRANSACTIONS_SUCCESS]: exportTransactionsSuccess,
    [constants.EXPORT_TRANSACTIONS_FAILURE]: exportTransactionsFailure,

    [constants.FETCH_TRANSACTIONS_IDLE]: fetchTransactionsIdle,
    [constants.FETCH_TRANSACTIONS_REQUEST]: fetchTransactionsRequest,
    [constants.FETCH_TRANSACTIONS_SUCCESS]: fetchTransactionsSuccess,
    [constants.FETCH_TRANSACTIONS_FAILURE]: fetchTransactionsFailure,

    [constants.FETCH_REFRESH_IDLE]: fetchRefreshIdle,
    [constants.FETCH_REFRESH_REQUEST]: fetchRefreshRequest,
    [constants.FETCH_REFRESH_SUCCESS]: fetchRefreshSuccess,
    [constants.FETCH_REFRESH_FAILURE]: fetchRefreshFailure,

    [constants.FETCH_DELETE_USER_IDLE]: fetchDeleteUserIdle,
    [constants.FETCH_DELETE_USER_REQUEST]: fetchDeleteUserRequest,
    [constants.FETCH_DELETE_USER_SUCCESS]: fetchDeleteUserSuccess,
    [constants.FETCH_DELETE_USER_FAILURE]: fetchDeleteUserFailure,

    [constants.FETCH_REAUTH_IDLE]: fetchReAuthIdle,
    [constants.FETCH_REAUTH_REQUEST]: fetchReAuthRequest,
    [constants.FETCH_REAUTH_SUCCESS]: fetchReAuthSuccess,
    [constants.FETCH_REAUTH_FAILURE]: fetchReAuthFailure,

    [constants.FETCH_GET_SWING_IDLE]: fetchGetSwingIdle,
    [constants.FETCH_GET_SWING_REQUEST]: fetchGetSwingRequest,
    [constants.FETCH_GET_SWING_SUCCESS]: fetchGetSwingSuccess,
    [constants.FETCH_GET_SWING_FAILURE]: fetchGetSwingFailure,

    [constants.FETCH_POST_SWING_IDLE]: fetchPostSwingIdle,
    [constants.FETCH_POST_SWING_REQUEST]: fetchPostSwingRequest,
    [constants.FETCH_POST_SWING_SUCCESS]: fetchPostSwingSuccess,
    [constants.FETCH_POST_SWING_FAILURE]: fetchPostSwingFailure,

    [constants.FETCH_USERS_IDLE]: fetchUsersIdle,
    [constants.FETCH_USERS_REQUEST]: fetchUsersRequest,
    [constants.FETCH_USERS_SUCCESS]: fetchUsersSuccess,
    [constants.FETCH_USERS_FAILURE]: fetchUsersFailure,

    [constants.FETCH_CREATE_USER_IDLE]: fetchCreateUserIdle,
    [constants.FETCH_CREATE_USER_REQUEST]: fetchCreateUserRequest,
    [constants.FETCH_CREATE_USER_SUCCESS]: fetchCreateUserSuccess,
    [constants.FETCH_CREATE_USER_FAILURE]: fetchCreateUserFailure,

    [constants.FETCH_UPDATE_PASSWORD_IDLE]: fetchUpdatePasswordIdle,
    [constants.FETCH_UPDATE_PASSWORD_REQUEST]: fetchUpdatePasswordRequest,
    [constants.FETCH_UPDATE_PASSWORD_SUCCESS]: fetchUpdatePasswordSuccess,
    [constants.FETCH_UPDATE_PASSWORD_FAILURE]: fetchUpdatePasswordFailure,

    [constants.FETCH_RECOMMENDATION_IDLE]: fetchRecommendationIdle,
    [constants.FETCH_RECOMMENDATION_REQUEST]: fetchRecommendationRequest,
    [constants.FETCH_RECOMMENDATION_SUCCESS]: fetchRecommendationSuccess,
    [constants.FETCH_RECOMMENDATION_FAILURE]: fetchRecommendationFailure,

    [constants.FETCH_CLUBS_IDLE]: fetchClubsIdle,
    [constants.FETCH_CLUBS_REQUEST]: fetchClubsRequest,
    [constants.FETCH_CLUBS_SUCCESS]: fetchClubsSuccess,
    [constants.FETCH_CLUBS_FAILURE]: fetchClubsFailure,

    [constants.FETCH_EXPORT_USERS_IDLE]: fetchExportUsersIdle,
    [constants.FETCH_EXPORT_USERS_REQUEST]: fetchExportUsersRequest,
    [constants.FETCH_EXPORT_USERS_SUCCESS]: fetchExportUsersSuccess,
    [constants.FETCH_EXPORT_USERS_FAILURE]: fetchExportUsersFailure,

    [constants.FETCH_FORGOT_PASSWORD_IDLE]: fetchForgotPasswordIdle,
    [constants.FETCH_FORGOT_PASSWORD_REQUEST]: fetchForgotPasswordRequest,
    [constants.FETCH_FORGOT_PASSWORD_SUCCESS]: fetchForgotPasswordSuccess,
    [constants.FETCH_FORGOT_PASSWORD_FAILURE]: fetchForgotPasswordFailure,

    [constants.FETCH_RESET_PASSWORD_IDLE]: fetchResetPasswordIdle,
    [constants.FETCH_RESET_PASSWORD_REQUEST]: fetchResetPasswordRequest,
    [constants.FETCH_RESET_PASSWORD_SUCCESS]: fetchResetPasswordSuccess,
    [constants.FETCH_RESET_PASSWORD_FAILURE]: fetchResetPasswordFailure,

    [constants.FETCH_UPDATE_USER_IDLE]: fetchUpdateUserIdle,
    [constants.FETCH_UPDATE_USER_REQUEST]: fetchUpdateUserRequest,
    [constants.FETCH_UPDATE_USER_SUCCESS]: fetchUpdateUserSuccess,
    [constants.FETCH_UPDATE_USER_FAILURE]: fetchUpdateUserFailure,

    [constants.FETCH_USER_PROFILE_IDLE]: fetchUserProfileIdle,
    [constants.FETCH_USER_PROFILE_REQUEST]: fetchUserProfileRequest,
    [constants.FETCH_USER_PROFILE_SUCCESS]: fetchUserProfileSuccess,
    [constants.FETCH_USER_PROFILE_FAILURE]: fetchUserProfileFailure,

    [constants.FETCH_POST_USER_FITTING_RESULTS_IDLE]: fetchPostUserFittingResultsIdle,
    [constants.FETCH_POST_USER_FITTING_RESULTS_REQUEST]: fetchPostUserFittingResultsRequest,
    [constants.FETCH_POST_USER_FITTING_RESULTS_SUCCESS]: fetchPostUserFittingResultsSuccess,
    [constants.FETCH_POST_USER_FITTING_RESULTS_FAILURE]: fetchPostUserFittingResultsFailure,

    [constants.FETCH_RESET_FITTING_RESULTS_IDLE]: fetchResetFittingResultsIdle,
    [constants.FETCH_RESET_FITTING_RESULTS_REQUEST]: fetchResetFittingResultsRequest,
    [constants.FETCH_RESET_FITTING_RESULTS_SUCCESS]: fetchResetFittingResultsSuccess,
    [constants.FETCH_RESET_FITTING_RESULTS_FAILURE]: fetchResetFittingResultsFailure,

    [constants.FETCH_CHECKOUT_IDLE]: fetchCheckOutIdle,
    [constants.FETCH_CHECKOUT_REQUEST]: fetchCheckOutRequest,
    [constants.FETCH_CHECKOUT_SUCCESS]: fetchCheckOutSuccess,
    [constants.FETCH_CHECKOUT_FAILURE]: fetchCheckOutFailure,

    [constants.SAVE_BOOKINGS_IDLE]: saveBookingIdle,
    [constants.SAVE_BOOKINGS_REQUEST]: saveBookingRequest,
    [constants.SAVE_BOOKINGS_SUCCESS]: saveBookingSuccess,
    [constants.SAVE_BOOKINGS_FAILURE]: saveBookingFailure,

    [constants.CANCEL_BOOKINGS_IDLE]: cancelBookingIdle,
    [constants.CANCEL_BOOKINGS_REQUEST]: cancelBookingRequest,
    [constants.CANCEL_BOOKINGS_SUCCESS]: cancelBookingSuccess,
    [constants.CANCEL_BOOKINGS_FAILURE]: cancelBookingFailure,

    [constants.GET_TIMESLOTS_IDLE]: getTimeSlotsIdle,
    [constants.GET_TIMESLOTS_REQUEST]: getTimeSlotsRequest,
    [constants.GET_TIMESLOTS_SUCCESS]: getTimeSlotsSuccess,
    [constants.GET_TIMESLOTS_FAILURE]: getTimeSlotsFailure,

    [constants.FETCH_SERVICES_IDLE]: fetchServicesIdle,
    [constants.FETCH_SERVICES_REQUEST]: fetchServicesRequest,
    [constants.FETCH_SERVICES_SUCCESS]: fetchServicesSuccess,
    [constants.FETCH_SERVICES_FAILURE]: fetchServicesFailure,

    [constants.EXPORT_ORDERS_IDLE]: exportOrdersIdle,
    [constants.EXPORT_ORDERS_REQUEST]: exportOrdersRequest,
    [constants.EXPORT_ORDERS_SUCCESS]: exportOrdersSuccess,
    [constants.EXPORT_ORDERS_FAILURE]: exportOrdersFailure,

    [constants.FETCH_ORDERS_IDLE]: fetchOrdersIdle,
    [constants.FETCH_ORDERS_REQUEST]: fetchOrdersRequest,
    [constants.FETCH_ORDERS_SUCCESS]: fetchOrdersSuccess,
    [constants.FETCH_ORDERS_FAILURE]: fetchOrdersFailure,

    [constants.CREATE_ORDERS_IDLE]: createOrdersIdle,
    [constants.CREATE_ORDERS_REQUEST]: createOrdersRequest,
    [constants.CREATE_ORDERS_SUCCESS]: createOrdersSuccess,
    [constants.CREATE_ORDERS_FAILURE]: createOrdersFailure,

    [constants.UPDATE_ORDERS_IDLE]: updateOrdersIdle,
    [constants.UPDATE_ORDERS_REQUEST]: updateOrdersRequest,
    [constants.UPDATE_ORDERS_SUCCESS]: updateOrdersSuccess,
    [constants.UPDATE_ORDERS_FAILURE]: updateOrdersFailure,

}, initialState);
