import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
} from "react-native";
import {  useBreakpointValue, } from "native-base";

const width = Dimensions.get("window").width;
const height=Dimensions.get("window").height;

const RadioButton = ({ selected, onPress, label }) => {
  const Width = useBreakpointValue({
    base: width * 0.4,
    sm: width * 0.4,
    md: width * 0.4,
    lg: width * 0.2,
    xl: width * 0.2,
  });
  return (
    <TouchableOpacity style={[styles.radioButtonContainer, { width: Width }]} onPress={onPress}>
      <Text style={styles.radioButtonText}>{label}</Text>
      <View
        style={[styles.radioButton, selected && styles.radioButtonSelected]}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  radioButtonContainer: {
    flex: 1,
    flexDirection: "row",

    justifyContent: "space-between",
    marginBottom: height * 0.02,

    alignItems: "flex-start",
  },
  radioButton: {
    height: 16,
    width: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "grey",
    alignItems: "center",
    marginRight: 10,
  },
  radioButtonSelected: {
    backgroundColor: "rgba(118,118,118,255)",
    height: 14,
    width: 14,
    borderRadius: 7,
  },
  radioButtonText: {
    fontSize: 16,
  },
});

export default RadioButton;
