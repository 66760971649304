import React from 'react';
import { View, Text } from 'react-native';


const Dashboard = ({}) => {
    return (
        <View style={{flex:1,backgroundColor:'white'}}>
             <View style={{ flex:1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white',height:'100%' }}>
                <Text>Page currently under development, check back soon</Text>
              </View>
        </View>
    );
};

export default Dashboard;
