
export default class AnalyticsLogger {
  static instance;

  static getInstance() {
    if (!AnalyticsLogger.instance) {
      AnalyticsLogger.instance = new AnalyticsLogger();
    }
    return AnalyticsLogger.instance;
  }

  async logSetUserProperties(user) {
    
  }

  async logScreenView(screenName) {
    console.log('ANALYTICS LOGS: Screen Name - ' + screenName)
  }

  async logLogin(status) {
    console.log('ANALYTICS LOGS: LOGIN');
  }

  async logRegister(status) {
    console.log('ANALYTICS LOGS: REGISTER');
  }

  async logEvent(eventName, params) {
    console.log('ANALYTICS LOGS: EVENT - ' + eventName + '\n ' + JSON.stringify(params));
  }
}