export const ErrorMessages = {
    Account_Exists: "User account exist with this email, try loggin in or user different email for registration.",
    WeakPassword: "Password Should be at least 6 characters long",
    GeneralError: "Something went wrong, please try again.",
    UserNotFound: "User don't exist with this email.",
    WrongCredentials: "Your Credentials are not correct, please try again with correct credentials.",
    TooManyRequests: "Too Many Requests, Please try again in some time."
}

export const APIStates = {
    IDLE: 'idle',
    SUCCESS: 'success',
    FAILURE: 'failure',
    LOADING: 'loading'
}