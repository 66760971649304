import { createAction } from 'redux-actions';
import * as constants from './constants';

export const fetchLoginIdle = createAction(constants.FETCH_LOGIN_IDLE);
export const fetchLoginRequest = createAction(constants.FETCH_LOGIN_REQUEST);
export const fetchLoginSuccess = createAction(constants.FETCH_LOGIN_SUCCESS);
export const fetchLoginFailure = createAction(constants.FETCH_LOGIN_FAILURE);

export const fetchRegisterIdle = createAction(constants.FETCH_REGISTER_IDLE);
export const fetchRegisterRequest = createAction(constants.FETCH_REGISTER_REQUEST);
export const fetchRegisterSuccess = createAction(constants.FETCH_REGISTER_SUCCESS);
export const fetchRegisterFailure = createAction(constants.FETCH_REGISTER_FAILURE);

export const fetchLogoutIdle = createAction(constants.FETCH_LOGOUT_IDLE);
export const fetchLogoutRequest = createAction(constants.FETCH_LOGOUT_REQUEST);
export const fetchLogoutSuccess = createAction(constants.FETCH_LOGOUT_SUCCESS);
export const fetchLogoutFailure = createAction(constants.FETCH_LOGOUT_FAILURE);

export const saveTokensIdle = createAction(constants.SAVE_TOKENS_IDLE);
export const saveTokensRequest = createAction(constants.SAVE_TOKENS_REQUEST);
export const saveTokensSuccess = createAction(constants.SAVE_TOKENS_SUCCESS);
export const saveTokensFailure = createAction(constants.SAVE_TOKENS_FAILURE);

export const syncCalendarIdle = createAction(constants.SYNC_CALENDAR_IDLE);
export const syncCalendarRequest = createAction(constants.SYNC_CALENDAR_REQUEST);
export const syncCalendarSuccess = createAction(constants.SYNC_CALENDAR_SUCCESS);
export const syncCalendarFailure = createAction(constants.SYNC_CALENDAR_FAILURE);

export const exportTransactionsIdle = createAction(constants.EXPORT_TRANSACTIONS_IDLE);
export const exportTransactionsRequest = createAction(constants.EXPORT_TRANSACTIONS_REQUEST);
export const exportTransactionsSuccess = createAction(constants.EXPORT_TRANSACTIONS_SUCCESS);
export const exportTransactionsFailure = createAction(constants.EXPORT_TRANSACTIONS_FAILURE);

export const fetchTransactionsIdle = createAction(constants.FETCH_TRANSACTIONS_IDLE);
export const fetchTransactionsRequest = createAction(constants.FETCH_TRANSACTIONS_REQUEST);
export const fetchTransactionsSuccess = createAction(constants.FETCH_TRANSACTIONS_SUCCESS);
export const fetchTransactionsFailure = createAction(constants.FETCH_TRANSACTIONS_FAILURE);

export const fetchRefreshIdle = createAction(constants.FETCH_REFRESH_IDLE);
export const fetchRefreshRequest = createAction(constants.FETCH_REFRESH_REQUEST);
export const fetchRefreshSuccess = createAction(constants.FETCH_REFRESH_SUCCESS);
export const fetchRefreshFailure = createAction(constants.FETCH_REFRESH_FAILURE);

export const fetchDeleteUserIdle = createAction(constants.FETCH_DELETE_USER_IDLE);
export const fetchDeleteUserRequest = createAction(constants.FETCH_DELETE_USER_REQUEST);
export const fetchDeleteUserSuccess = createAction(constants.FETCH_DELETE_USER_SUCCESS);
export const fetchDeleteUserFailure = createAction(constants.FETCH_DELETE_USER_FAILURE);

export const fetchReAuthIdle = createAction(constants.FETCH_REAUTH_IDLE);
export const fetchReAuthRequest = createAction(constants.FETCH_REAUTH_REQUEST);
export const fetchReAuthSuccess = createAction(constants.FETCH_REAUTH_SUCCESS);
export const fetchReAuthFailure = createAction(constants.FETCH_REAUTH_FAILURE);

export const fetchGetSwingIdle = createAction(constants.FETCH_GET_SWING_IDLE);
export const fetchGetSwingRequest = createAction(constants.FETCH_GET_SWING_REQUEST);
export const fetchGetSwingSuccess = createAction(constants.FETCH_GET_SWING_SUCCESS);
export const fetchGetSwingFailure = createAction(constants.FETCH_GET_SWING_FAILURE);

export const fetchPostSwingIdle = createAction(constants.FETCH_POST_SWING_IDLE);
export const fetchPostSwingRequest = createAction(constants.FETCH_POST_SWING_REQUEST);
export const fetchPostSwingSuccess = createAction(constants.FETCH_POST_SWING_SUCCESS);
export const fetchPostSwingFailure = createAction(constants.FETCH_POST_SWING_FAILURE);

export const fetchPostUserFittingResultsIdle = createAction(constants.FETCH_POST_USER_FITTING_RESULTS_IDLE);
export const fetchPostUserFittingResultsRequest = createAction(constants.FETCH_POST_USER_FITTING_RESULTS_REQUEST);
export const fetchPostUserFittingResultsSuccess = createAction(constants.FETCH_POST_USER_FITTING_RESULTS_SUCCESS);
export const fetchPostUserFittingResultsFailure = createAction(constants.FETCH_POST_USER_FITTING_RESULTS_FAILURE);


export const fetchUsersIdle = createAction(constants.FETCH_USERS_IDLE);
export const fetchUsersRequest = createAction(constants.FETCH_USERS_REQUEST);
export const fetchUsersSuccess = createAction(constants.FETCH_USERS_SUCCESS);
export const fetchUsersFailure = createAction(constants.FETCH_USERS_FAILURE);

export const fetchCreateUserIdle = createAction(constants.FETCH_CREATE_USER_IDLE);
export const fetchCreateUserRequest = createAction(constants.FETCH_CREATE_USER_REQUEST);
export const fetchCreateUserSuccess = createAction(constants.FETCH_CREATE_USER_SUCCESS);
export const fetchCreateUserFailure = createAction(constants.FETCH_CREATE_USER_FAILURE);

export const fetchUpdatePasswordIdle = createAction(constants.FETCH_UPDATE_PASSWORD_IDLE);
export const fetchUpdatePasswordRequest = createAction(constants.FETCH_UPDATE_PASSWORD_REQUEST);
export const fetchUpdatePasswordSuccess = createAction(constants.FETCH_UPDATE_PASSWORD_SUCCESS);
export const fetchUpdatePasswordFailure = createAction(constants.FETCH_UPDATE_PASSWORD_FAILURE);

export const fetchRecommendationIdle = createAction(constants.FETCH_RECOMMENDATION_IDLE);
export const fetchRecommendationRequest = createAction(constants.FETCH_RECOMMENDATION_REQUEST);
export const fetchRecommendationSuccess = createAction(constants.FETCH_RECOMMENDATION_SUCCESS);
export const fetchRecommendationFailure = createAction(constants.FETCH_RECOMMENDATION_FAILURE);

export const fetchClubsIdle = createAction(constants.FETCH_CLUBS_IDLE);
export const fetchClubsRequest = createAction(constants.FETCH_CLUBS_REQUEST);
export const fetchClubsSuccess = createAction(constants.FETCH_CLUBS_SUCCESS);
export const fetchClubsFailure = createAction(constants.FETCH_CLUBS_FAILURE);

export const fetchExportUsersIdle = createAction(constants.FETCH_EXPORT_USERS_IDLE);
export const fetchExportUsersRequest = createAction(constants.FETCH_EXPORT_USERS_REQUEST);
export const fetchExportUsersSuccess = createAction(constants.FETCH_EXPORT_USERS_SUCCESS);
export const fetchExportUsersFailure = createAction(constants.FETCH_EXPORT_USERS_FAILURE);

export const fetchForgotPasswordIdle = createAction(constants.FETCH_FORGOT_PASSWORD_IDLE);
export const fetchForgotPasswordRequest = createAction(constants.FETCH_FORGOT_PASSWORD_REQUEST);
export const fetchForgotPasswordSuccess = createAction(constants.FETCH_FORGOT_PASSWORD_SUCCESS);
export const fetchForgotPasswordFailure = createAction(constants.FETCH_FORGOT_PASSWORD_FAILURE);

export const fetchResetPasswordIdle = createAction(constants.FETCH_RESET_PASSWORD_IDLE);
export const fetchResetPasswordRequest = createAction(constants.FETCH_RESET_PASSWORD_REQUEST);
export const fetchResetPasswordSuccess = createAction(constants.FETCH_RESET_PASSWORD_SUCCESS);
export const fetchResetPasswordFailure = createAction(constants.FETCH_RESET_PASSWORD_FAILURE);

export const fetchUpdateUserIdle = createAction(constants.FETCH_UPDATE_USER_IDLE);
export const fetchUpdateUserRequest = createAction(constants.FETCH_UPDATE_USER_REQUEST);
export const fetchUpdateUserSuccess = createAction(constants.FETCH_UPDATE_USER_SUCCESS);
export const fetchUpdateUserFailure = createAction(constants.FETCH_UPDATE_USER_FAILURE);

export const fetchUserProfileIdle = createAction(constants.FETCH_USER_PROFILE_IDLE);
export const fetchUserProfileRequest = createAction(constants.FETCH_USER_PROFILE_REQUEST);
export const fetchUserProfileSuccess = createAction(constants.FETCH_USER_PROFILE_SUCCESS);
export const fetchUserProfileFailure = createAction(constants.FETCH_USER_PROFILE_FAILURE);

export const fetchResetFittingResultsIdle = createAction(constants.FETCH_RESET_FITTING_RESULTS_IDLE);
export const fetchResetFittingResultsRequest = createAction(constants.FETCH_RESET_FITTING_RESULTS_REQUEST);
export const fetchResetFittingResultsSuccess = createAction(constants.FETCH_RESET_FITTING_RESULTS_SUCCESS);
export const fetchResetFittingResultsFailure = createAction(constants.FETCH_RESET_FITTING_RESULTS_FAILURE);


export const fetchCheckoutIdle = createAction(constants.FETCH_CHECKOUT_IDLE);
export const fetchCheckoutRequest = createAction(constants.FETCH_CHECKOUT_REQUEST);
export const fetchCheckoutSuccess = createAction(constants.FETCH_CHECKOUT_SUCCESS);
export const fetchCheckoutFailure = createAction(constants.FETCH_CHECKOUT_FAILURE);

export const saveBookingIdle = createAction(constants.SAVE_BOOKINGS_IDLE);
export const saveBookingRequest = createAction(constants.SAVE_BOOKINGS_REQUEST);
export const saveBookingSuccess = createAction(constants.SAVE_BOOKINGS_SUCCESS);
export const saveBookingFailure = createAction(constants.SAVE_BOOKINGS_FAILURE);

export const cancelBookingIdle = createAction(constants.CANCEL_BOOKINGS_IDLE);
export const cancelBookingRequest = createAction(constants.CANCEL_BOOKINGS_REQUEST);
export const cancelBookingSuccess = createAction(constants.CANCEL_BOOKINGS_SUCCESS);
export const cancelBookingFailure = createAction(constants.CANCEL_BOOKINGS_FAILURE);

export const getTimeSlotsIdle = createAction(constants.GET_TIMESLOTS_IDLE);
export const getTimeSlotsRequest = createAction(constants.GET_TIMESLOTS_REQUEST);
export const getTimeSlotsSuccess = createAction(constants.GET_TIMESLOTS_SUCCESS);
export const getTimeSlotsFailure = createAction(constants.GET_TIMESLOTS_FAILURE);

export const fetchServicesIdle = createAction(constants.FETCH_SERVICES_IDLE);
export const fetchServicesRequest = createAction(constants.FETCH_SERVICES_REQUEST);
export const fetchServicesSuccess = createAction(constants.FETCH_SERVICES_SUCCESS);
export const fetchServicesFailure = createAction(constants.FETCH_SERVICES_FAILURE);

export const exportOrdersIdle = createAction(constants.EXPORT_ORDERS_IDLE);
export const exportOrdersRequest = createAction(constants.EXPORT_ORDERS_REQUEST);
export const exportOrdersSuccess = createAction(constants.EXPORT_ORDERS_SUCCESS);
export const exportOrdersFailure = createAction(constants.EXPORT_ORDERS_FAILURE);

export const fetchOrdersIdle = createAction(constants.FETCH_ORDERS_IDLE);
export const fetchOrdersRequest = createAction(constants.FETCH_ORDERS_REQUEST);
export const fetchOrdersSuccess = createAction(constants.FETCH_ORDERS_SUCCESS);
export const fetchOrdersFailure = createAction(constants.FETCH_ORDERS_FAILURE);

export const createOrdersIdle = createAction(constants.CREATE_ORDERS_IDLE);
export const createOrdersRequest = createAction(constants.CREATE_ORDERS_REQUEST);
export const createOrdersSuccess = createAction(constants.CREATE_ORDERS_SUCCESS);
export const createOrdersFailure = createAction(constants.CREATE_ORDERS_FAILURE);

export const updateOrdersIdle = createAction(constants.UPDATE_ORDERS_IDLE);
export const updateOrdersRequest = createAction(constants.UPDATE_ORDERS_REQUEST);
export const updateOrdersSuccess = createAction(constants.UPDATE_ORDERS_SUCCESS);
export const updateOrdersFailure = createAction(constants.UPDATE_ORDERS_FAILURE);