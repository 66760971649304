import { styled } from "nativewind";
import { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { textInputLabelStyle } from "./Styles";

const StyledText = styled(Text);

const TagsPicker = ({ defaultSelected, index, tags, setSelection}) => {
    const [selectedTags, setSelectedTags] = useState(defaultSelected || []);

    const handleTagPress = (tag) => {
        if (selectedTags.length >= 3 && !selectedTags.includes(tag)) {
            return;
        }
        const _index = selectedTags.indexOf(tag);
        if (_index > -1) {
            setSelectedTags(selectedTags.filter((item) => item !== tag));
            setSelection(index, selectedTags.filter((item) => item !== tag))
        } else {
            setSelectedTags([...selectedTags, tag]);
            setSelection(index, [...selectedTags, tag])
        }
    };
    
    return (
        <View style={styles.tagsContainer}>
            <View style={{flexDirection:'column', width: '100%'}}>
                <View>
                    <StyledText className={textInputLabelStyle}> Brand Prefrences </StyledText>
                </View>
                <View>
                    <StyledText className='text-gray-900 text-lg capitalize mx-2'>Select up to 3</StyledText>
                </View>
            </View>

                {tags.map((tag) => (
                    <TouchableOpacity
                        key={tag}
                        style={[
                            styles.tag,
                            selectedTags.includes(tag) && styles.tagSelected,
                        ]}
                        onPress={() => handleTagPress(tag)}
                    >
                        <Text
                            style={[
                                styles.tagText,
                                selectedTags.includes(tag) && styles.tagTextSelected,
                            ]}
                        >
                            {tag}
                        </Text>
                    </TouchableOpacity>
                ))}
            </View>
        
    );

}

export default TagsPicker;

const styles = StyleSheet.create({
    tagsContainer : {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginVertical: 10,
    },
    tag: {
        backgroundColor: '#f1f1f1',
        borderRadius: 20,
        paddingVertical: 10,
        paddingHorizontal: 15,
        margin: 5,
    },
    tagText: {
        color: '#2C2C2C',
        fontSize: 16,
        fontWeight: '500'
    },
    tagSelected: {
        backgroundColor: '#000',
    },
    tagTextSelected: {
        color: '#fff',
    },
});