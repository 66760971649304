import { Image, Modal, Pressable, ScrollView, Text, View } from 'react-native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { styled } from 'nativewind';
import MyButton from '../MyButton';
import { useCurrentUser } from '../CurrentUserProvider';
import { useSelector } from 'react-redux';

const StyledImage = styled(Image);
const StyledPressable = styled(Pressable);
const StyledScrollView = styled(ScrollView);
const StyledText = styled(Text);
const StyledView = styled(View);

export default function ClubDetail({ isVisible, clubData, setIsModalVisible, selectedClubNumber }) {
  const recommendations = useSelector(state => state.app.recommendations);
  
  const modalBackgroundStyle = "basis-10 bg-gray-700 opacity-75"
  const {cart, handleAddCart, currentUser, handleRemoveCart} = useCurrentUser();


  const handleCart = ()=>{
    if (cart?.golferId && currentUser?.id !== cart?.golferId){
      alert("Please Clear Cart before Adding to this golfer")

    }else if (cart?.cartData?.length){
      alert("You can purchase 1 item at time")


    } else {
      const fittingResults = recommendations.data?.idealBag?.[selectedClubNumber] || {};
      
      handleAddCart(clubData, fittingResults);
      setIsModalVisible(false) 
    }

  

  }
  const isClubInCart = (currentUser?.id === cart?.golferId & cart?.cartData?.some(v => v?.productData?._id === clubData._id));


  const ProceedButton = () => {
    return (
      <MyButton
      label={isClubInCart ?  "Remove from Cart"  : "Add to Cart"}
      theme={"viewClubs"}
      onPress={() => {
        if (isClubInCart) {
          handleRemoveCart(clubData._id)
        } else {
          handleCart()
        }
      }}
    />
    
    );
  };
  
  const details = [
    {
      key: "lie",
      label: "Lie"
    },
    {
      key: "loft",
      label: "Loft"
    },
    {
      key: "hoselSetting",
      label: "Hosel Setting"
    },
    {
      key: "model_year",
      label: "Model Year"
    },
    {
      key: "offset",
      label: "Offset"
    }
  ]

  return (
    <Modal animationType="none" transparent={true} visible={isVisible}>
      <StyledView
        className="flex flex-row flex-shrink flex-grow"
      >
        <StyledView
          className={modalBackgroundStyle}
        />
        <StyledView
          className="flex flex-col flex-shrink flex-grow"
        >
          <StyledView
            className={modalBackgroundStyle}
          />
          <StyledView
            className='bg-off-white flex-shrink flex-grow p-2'
          >
            <StyledScrollView>
              <StyledView className="flex flex-row justify-start ">
                <StyledPressable onPress={() => setIsModalVisible(false)}>
                  <MaterialIcons name="close" color="#000" size={30} />
                </StyledPressable>
              </StyledView>
              <StyledView
                className='flex flex-row justify-center m-4'
              >
                <StyledText
                  className='flex flex-shrink text-3xl font-bold'
                >
                  {clubData.club_full_name}
                </StyledText>
              </StyledView>
              <StyledView
                className='flex flex-row justify-center m-4'
              >
                <StyledView
                  className='rounded-lg border-2 p-2 bg-white'
                >
                  <StyledImage
                    className="rounded-lg border"
                    style={{width:200, height:200}}
                    source={{uri: "https://cdn.shopify.com/s/files/1/0614/0379/1520/files/" + clubData.image}}
                    alt={clubData.club_full_name}
                  />
                </StyledView>
              </StyledView>
              <StyledView
                className='flex flex-col items-center'
              >
                {details.map((value) => {
                  if (clubData[value.key]) {
                    return (
                      <StyledView
                        className="m-2 flex flex-col justify-center"
                        key={value.key}
                      >
                        <StyledText
                          key={value.key}
                          className='text-2xl font-semibold'
                        >
                          {value.label}: {clubData[value.key]}
                        </StyledText>
                      </StyledView>
                    )
                  }
                })}
              </StyledView>
              <StyledView
                className='p-4 flex-shrink'
              >
                <ProceedButton/>
              </StyledView>
            </StyledScrollView>
          </StyledView>
          <StyledView
            className={modalBackgroundStyle}
          />
        </StyledView>
        <StyledView
          className={modalBackgroundStyle}
        />
      </StyledView>
    </Modal>
  )
}