import { View, Text, Pressable, TextInput, TouchableOpacity, TouchableWithoutFeedback, Platform } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import { styled } from 'nativewind';

import {
  pageTitleStyle,
  textFunStyle,
  textInputStyle,
  textInputLabelStyle,
  textStyle,
  textDropDownStyle
} from './Styles';
import MyButton from './MyButton';
import { useState } from 'react';

const StyledText = styled(Text);
const StyledPressable = styled(Pressable);
const StyledView = styled(View);
const StyledPicker = styled(Picker);
const StyledPickerItem = styled(Picker.Item);

// https://docs.expo.dev/versions/latest/sdk/picker/
export default function MyPicker({ label, pickerItems, selection, setSelection, onModalOpen, index, setCurrentSelectedIndex, setIsPickerVisible, isPickerVisible,placeholder }) {
  return (
    <StyledView className="mt-4">
      <StyledView className="flex flex-row">
        <StyledView className='flex-grow'>
          <StyledText className={textInputLabelStyle}>
            {label}
          </StyledText>
        </StyledView>
        {(onModalOpen) ?
          <StyledView className="">
            <Pressable onPress={onModalOpen}>
              <MaterialIcons name="help" color="#000" size={22} />
            </Pressable>
          </StyledView>
          :
          <></>
        }
      </StyledView>
      {
        Platform.OS == 'ios' &&
        <>
          <StyledView 
            className="mt-2 rounded-md border border-gray-700">
            <TouchableWithoutFeedback
              onPress={() => {
                setCurrentSelectedIndex && setCurrentSelectedIndex(index)
                setIsPickerVisible(index)
              }}
            >
              <StyledText className={textDropDownStyle}>
                {selection != null ? selection: placeholder || ""}
              </StyledText>
            </TouchableWithoutFeedback>
          </StyledView>
          {
            isPickerVisible > -1 && isPickerVisible == index && <StyledView 
              className="mt-2 rounded-md border border-gray-700">
              <StyledPicker
                selectedValue={selection}
                onValueChange={(itemValue, itemIndex) => {
                  setSelection(index, itemValue)
                  setIsPickerVisible(-1)
                }}
                className="p-2 rounded border-gray-700 text-xl"
              >
                {pickerItems.map((item) => {
                  if (typeof item === "string") {
                    return (
                      <StyledPickerItem className="uppercase font-bold text-2xl" key={item} label={item[0].toUpperCase() + item.slice(1)} value={item} />
                    )
                  } else {
                    return (
                      <StyledPickerItem className="uppercase font-bold text-2xl" key={item.toString()} label={item.toString()} value={item} />
                    )
                  }

                })}
              </StyledPicker>
            </StyledView>
          }
        </>
      }
      {
        Platform.OS == 'web' &&
        <StyledView className="mt-2 rounded-md border border-gray-700">
            
          <StyledPicker
            selectedValue={selection!= null ? selection : placeholder || ""}
            onValueChange={(itemValue, itemIndex) => {
              setSelection(index, itemValue) 
            }}
            className="p-2 rounded border-gray-700 text-xl"
          >
            {pickerItems.map((item) => {
              if (typeof item === "string") {
                return (
                  <StyledPickerItem className="uppercase font-bold text-2xl" key={item} label={item[0].toUpperCase() + item.slice(1)} value={item}  />
                )
              } else {
                return (
                  <StyledPickerItem className="uppercase font-bold text-2xl" key={item.toString()} label={item.toString()} value={item} />
                )
              }

            })}
          </StyledPicker>
        </StyledView>
      }

      {
        Platform.OS == 'android' &&
        <StyledView className="mt-2 rounded-md border border-gray-700">
          <StyledPicker
            selectedValue={selection!= null ? selection : placeholder || ""}
            onValueChange={(itemValue, itemIndex) =>
              setSelection(itemValue)
            }
            className="p-2 rounded border-gray-700 text-xl"
          >
            {pickerItems.map((item) => {
              if (typeof item === "string") {
                return (
                  <StyledPickerItem className="uppercase font-bold text-2xl" key={item} label={item[0].toUpperCase() + item.slice(1)} value={item} />
                )
              } else {
                return (
                  <StyledPickerItem className="uppercase font-bold text-2xl" key={item.toString()} label={item.toString()} value={item} />
                )
              }

            })}
          </StyledPicker>
        </StyledView>
      }
    </StyledView>
  )
};