import { handleActions } from 'redux-actions';
import * as constants from './constants';

const initialState = {
    theme: {
        status: 'DARK'
    },
}

export default handleActions({

}, initialState);