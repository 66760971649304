import { styled } from "nativewind";
import React, { useEffect, useState } from 'react';
import {
    ActivityIndicator,
    Alert,
    Dimensions,
    FlatList,
    KeyboardAvoidingView,
    RefreshControl,
    SafeAreaView,
    StyleSheet,
    Text,
    TouchableWithoutFeedback,
    View,
    Platform,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeleteUserIdle, fetchDeleteUserRequest, fetchUsersIdle, fetchUsersRequest } from '../../store/ducks/app/actions';
import { APIStates } from '../../utils/Strings';
import MyTextInput from '../MyTextInput';
import { pageTitleStyle } from '../Styles';
import AnalyticsLogger from '../managers/AnalyticsLogger';
import { ScreenNames } from '../../utils/Util';
import { getItemFromStore } from '../../utils/storage/storage';
import { useNavigation } from '@react-navigation/native';
import { useCurrentUser } from "../CurrentUserProvider";
import AlertModal from "../modals/AlertModal";
import { useResponsiveValues } from "../booking/useResponsiveValues";

const StyledText = styled(Text);
const StyledView = styled(View);
const width = Dimensions.get("window");
const height = Dimensions.get('window').height;
 
const UsersListingComponent = ({route}) => {
  const { modelWidth } = useResponsiveValues();
  const {type,role,orgData} = route?.params || {};
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [search, onChangeSearch] = useState("");
  const [searchKeyword, onChangeSearchKeyword] = useState("");
  const [userList, setUserList] = useState([]);
  const [searchUserList, setSearchUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalSearchPages, setTotalSearchPages] = useState(1);
  const [deletedIndex, setDeletedIndex] = useState(null);
  const users = useSelector((state) => state.app.users);
  const deleteUser = useSelector((state) => state.app.deleteUser);
  const [isAdmin, setIsAdmin] = useState(false);
  const [screenToNavigate, setScreenToNavigate] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { setSelectedUser,setUserId,setUserRole} = useCurrentUser();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    checkIfAdmin();
  }, []);

  useEffect(() => {
    const initializeUser = async () => {
        const userId = await getItemFromStore('userId');
        setUserId(userId); // Set User ID
        const role = await getItemFromStore('role');
        setUserRole(role); // Set User Role
    };
    initializeUser();
}, []);

  const checkIfAdmin = async () => {
    const userId = await getItemFromStore('userId')
    setUserId(userId)
    const role = await getItemFromStore('role');
    setUserRole(role)
    setIsAdmin(['provider', 'owner', 'admin', 'contributor','viewer'].includes(role) ? true : false)
    navigation.navigate(['provider', 'owner', 'admin', 'contributor', 'viewer'].includes(role) ? "UserList" : "AiCaddie")
  }
  useEffect(() => {
    AnalyticsLogger.getInstance().logScreenView(
      type == "golfer" ? ScreenNames.GolfersListing : ScreenNames.UsersListing
    );
    loadUsers();
  }, [type]);

  useEffect(() => {
    if (users.status == APIStates.SUCCESS) {
      search == ""
        ? setTotalPages(users.totalPages)
        : setTotalSearchPages(users.totalPages);
      search == ""
        ? setUserList(users.data.results)
        : setSearchUserList(users.data.results);
      page < users.totalPages &&
        (search == "" ? setPage(page + 1) : setSearchPage(searchPage + 1));
      dispatch(fetchUsersIdle());
    } else if (users.status == APIStates.FAILURE) {
      dispatch(fetchUsersIdle());
    }
  }, [users.status]);

  useEffect(() => {
    if (deleteUser.status == APIStates.SUCCESS) {
      userList.splice(deletedIndex, 1);
      setDeletedIndex(null);
      alert("User Deleted Successfully.");
      dispatch(fetchDeleteUserIdle());
    } else if (deleteUser.status == APIStates.FAILURE) {
      alert(deleteUser.message);
      setDeletedIndex(null);
      dispatch(fetchDeleteUserIdle());
    }
  }, [deleteUser.status]);

  const loadUsers = async (search = "") => {
    const orgId = await getItemFromStore("orgId");
    // Fetch Users on Screen Load
    const pageToLoad =
      search == ""
        ? page == 0
          ? 1
          : page + 1
        : searchPage == 0
        ? 1
        : searchPage + 1;
    const body = {
      orgId: orgId,
      page: pageToLoad,
      limit: 20,
    };
    type == "golfer"
      ? (body.role = "golfer")
      : (body.role =
          "provider" +
          "&role[]=owner" +
          "&role[]=admin" +
          "&role[]=contributor" +
          "&role[]=viewer");
    search != "" && (body.name = search);
    dispatch(fetchUsersRequest(body));
  };

  const handleOutsideClick = () => {
    setIsModalVisible(false)
  };

  const handleDismissCancel = () => {
    setIsModalVisible(false); 
  };

  const openModal = (item, index) => {
    setSelectedItem(item);
    setSelectedIndex(index);
    setIsModalVisible(true);
  };

  const deleteAccount = async (item, index) => {
    try {
      setDeletedIndex(index);
      dispatch(
        fetchDeleteUserRequest({
          userId: item.id,
        })
      );
    } catch (error) {
      showError(error.code);
    }
    setIsModalVisible(false)
  };

  useEffect(() => {
      if (screenToNavigate) {
      navigation.navigate(screenToNavigate)
      setScreenToNavigate(null); 
    }
  }, [screenToNavigate, navigation]);

  const handleSelectUser = (user, screen) => {
    setSelectedUser(user); 
    setScreenToNavigate(screen);
  }; 

  return (
    <View style={style.mainContainer}>
      <SafeAreaView style={style.safeAreaStyle}>
        <KeyboardAvoidingView behavior="padding">
          {/* Page Header */}
          <View style={style.pageHeaderStyle}>
            <View style={{flex:1,alignItems:'flex-start',justifyContent:'flex-start'}}>
            <StyledText className={pageTitleStyle} >
              {type == "golfer" ? "Golfers" : "Users"}
            </StyledText>
            </View>

            {role == "viewer" ? null : type == "golfer" ? (
              <View style={{flex:1,alignItems:'flex-end',justifyContent:'flex-end'}}>
              <TouchableWithoutFeedback
                onPress={() => {
                  navigation.navigate("ImportUsers")
                }}
              >
                <Text style={style.addNewUserButtonStyle}>Import Golfers</Text>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback
                onPress={() => {
                  navigation.navigate("CreateUser",{role: role,create: type === "golfer" ? "golfer" : "organisationUser",})
                }}
              >
                <Text style={style.addNewUserButtonStyle}>Add Golfer</Text>
              </TouchableWithoutFeedback>
              </View>
            ) : (
              <TouchableWithoutFeedback
                onPress={() => {
                  navigation.navigate("CreateUser",{role: role,create: type === "golfer" ? "golfer" : "organisationUser",})
                }}
              >
                <Text style={style.addNewUserButtonStyle}>Add New User</Text>
              </TouchableWithoutFeedback>
            )}
          </View>

          {/* Search Field */}
          <View style={style.pageBodyStyle}>
          <MyTextInput
            isSearch={true}
            placeholder={
              type == "golfer"
                ? "Search Golfers by Email"
                : "Search Users by Email"
            }
            type={"email"}
            value={searchKeyword}
            onChangeValue={(text) => {
              onChangeSearchKeyword(text);
              if (text == "") {
                onChangeSearch("");
              }
            }}
            onSubmitEditing={(event) => {
              loadUsers(event.nativeEvent.text);
              onChangeSearch(event.nativeEvent.text);
            }}
          />
           <View style={Platform.OS == 'web' ? { height:height * 0.98} : {paddingBottom:200}}>
            <FlatList
            style={style.flatlistContainer}
            data={search == "" ? userList : searchUserList}
            keyExtractor={(item) => item.id}
            showsVerticalScrollIndicator={false}
            refreshControl={
              <RefreshControl
                refreshing={users.status == APIStates.LOADING}
                onRefresh={() => {
                  loadUsers();
                }}
              />
            }
            renderItem={({ item, index }) => (
              <TouchableWithoutFeedback onPress={() => {}}>
                <View style={style.item}>
                  <StyledText style={style.userName}>
                    {item.firstName
                      ? `${item.firstName} ${item.lastName}`
                      : item.name || "No Name"}
                    <StyledText style={style.userEmail}>
                      {` (${
                        item.role.charAt(0).toUpperCase() + item.role.slice(1)
                      })`}
                    </StyledText>
                  </StyledText>

                  <StyledText style={style.userEmail}>{item.email}</StyledText>
                  <View>
                    <View>
                      <View style={style.btnStyling}>
                        {/* See AI Caddie */}
                        <TouchableWithoutFeedback
                          onPress={() => handleSelectUser(item, "AiCaddie")} 
                        >
                          <Text style={style.userListButtonsStyle}>
                            See AI Caddie
                          </Text>
                        </TouchableWithoutFeedback>
                        {/* Edit Swing Data */}
                        {role == "viewer" ? null : (
                          <TouchableWithoutFeedback
                            onPress={() => handleSelectUser(item, "SwingDataFrom")} 
                          >
                            <Text
                              style={style.userListButtonsStyle}
                            >{`Edit Swing Data`}</Text>
                          </TouchableWithoutFeedback>
                        )}
                      </View>

                      <View style={style.btnStyling}>
                        {/* See Recommended Clubs */}
                        <TouchableWithoutFeedback
                          onPress={() => handleSelectUser(item, "AiFitterHome")}
                        >
                          <Text style={style.userListButtonsStyle}>
                            See AI Fitter
                          </Text>
                        </TouchableWithoutFeedback>

                        {/* View Golfer Profile */}
                        <TouchableWithoutFeedback
                          onPress={() => handleSelectUser(item, "UserProfile")} 
                          
                        >
                          <Text
                            style={style.userListButtonsStyle}
                          >{`View Profile`}</Text>
                        </TouchableWithoutFeedback>
                      </View>
                    </View>
                  </View>

                  {/* Delete User Account */}
                  {role == "viewer" ? null : (
                    <TouchableWithoutFeedback
                      onPress={() => {
                        openModal(item, index)
                      }}
                    >
                      <Text style={style.userDeleteButtonsStyle}>
                        Delete User
                      </Text>
                    </TouchableWithoutFeedback>
                    
                  )}
                  
                </View>
              </TouchableWithoutFeedback>
            )}
            onEndReached={() => {
              page > 0 && page < totalPages && loadUsers();
            }}
            onEndReachedThreshold={1}
            ListFooterComponent={
              users.status == APIStates.LOADING ? (
                <ActivityIndicator />
              ) : (
                  search == ""
                    ? page < totalPages
                    : searchPage < totalSearchPages
                ) ? (
                <Text>Load More</Text>
              ) : null
            }
          />
            </View> 
            <AlertModal
            modalVisible={isModalVisible}
            handleConfirmCancel={() =>deleteAccount(selectedItem, selectedIndex)} 
            handleDismissCancel={handleDismissCancel}
            handleOutsideClick={handleOutsideClick}
            modelWidth={modelWidth}
            title={"Delete Account"}
            description={"Are you sure you want to delete account?"}
            btnLabel1={"Yes"}
            btnLabel2={"No"}
          />
   
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </View>
  );
};

const style = StyleSheet.create({
  mainContainer:{
    flex: 1,
    margin: 0,
    backgroundColor:'white',
    justifyContent: Platform.OS === "ios" ? "center" :null,
    alignSelf: Platform.OS === "ios" ? "center" :null,
  },
  pageHeaderStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: Platform.OS === "ios" ? 20 :null,
    paddingRight: Platform.OS === "ios" ? 20 : null,
  },
  pageBodyStyle:{
    paddingLeft: Platform.OS === "ios" ? 20 : null,
    paddingRight: Platform.OS === "ios" ? 20 : null,
  },

  addNewUserButtonStyle: {
    color: "rgba(87,164,251,1)",
    fontSize: 20,
    marginTop: 7.5,
     
  },
  userListButtonsStyle: {
    color: "rgba(87,164,251,1)",
    fontSize: 17.5,
    marginTop: 7.5,
     
  },
  userDeleteButtonsStyle: {
    color: "#b22222",
    fontSize: 15.5,
    marginTop: 7.5,
     
  },
  flatlistContainer: { 
    marginBottom:Platform.OS === "ios" ? 200 :130,
  },
  userName: {
    fontSize: 20,
    fontWeight: "bold",
  },
  userEmail: {
    fontSize: 18,
    fontWeight: "400",
  },
  item: {
    padding: 20,
    borderWidth: 2,
    borderRadius: 10,
    borderBottomColor: "black",
    marginBottom: 10,
  },
  mainBoxStyle: {
    padding: 20,
  },
  safeAreaStyle: {
    flex: 1,
    marginHorizontal: 20,
    ...Platform.select({
      ios: {
        width: Dimensions.get("screen").width,
      },
      android: {
        width: width,
      },
      web: {
        width: width,
      },
    }),
  },
  btnStyling: {
    flexDirection: "row",
    justifyContent: "space-between",
    ...Platform.select({
      ios: {
        width: Dimensions.get("screen").width - 80,
      },
      android: {
        width: Dimensions.get("screen").width - 80,
      },
      web: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        width: Dimensions.get("window").width - 100,
        alignContent: "flex-end",
      },
    }),
  },
  warningBoxStyle: {
    flexDirection: "row",
    alignItems: "center",
  },
  warningTextStyle: {
    fontFamily: "semi_bold",
    fontSize: 16,
    color: "red",
    marginLeft: 10,
  },
  descriptionTextStyle: {
    fontSize: 14,
    fontFamily: "regular",
    color: "#000004",
    textAlign: "justify",
    lineHeight: 22,
    marginBottom: 16,
    marginTop: 11,
  },
  enterPasswordDescriptionTextStyle: {
    fontSize: 14,
    fontFamily: "regular",
    color: "black",
    textAlign: "justify",
    lineHeight: 20,
    marginTop: 10,
  },
  buttonBoxStyle: {
    flex: 1,
    justifyContent: "flex-end",
    padding: 20,
  },
  headerIos: {
    backgroundColor: "red",
  },
  headerAndroid: {
    backgroundColor: "#CCA7B1",
  },
});

export default UsersListingComponent;
