// https://docs.expo.dev/guides/using-firebase/
// https://github.com/expo/fyi/blob/main/firebase-js-auth-setup.md
// https://firebase.google.com/docs/database/web/start#web-modular-api

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";


// https://firebase.google.com/docs/auth/web/firebaseui
// https://github.com/firebase/firebaseui-web


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA54S7a0qbD37rx5sLRFBiHIWkbK_Upg44",
  authDomain: "golf-eq-app.firebaseapp.com",
  databaseURL: "https://golf-eq-app-default-rtdb.firebaseio.com",
  projectId: "golf-eq-app",
  storageBucket: "golf-eq-app.appspot.com",
  messagingSenderId: "789901232082",
  appId: "1:789901232082:web:ee5653e3f357717994ea0b",
  measurementId: "G-81YYQL6GBD",
  databaseURL: "https://golf-eq-app-default-rtdb.firebaseio.com/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
