import React from 'react'
import { createStackNavigator } from '@react-navigation/stack';
import CommonStack from '../CommonStack';

const Stack = createStackNavigator()

const TabTwoStack = ({ route }) => {
  const { uid, role, signMeOut } = route.params || {};
  return (
    <Stack.Navigator screenOptions={{
      headerShown: false,
    }}>
      <Stack.Screen name="CommonStack" component={CommonStack} initialParams={{ uid, role, signMeOut}}  options={{ title: 'golf-eq-app' }}  />
    </Stack.Navigator>
  )
}

export default TabTwoStack