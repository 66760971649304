import React from 'react'
import { createStackNavigator } from '@react-navigation/stack';
import Orders from '../../components/sideMenu/Order/Orders';
import OrderDetail from '../../components/sideMenu/Order/OrderDetail/OrderDetail';
import { useCurrentUser } from "../.././components/CurrentUserProvider";
import HeaderWithBack from "../.././components/HeaderWithBack";
import Header from "../.././components/Header";

const Stack = createStackNavigator()

const TabFourStack = () => {
  const { currentUser,uid,role,orgData } = useCurrentUser();
  return (
    <Stack.Navigator screenOptions={{
      headerShown: false,
    }}>
      <Stack.Screen name="Orders" component={Orders}  
                options={{
                  header: () => <Header uid={uid} role={role}orgName={orgData?.name}/>,
                  headerShown: true,
                  title: 'golf-eq-app',
            }}  
      />
      <Stack.Screen name="OrderDetail" component={OrderDetail}  
              options={{
                header: () => <HeaderWithBack uid={uid} role={role} orgName={orgData?.name} />,
                headerShown: true,
                title: 'golf-eq-app',
          }}     
      />
    </Stack.Navigator>
  )
}

export default TabFourStack