import { styled } from "nativewind";
import { ScrollView, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import { pageTitleStyle, textScreenDescriptionlStyle } from '../Styles';
import { useNavigation } from '@react-navigation/native';

const StyledView = styled(View);
const StyledText = styled(Text);
const StyledScrollView = styled(ScrollView);

export default function ClubsHeader({ title }) {
  const { navigation } = useNavigation();
  return (
    <View>
      <View style={style.pageHeaderStyle}>
        <StyledText className={pageTitleStyle}>
          {title}
        </StyledText>

        <TouchableWithoutFeedback onPress={() => { navigation.navigate('AiCaddie') }}>
          <Text
            style={style.addNewUserButtonStyle}
          >See AI Caddie</Text>
        </TouchableWithoutFeedback>
      </View>
      <StyledText className={textScreenDescriptionlStyle}>
        Click on one of the following links to see which clubs would be good fits for you.
      </StyledText>
    </View>
  )
};

const style = StyleSheet.create({
  pageHeaderStyle: { flexDirection: 'row', justifyContent: 'space-between' },
  addNewUserButtonStyle: {
    color: 'rgba(87,164,251,1)',
    fontSize: 20,
    marginTop: 7.5,
    marginBottom:10,
     
  },
  resetToDefaultsButton: {
    color: 'rgba(87,164,251,1)',
    fontSize: 15,
  }
})