import { styled } from "nativewind";
import { useEffect, useRef, useState } from 'react';
import { Dimensions, Pressable, ScrollView, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPostUserFittingResultsIdle, fetchPostUserFittingResultsRequest } from '../../store/ducks/app/actions';
import { APIStates } from '../../utils/Strings';
import { putter } from '../../utils/Util';
import MyButton from '../MyButton';
import MyPicker from '../Picker';
import {
    pageTitleStyle,
    textScreenDescriptionlStyle
} from '../Styles';
import ComboBox from "../ComboBox";
import ClubsHeader from "./ClubsHeader";

const StyledView = styled(View);
const StyledText = styled(Text);
const StyledScrollView = styled(ScrollView);
const width = Dimensions.get("screen").width;

export default function Putter({ setScreen, currentUser }) {

    const dispatch = useDispatch()
    const postUserFittingResults = useSelector(state => state.app.postUserFittingResults);
    const [refresh, setRefresh] = useState(false)
    const [userId, setUserId] = useState('');
    const [isPickerVisible, setIsPickerVisible] = useState(-1)
    const [inputs, setInputs] = useState(putter)
    const [formJson, setFormJson] = useState()

    const scrollRef = useRef(null)

    const setCurrentSelected = (index) => {
        scrollRef.current.scrollTo({ x: 0, y: index * Dimensions.get('screen').height * 0.15 - 100, animated: true })
    }

    const setSelection = (index, value) => {
        const _index = inputs.findIndex((input) => input.index == index);
        inputs[_index].selection = value;
        setRefresh(!refresh)
    };
    const items = ['Leslie Alexander', 'Michael Foster', 'Dries Vincent', 'Lindsay Walton', 'Courtney Henry', 'Tom Cook', 'Whitney Francis'];


    return (

        <StyledScrollView
            showsVerticalScrollIndicator={false}
            ref={scrollRef}
            style={{ flex: 1, marginBottom: 0, width: '100%',backgroundColor:'white',paddingLeft:"3%",paddingRight:"3%" }}>

            <StyledView className="my-2">

                {/* Page Header */}
                <ClubsHeader title={"Ai Fitter"}/>
                <StyledText className={textScreenDescriptionlStyle}>
                    Update fitting results for more personalised recommendations.
                </StyledText>
            </StyledView>
            <ComboBox label="Club Name" items={items} />

            <View
                key={refresh}
            >
                {inputs.map((data) => {

                    if (data.list) {
                        return (
                            <StyledView
                                key={data.name}
                            >
                                <MyPicker
                                    index={data.index}
                                    key={data.name}
                                    label={data.label.toString()}
                                    name={data.name}
                                    pickerItems={data.pickerItems}
                                    selection={data.selection}
                                    setSelection={setSelection}
                                    setCurrentSelectedIndex={setCurrentSelected}
                                    isPickerVisible={isPickerVisible}
                                    setIsPickerVisible={setIsPickerVisible}
                                />

                            </StyledView>

                        );
                    }
                }
                )}

                <StyledView className='mx-auto pt-2 pb-6'>
                    <MyButton
                        label={"Submit"}
                        theme={"submit"}
                    // onPress={() => onSubmit()}
                    />
                </StyledView>
            </View>
        </StyledScrollView>
    )
};

const style = StyleSheet.create({
    pageHeaderStyle: { flexDirection: 'row', justifyContent: 'space-between' },
    addNewUserButtonStyle: {
        color: 'rgba(87,164,251,1)',
        fontSize: 20,
        marginTop: 7.5,
         
    }
})