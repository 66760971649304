import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {  View, StyleSheet, TouchableOpacity, Text, TouchableWithoutFeedback } from 'react-native';
import { textInputLabelStyle } from '../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeSlotsRequest } from '../../store/ducks/app/actions';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone); 

const CustomCalender = ({ dateCheck,  selectedStartTime, selectedDate, pickedDate, setTime, setDateTimeStamp, availableTime,selectedService ,setEndTime}) => {
  const initialDate = dayjs(pickedDate || dayjs());
  const [value, setValue] = useState(initialDate);
  const [selectedSlot, setSelectedSlot] = useState(setTime);
  const getTimeSlots = useSelector(state => state.app.timeSlots);

const dispatch = useDispatch()


  const handleTagPress = (time) => {
    setSelectedSlot(time);
    handleDateChange(value, time);
  };

  useEffect(() => {
    setSelectedSlot(setTime);
  }, [setTime]);

  useEffect(() => {
    handleDateChange(value, selectedSlot);
  }, [value, selectedSlot]);

  useEffect(()=>{
    if (value?.format("DD/MM/YYYY")) {
      if (selectedService) {
        dispatch(getTimeSlotsRequest({
          orgId: selectedService.orgId || "",
          serviceId: selectedService.id || "",
          date: value.format("YYYY-MM-DD")
        }));
      } else {
        console.warn("selectedService is null or undefined");
      }
    }

  }, [value.format("DD/MM/YYYY")])

  const handleDateChange = (newValue, newSelectedTime = selectedSlot) => {
    if (newValue && newSelectedTime) {
      const date = new Date(newValue.toDate());
      const [time, period] = newSelectedTime.split(' ');
      let [hour, minute] = time.split(':').map(Number);

      if (period === 'PM' && hour < 12) {
        hour += 12;
      } else if (period === 'AM' && hour === 12) {
        hour = 0;
      }
      date.setHours(hour);
      date.setMinutes(minute);
      date.setSeconds(0);
      const epochTimestamp = date.getTime();
      setDateTimeStamp(epochTimestamp);
      dateCheck(dayjs(date).format());
      selectedDate(dayjs(date).format('YYYY/MM/DD'));
      selectedStartTime(newSelectedTime);

      
      let endDate;
      if (selectedService && selectedService.serviceDuration) {
        endDate = new Date(date.getTime() + selectedService.serviceDuration * 60000);
      } else {
        console.warn("selectedService or serviceDuration is null or undefined");
        endDate = new Date(date.getTime()); // Or handle this case differently based on your needs
      }

      const endEpochTimestamp = endDate.getTime();
      setEndTime(endEpochTimestamp); // Store end time as epoch timestamp
    }
    setValue(newValue);
  };


  let DatePickerComponent;
  DatePickerComponent = require('@mui/x-date-pickers/DatePicker').DatePicker;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <View components={['DatePicker']} style={{ flex: 1, flexDirection: 'column' }}>
        <TouchableWithoutFeedback style={{ width: '100%' }}>
          <DatePickerComponent
            value={value}
            onChange={(newValue) => handleDateChange(newValue)}
            renderLoading={() => null}
            minDate={dayjs()}
            
          />
        </TouchableWithoutFeedback>
        {value && getTimeSlots.data.length > 0 && (
          <View style={{ marginTop: 2 }}>
            <Text className={textInputLabelStyle}>Available Slots:</Text>
            <View style={styles.timeSlots}>
              {getTimeSlots.data?.map((time) => {
                const slot = dayjs(time).format("hh:mm A")
                const formatSlot = dayjs(time).format("DD-MM-YYYY")
                const formatDate = dayjs(value).format("DD-MM-YYYY")

                return formatSlot === formatDate ? (
                <TouchableOpacity
                  key={slot}
                  style={[
                    styles.time,
                    selectedSlot === slot && styles.timeSelected,
                  ]}
                  onPress={() => handleTagPress(slot)}
                >
                  <Text
                    style={[
                      styles.timeText,
                      selectedSlot === slot && styles.timeTextSelected,
                    ]}
                  >
                    {slot}
                  </Text>
                </TouchableOpacity>
              ) : null})}
            </View>
          </View>
        )}
        {value && getTimeSlots.data.length === 0 && (
          <View style={{ marginTop: 4 }}>
            <Text>No available slots for the selected day.</Text>
          </View>
        )}
      </View>
    </LocalizationProvider>
  );
};

export default CustomCalender;

const styles = StyleSheet.create({
  timeSlots: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  timeText: {
    fontSize: 18,
  },
  time: {
    backgroundColor: "#f1f1f1",
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 15,
    margin: 5,
  },
  timeSelected: {
    backgroundColor: "#000",
  },
  timeTextSelected: {
    color: "#fff",
  },
});
