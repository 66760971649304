import React from 'react';
import { View, Text } from 'react-native';

const Store = () => {
    return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center',backgroundColor:'white' }}>
            
            <Text>Page currently under development, check back soon</Text>
        </View>
    );
};

export default Store;
