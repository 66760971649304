import { styled } from "nativewind";
import { useEffect, useRef, useState } from 'react';
import { Dimensions, Pressable, ScrollView, StyleSheet, Text, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPostUserFittingResultsIdle, fetchPostUserFittingResultsRequest } from '../../store/ducks/app/actions';
import { APIStates } from '../../utils/Strings';
import { editableFittingParamNames } from "../../utils/Util";
import MyButton from '../MyButton';
import MyPicker from '../Picker';
import {
  pageTitleStyle,
  textScreenDescriptionlStyle
} from '../Styles';
import { useNavigation } from '@react-navigation/native';
import ComboBox from "../ComboBox";
import { useCurrentUser } from "../CurrentUserProvider";
import MyTextInput from "../MyTextInput";

const StyledView = styled(View);
const StyledText = styled(Text);
const StyledScrollView = styled(ScrollView);
const width = Dimensions.get("screen").width;

export default function FittingResultsEditable({ route }) {
  const { fittingValues } = route.params || {};
  const { currentUser,uid,role } = useCurrentUser();
  const navigation = useNavigation();
  const dispatch = useDispatch()
  const postUserFittingResults = useSelector(state => state.app.postUserFittingResults);
  const [refresh, setRefresh] = useState(false)
  const [userId, setUserId] = useState('');
  const [isPickerVisible, setIsPickerVisible] = useState(-1)
  const [inputs, setInputs] = useState(editableFittingParamNames)
  const [formJson, setFormJson] = useState();
  const [fittingValuesA, setFittingValues] = useState(null);

  const scrollRef = useRef(null)

  useEffect(() => {
    populateInputs();
  },[]);

  const populateInputs = () => {
    const convertedObject = fittingValues.fittingValues.reduce((obj, item) => {
      return { ...obj, [item.key]: item.value };
    }, {});
  
    inputs.forEach((input) => {
      input.selection = convertedObject[input.name]
    })
    setInputs(inputs)
    setRefresh(!refresh)
  }

  useEffect(() => {
    if (postUserFittingResults.status === APIStates.SUCCESS) {
      delete formJson.idealBagNumber;
      delete formJson.userId;
  
      const updatedFittingValues = {
        idealBagNumber: fittingValues.idealBagNumber,
        fittingValues: formJson,
      };
      setFittingValues(updatedFittingValues);
  
      setFormJson(undefined);
      navigation.navigate("FittingResults", { updatedFittingValues });
      dispatch(fetchPostUserFittingResultsIdle());
    } else if (postUserFittingResults.status === APIStates.FAILURE) {
      alert(postUserFittingResults.message);
      dispatch(fetchPostUserFittingResultsIdle());
    }
  }, [postUserFittingResults.status])

  function onSubmit() {
    const _userId = currentUser ? currentUser.id : userId;
    let formJson = {
      userId: _userId,
    }

    inputs.forEach((input) => {
      formJson[`${input.name}`] = input.selection;
    })
    formJson.idealBagNumber = fittingValues.idealBagNumber
    setFormJson(formJson)
    dispatch(fetchPostUserFittingResultsRequest(formJson))
  }

  const setCurrentSelected = (index) => {
    // scrollRef.current.scrollTo({ x: 0, y: index * Dimensions.get('screen').height * 0.15 - 100, animated: true })
  }

  const setSelection = (index, value) => {
    const _index = inputs.findIndex((input) => input.index == index);
    inputs[_index].selection = value;
    setRefresh(!refresh)
  };


  return (
    
    <StyledScrollView
      showsVerticalScrollIndicator={false}
      ref={scrollRef}
      style={{ flex: 1, marginBottom: 0, width: '100%',backgroundColor:'white',paddingLeft:'2%',paddingRight:'2%' }}>

      <StyledView className="my-2">

      {/* Page Header */}
      <View style={style.pageHeaderStyle}>
        <StyledText className={pageTitleStyle}>
          Update
        </StyledText>

          <TouchableWithoutFeedback onPress={() => { onSubmit()}}>
          <Text
            style={style.addNewUserButtonStyle}
          >Fitting Results</Text>
        </TouchableWithoutFeedback>
      </View>

      <StyledText className={textScreenDescriptionlStyle}>
        Update fitting results for more personalised recommendations.
      </StyledText>
      </StyledView>

      <View style={{marginTop:20}}
        key={refresh}
      >        
        {inputs.map((data) => {
          if (data.list) {
            return (
              <StyledView
                key={data.name}
              >
                <MyPicker
                  index={data.index}
                  key={data.name}
                  label={data.label.toString()}
                  name={data.name}
                  pickerItems={data.pickerItems}
                  selection={data.selection}
                  setSelection={setSelection}
                  placeholder={data.placeholder}
                  setCurrentSelectedIndex={setCurrentSelected}
                  isPickerVisible={isPickerVisible}
                  setIsPickerVisible={setIsPickerVisible}
                />
              </StyledView>
            );
          } else {
            if (data.combobox) {
              return (
                <ComboBox label={data.label} items={data.pickerItems} placeholder={data.placeholder}
                index={data.index}
                selection={data.selection}
                setSelection={setSelection}/>
              )
            }
            else {
              if (data.textinput) {
                return (
                  <MyTextInput
                  placeholder={data.placeholder}
                  label={data.label}
                  type={"email"}
                  value={data.selection || ""}
                  onChangeValue={(v)=>setSelection(data.index, v)}
                />
                )
              }
            }
        }}
      )}

      <StyledView className='mx-auto pt-2 pb-6'>
        <MyButton
          label={"Update Fitting Results"}
          theme={"submit"}
          onPress={() => onSubmit()}
        />
      </StyledView>
      </View> 
    </StyledScrollView>
  )
};

const style = StyleSheet.create({
  pageHeaderStyle: { flexDirection: 'row', justifyContent: 'space-between' },
  addNewUserButtonStyle: {
    color: 'rgba(87,164,251,1)',
    fontSize: 20,
    marginTop: 7.5,
     
  }
})