import { styled } from "nativewind";
import { useEffect, useState } from 'react';
import { ActivityIndicator, Image, KeyboardAvoidingView, Pressable, SafeAreaView, ScrollView, Text, View, Platform } from 'react-native';

import MyButton from '../MyButton';
import MyTextInput from '../MyTextInput';

import { useDispatch, useSelector } from 'react-redux';
import { fetchRegisterRequest } from '../../store/ducks/app/actions';
import { APIStates } from '../../utils/Strings';
import {
  pageTitleStyle,
  textFunStyle,
  textStyle
} from '../Styles';
import AnalyticsLogger from "../managers/AnalyticsLogger";
import { ScreenNames } from "../../utils/Util";

const StyledPressable = styled(Pressable);
const StyledView = styled(View);
const StyledText = styled(Text);
const StyledImage = styled(Image);
const StyledKeyboardAvoidingView = styled(KeyboardAvoidingView);

export default function LoginForm({ setShowSignUp, auth, isCallingFromBooking }) {

  const dispatch = useDispatch()
  const register = useSelector(state => state.app.register)
  const [email, onChangeEmail] = useState("");
  const [password, onChangePassword] = useState("");
  const [passwordConfirm, onChangePasswordConfirm] = useState("");

  useEffect(() => {
    AnalyticsLogger.getInstance().logScreenView(ScreenNames.RegisterForm)
  }, [])

  useEffect(() => {
    if (register.status == APIStates.FAILURE) {
      alert(register.message)
    }
  }, [register.status])

  const onSubmit = () => {
    if (email == '') {
      alert('Email is required.')
      return
    }
    if (password !== passwordConfirm) {
      alert("Passwords do not match, please try again.");
      return null;
    }

    dispatch(fetchRegisterRequest({
      email,
      password
    }))
  };

  // https://medium.com/@nickopops/keyboardavoidingview-not-working-properly-c413c0a200d4
  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={-100}
      >
        <ScrollView>
          {!isCallingFromBooking && (<StyledView className="flex flex-col">
            {/* logo styling */}
            <StyledView
              className='flex flex-row items-center justify-center'
            >
              <StyledImage
                className="rounded"
                style={{ width: 200, height: 150 }}
                source={require('../../assets/icon.png')}
                alt={"logo"}
              />
            </StyledView>
            <StyledText className={pageTitleStyle + " m-2"}>
              Sign up for Golf EQ
            </StyledText>
          </StyledView>)}
          

          {/* TextInputs */}
          <View>
          <MyTextInput
            label={"Email Address"}
            type={"email"}
            value={email}
            onChangeValue={onChangeEmail}
          />

          <MyTextInput
            label={"Password"}
            type={"password"}
            value={password}
            onChangeValue={onChangePassword}
          />

          <MyTextInput
            label={"Confirm Password"}
            type={"password"}
            value={passwordConfirm}
            onChangeValue={onChangePasswordConfirm}
          />
          </View>
          {
            register.status == APIStates.LOADING ?
              <View className='m-2 flex flex-row items-center justify-center'>
                <ActivityIndicator size="large" color="rgba(170,218,253,1)" />
              </View>
              :
            
                <MyButton
                  label={" Register with SwingID"}
                  theme={"submit"}
                  onPress={() => onSubmit()}
                />
             
             
          }


          {!isCallingFromBooking && (<StyledView className='m-2 flex flex-row items-center justify-center'>
            <StyledText className={textStyle}>
              Already a member?{' '}
            </StyledText>
            <StyledPressable
              onPress={() => setShowSignUp(false)}
            >
              <StyledText className={textFunStyle}>
                Sign in here
              </StyledText>
            </StyledPressable>
            {/* </StyledView> */}
          </StyledView>)}
          
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
