export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';
export const FETCH_LOGIN_IDLE = 'FETCH_LOGIN_IDLE';

export const FETCH_REGISTER_REQUEST = 'FETCH_REGISTER_REQUEST';
export const FETCH_REGISTER_SUCCESS = 'FETCH_REGISTER_SUCCESS';
export const FETCH_REGISTER_FAILURE = 'FETCH_REGISTER_FAILURE';
export const FETCH_REGISTER_IDLE = 'FETCH_REGISTER_IDLE';

export const FETCH_LOGOUT_REQUEST = 'FETCH_LOGOUT_REQUEST';
export const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS';
export const FETCH_LOGOUT_FAILURE = 'FETCH_LOGOUT_FAILURE';
export const FETCH_LOGOUT_IDLE = 'FETCH_LOGOUT_IDLE';

export const SAVE_TOKENS_REQUEST = 'SAVE_TOKENS_REQUEST';
export const SAVE_TOKENS_SUCCESS = 'SAVE_TOKENS_SUCCESS';
export const SAVE_TOKENS_FAILURE = 'SAVE_TOKENS_FAILURE';
export const SAVE_TOKENS_IDLE = 'SAVE_TOKENS_IDLE';

export const SYNC_CALENDAR_REQUEST = 'SYNC_CALENDAR_REQUEST';
export const SYNC_CALENDAR_SUCCESS = 'SYNC_CALENDAR_SUCCESS';
export const SYNC_CALENDAR_FAILURE = 'SYNC_CALENDAR_FAILURE';
export const SYNC_CALENDAR_IDLE = 'SYNC_CALENDAR_IDLE';

export const EXPORT_TRANSACTIONS_REQUEST = 'EXPORT_TRANSACTIONS_REQUEST';
export const EXPORT_TRANSACTIONS_SUCCESS = 'EXPORT_TRANSACTIONS_SUCCESS';
export const EXPORT_TRANSACTIONS_FAILURE = 'EXPORT_TRANSACTIONS_FAILURE';
export const EXPORT_TRANSACTIONS_IDLE = 'EXPORT_TRANSACTIONS_IDLE';

export const FETCH_TRANSACTIONS_REQUEST = 'FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';
export const FETCH_TRANSACTIONS_IDLE = 'FETCH_TRANSACTIONS_IDLE';

export const FETCH_REFRESH_REQUEST = 'FETCH_REFRESH_REQUEST';
export const FETCH_REFRESH_SUCCESS = 'FETCH_REFRESH_SUCCESS';
export const FETCH_REFRESH_FAILURE = 'FETCH_REFRESH_FAILURE';
export const FETCH_REFRESH_IDLE = 'FETCH_REFRESH_IDLE';

export const FETCH_DELETE_USER_REQUEST = 'FETCH_DELETE_USER_REQUEST';
export const FETCH_DELETE_USER_SUCCESS = 'FETCH_DELETE_USER_SUCCESS';
export const FETCH_DELETE_USER_FAILURE = 'FETCH_DELETE_USER_FAILURE';
export const FETCH_DELETE_USER_IDLE = 'FETCH_DELETE_USER_IDLE';

export const FETCH_REAUTH_REQUEST = 'FETCH_REAUTH_REQUEST';
export const FETCH_REAUTH_SUCCESS = 'FETCH_REAUTH_SUCCESS';
export const FETCH_REAUTH_FAILURE = 'FETCH_REAUTH_FAILURE';
export const FETCH_REAUTH_IDLE = 'FETCH_REAUTH_IDLE';

export const FETCH_GET_SWING_REQUEST = 'FETCH_GET_SWING_REQUEST';
export const FETCH_GET_SWING_SUCCESS = 'FETCH_GET_SWING_SUCCESS';
export const FETCH_GET_SWING_FAILURE = 'FETCH_GET_SWING_FAILURE';
export const FETCH_GET_SWING_IDLE = 'FETCH_GET_SWING_IDLE';

export const FETCH_POST_SWING_REQUEST = 'FETCH_POST_SWING_REQUEST';
export const FETCH_POST_SWING_SUCCESS = 'FETCH_POST_SWING_SUCCESS';
export const FETCH_POST_SWING_FAILURE = 'FETCH_POST_SWING_FAILURE';
export const FETCH_POST_SWING_IDLE = 'FETCH_POST_SWING_IDLE';

export const FETCH_POST_USER_FITTING_RESULTS_REQUEST = 'FETCH_POST_USER_FITTING_RESULTS_REQUEST';
export const FETCH_POST_USER_FITTING_RESULTS_SUCCESS = 'FETCH_POST_USER_FITTING_RESULTS_SUCCESS';
export const FETCH_POST_USER_FITTING_RESULTS_FAILURE = 'FETCH_POST_USER_FITTING_RESULTS_FAILURE';
export const FETCH_POST_USER_FITTING_RESULTS_IDLE = 'FETCH_POST_USER_FITTING_RESULTS_IDLE';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USERS_IDLE = 'FETCH_USERS_IDLE';

export const FETCH_CREATE_USER_REQUEST = 'FETCH_CREATE_USER_REQUEST';
export const FETCH_CREATE_USER_SUCCESS = 'FETCH_CREATE_USER_SUCCESS';
export const FETCH_CREATE_USER_FAILURE = 'FETCH_CREATE_USER_FAILURE';
export const FETCH_CREATE_USER_IDLE = 'FETCH_CREATE_USER_IDLE';

export const FETCH_UPDATE_PASSWORD_REQUEST = 'FETCH_UPDATE_PASSWORD_REQUEST';
export const FETCH_UPDATE_PASSWORD_SUCCESS = 'FETCH_UPDATE_PASSWORD_SUCCESS';
export const FETCH_UPDATE_PASSWORD_FAILURE = 'FETCH_UPDATE_PASSWORD_FAILURE';
export const FETCH_UPDATE_PASSWORD_IDLE = 'FETCH_UPDATE_PASSWORD_IDLE';

export const FETCH_RECOMMENDATION_REQUEST = 'FETCH_RECOMMENDATION_REQUEST';
export const FETCH_RECOMMENDATION_SUCCESS = 'FETCH_RECOMMENDATION_SUCCESS';
export const FETCH_RECOMMENDATION_FAILURE = 'FETCH_RECOMMENDATION_FAILURE';
export const FETCH_RECOMMENDATION_IDLE = 'FETCH_RECOMMENDATION_IDLE';

export const FETCH_CLUBS_REQUEST = 'FETCH_CLUBS_REQUEST';
export const FETCH_CLUBS_SUCCESS = 'FETCH_CLUBS_SUCCESS';
export const FETCH_CLUBS_FAILURE = 'FETCH_CLUBS_FAILURE';
export const FETCH_CLUBS_IDLE = 'FETCH_CLUBS_IDLE';

export const FETCH_EXPORT_USERS_REQUEST = 'FETCH_EXPORT_USERS_REQUEST';
export const FETCH_EXPORT_USERS_SUCCESS = 'FETCH_EXPORT_USERS_SUCCESS';
export const FETCH_EXPORT_USERS_FAILURE = 'FETCH_EXPORT_USERS_FAILURE';
export const FETCH_EXPORT_USERS_IDLE = 'FETCH_EXPORT_USERS_IDLE';

export const FETCH_FORGOT_PASSWORD_REQUEST = 'FETCH_FORGOT_PASSWORD_REQUEST';
export const FETCH_FORGOT_PASSWORD_SUCCESS = 'FETCH_FORGOT_PASSWORD_SUCCESS';
export const FETCH_FORGOT_PASSWORD_FAILURE = 'FETCH_FORGOT_PASSWORD_FAILURE';
export const FETCH_FORGOT_PASSWORD_IDLE = 'FETCH_FORGOT_PASSWORD_IDLE';

export const FETCH_RESET_PASSWORD_REQUEST = 'FETCH_RESET_PASSWORD_REQUEST';
export const FETCH_RESET_PASSWORD_SUCCESS = 'FETCH_RESET_PASSWORD_SUCCESS';
export const FETCH_RESET_PASSWORD_FAILURE = 'FETCH_RESET_PASSWORD_FAILURE';
export const FETCH_RESET_PASSWORD_IDLE = 'FETCH_RESET_PASSWORD_IDLE';

export const FETCH_UPDATE_USER_REQUEST = 'FETCH_UPDATE_USER_REQUEST';
export const FETCH_UPDATE_USER_SUCCESS = 'FETCH_UPDATE_USER_SUCCESS';
export const FETCH_UPDATE_USER_FAILURE = 'FETCH_UPDATE_USER_FAILURE';
export const FETCH_UPDATE_USER_IDLE = 'FETCH_UPDATE_USER_IDLE';

export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
export const FETCH_USER_PROFILE_IDLE = 'FETCH_USER_PROFILE_IDLE';

export const FETCH_RESET_FITTING_RESULTS_REQUEST = 'FETCH_RESET_FITTING_RESULTS_REQUEST';
export const FETCH_RESET_FITTING_RESULTS_SUCCESS = 'FETCH_RESET_FITTING_RESULTS_SUCCESS';
export const FETCH_RESET_FITTING_RESULTS_FAILURE = 'FETCH_RESET_FITTING_RESULTS_FAILURE';
export const FETCH_RESET_FITTING_RESULTS_IDLE = 'FETCH_RESET_FITTING_RESULTS_IDLE';

export const FETCH_CHECKOUT_REQUEST = 'FETCH_CHECKOUT_REQUEST';
export const FETCH_CHECKOUT_SUCCESS = 'FETCH_CHECKOUT_SUCCESS';
export const FETCH_CHECKOUT_FAILURE = 'FETCH_CHECKOUT_FAILURE';
export const FETCH_CHECKOUT_IDLE = 'FETCH_CHECKOUT_IDLE';

export const SAVE_BOOKINGS_REQUEST = 'SAVE_BOOKINGS_REQUEST';
export const SAVE_BOOKINGS_SUCCESS = 'SAVE_BOOKINGS_SUCCESS';
export const SAVE_BOOKINGS_FAILURE = 'SAVE_BOOKINGS_FAILURE';
export const SAVE_BOOKINGS_IDLE = 'SAVE_BOOKINGS_IDLE';

export const CANCEL_BOOKINGS_REQUEST = 'CANCEL_BOOKINGS_REQUEST';
export const CANCEL_BOOKINGS_SUCCESS = 'CANCEL_BOOKINGS_SUCCESS';
export const CANCEL_BOOKINGS_FAILURE = 'CANCEL_BOOKINGS_FAILURE';
export const CANCEL_BOOKINGS_IDLE = 'CANCEL_BOOKINGS_IDLE';

export const GET_TIMESLOTS_REQUEST = 'GET_TIMESLOTS_REQUEST';
export const GET_TIMESLOTS_SUCCESS = 'GET_TIMESLOTS_SUCCESS';
export const GET_TIMESLOTS_FAILURE = 'GET_TIMESLOTS_FAILURE';
export const GET_TIMESLOTS_IDLE = 'GET_TIMESLOTS_IDLE';


export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';
export const FETCH_SERVICES_IDLE = 'FETCH_SERVICES_IDLE';

export const EXPORT_ORDERS_REQUEST = 'EXPORT_ORDERS_REQUEST';
export const EXPORT_ORDERS_SUCCESS = 'EXPORT_ORDERS_SUCCESS';
export const EXPORT_ORDERS_FAILURE = 'EXPORT_ORDERS_FAILURE';
export const EXPORT_ORDERS_IDLE = 'EXPORT_ORDERS_IDLE';

export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';
export const FETCH_ORDERS_IDLE = 'FETCH_ORDERS_IDLE';

export const CREATE_ORDERS_REQUEST = 'CREATE_ORDERS_REQUEST';
export const CREATE_ORDERS_SUCCESS = 'CREATE_ORDERS_SUCCESS';
export const CREATE_ORDERS_FAILURE = 'CREATE_ORDERS_FAILURE';
export const CREATE_ORDERS_IDLE = 'CREATE_ORDERS_IDLE';

export const UPDATE_ORDERS_REQUEST = 'UPDATE_ORDERS_REQUEST';
export const UPDATE_ORDERS_SUCCESS = 'UPDATE_ORDERS_SUCCESS';
export const UPDATE_ORDERS_FAILURE = 'UPDATE_ORDERS_FAILURE';
export const UPDATE_ORDERS_IDLE = 'UPDATE_ORDERS_IDLE';