import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import BottomTabNavigator from './BottomTabNavigator';
import CommonStack from './CommonStack';
import CustomDrawerContent from './CustomDrawer';
import DrawerStack from './DrawerStack';
import TabFourStack from './tabStacks/TabFourStack';

const Drawer = createDrawerNavigator();

const DrawerNavigator = ({ uid, role, signMeOut, orgId , orgName,selfCreated }) => {
  return (
    <Drawer.Navigator
      drawerContent={(props) => <CustomDrawerContent {...props} signMeOut={signMeOut} />}
      screenOptions={({ route }) => ({
        unmountOnBlur: true,
        headerShown: false,
      })}
    >
      <Drawer.Screen
        name="Dashboard"
        component={selfCreated ? CommonStack : BottomTabNavigator }
        initialParams={{ uid: uid, role: role, signMeOut: signMeOut , orgName: orgName  }}
      />
      <Drawer.Screen
        name="Golfers"
        component={selfCreated  ?  CommonStack : BottomTabNavigator }
        initialParams={{ uid: uid, role: role, signMeOut: signMeOut, orgName: orgName,orgId:orgId  }}
      />
      <Drawer.Screen
        name="Orders"
        component={selfCreated  ? TabFourStack : BottomTabNavigator }
        initialParams={{role: role , orgName: orgName  }}
      />
      <Drawer.Screen
        name="Transactions"
        component={DrawerStack}
        initialParams={{ screen: 'Transaction',role: role , orgName: orgName  }}
      />
      <Drawer.Screen
        name="manageAccount"
        component={CommonStack}
        initialParams={{ screen: 'manageAccount',role: role, orgName: orgName  }}
      />
      <Drawer.Screen
        name="Payout"
        component={DrawerStack}
        initialParams={{ screen: 'Payout',role: role , orgName: orgName }}
      />
      <Drawer.Screen
        name="Store"
        component={DrawerStack}
        initialParams={{ screen: 'Store',role: role , orgName: orgName  }}
      />
      <Drawer.Screen
        name="Contact"
        component={DrawerStack}
        initialParams={{ screen: 'Contact',role: role , orgName: orgName  }}
      />
      <Drawer.Screen
        name="UserList"
        component={CommonStack}
        initialParams={{ type: 'usersList',role: role , orgName: orgName  }}
      />
    </Drawer.Navigator>
  );
};


export default DrawerNavigator;



