import { styled } from "nativewind";
import { View, Text, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, Pressable, Modal } from "react-native";

export default function AlertModal({ modalVisible, handleConfirmCancel, handleDismissCancel, handleOutsideClick, modelWidth ,description,title,btnLabel1,btnLabel2}) {
    const containerStyle = btnLabel1 && btnLabel2 ? styles.buttonContainer : styles.buttonOKContainer;
return(
   
    <Modal
        transparent={true}
        visible={modalVisible}
        animationType="slide"
    >

        <TouchableWithoutFeedback onPress={handleOutsideClick}>
            <View style={styles.modalBackground}>
                <View style={[styles.modalContainer, { width: modelWidth }]}>
                    <Text style={styles.modalTitle}>{title}</Text>
                    <Text style={styles.modalMessage}>{description}</Text>
                    <View style={containerStyle}>
                        {btnLabel1 && (
                            <TouchableOpacity style={[styles.mButton, { marginRight: 20 }]}
                                onPress={handleConfirmCancel}>
                                <Text style={styles.modalButton}>{btnLabel1}</Text>
                            </TouchableOpacity>
                        )}

                        {btnLabel2 && (
                            <TouchableOpacity style={styles.mButton}
                                onPress={handleDismissCancel}>
                                <Text style={styles.modalButton}>{btnLabel2}</Text>
                            </TouchableOpacity>
                        )}
                    </View>
                </View>
            </View>
        </TouchableWithoutFeedback>
     </Modal>
   
    );
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: "#007dba",
        paddingVertical: 15,
        alignItems: "center",
        width: "50%",
        marginBottom: 10,
    },
    mButton: {
        backgroundColor: "#007dba",
        paddingVertical: 15,
        alignItems: "center",
        width: "40%",
        marginBottom: 10,
    },
    okButton: {
        backgroundColor: "#007dba",
        paddingVertical: 15,
        alignItems: "center",
        width: "40%",
        marginBottom: 10,
        justifyContent: 'center'
    },
    buttonText: {
        color: "white",
        fontSize: 16,
    },
    modalButton: {
        color: "white",
        fontSize: 16,
        fontWeight: 'bold'
    },
    modalBackground: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {

        padding: 50,
        backgroundColor: 'white',
        borderRadius: 10,
        alignItems: 'center',
    },
    modalTitle: {
        fontSize: 23,
        fontWeight: 'bold',
    },
    modalMessage: {
        fontSize: 17,
        marginVertical: 10,
        textAlign: 'center',
        marginBottom: 20
    },
    conformationMessage: {
        fontSize: 20,
        marginVertical: 10,
        textAlign: 'center',
        marginBottom: 20
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    buttonOKContainer: {
        flex:1,
        justifyContent: 'center',
        width: '100%',
        alignItems:'center',
    },
    conformationContainer: {
        width: '100%',
        padding: 50,
        backgroundColor: 'white',
        borderRadius: 10,
        alignItems: 'center',
    },
    okContainer: {
        flex: 1,
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
     

    },
});
