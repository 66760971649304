import { styled } from "nativewind";
import { useEffect, useState } from 'react';
import { Image, KeyboardAvoidingView, Platform, Pressable, SafeAreaView, ScrollView, Text, View } from 'react-native';

import MyButton from '../MyButton';
import MyTextInput from '../MyTextInput';

import { useDispatch, useSelector } from 'react-redux';
import { fetchRegisterRequest, fetchUpdatePasswordIdle, fetchUpdatePasswordRequest } from '../../store/ducks/app/actions';
import { APIStates } from '../../utils/Strings';
import {
  pageTitleStyle,
  textFunStyle,
  textStyle
} from '../Styles';
import { setItemInStore } from "../../utils/storage/storage";
import { useNavigation } from '@react-navigation/native';

const StyledPressable = styled(Pressable);
const StyledView = styled(View);
const StyledText = styled(Text);
const StyledImage = styled(Image);
const StyledKeyboardAvoidingView = styled(KeyboardAvoidingView);

export default function UpdatePassword({ uid, setIsAdminUserLandedFirstTime, }) {

  const navigation = useNavigation();
  const dispatch = useDispatch()
  const updatePassword = useSelector(state => state.app.updatePassword)
  const [password, onChangePassword] = useState("");
  const [passwordConfirm, onChangePasswordConfirm] = useState("");

  useEffect(() => {
    if (updatePassword.status == APIStates.FAILURE) {
      alert(updatePassword.message)
      dispatch(fetchUpdatePasswordIdle())
    } else if (updatePassword.status == APIStates.SUCCESS) {
      alert('Password Updated Successfully.')
      setItemInStore('isAdminUserLandedFirstTime', 'false')
      setIsAdminUserLandedFirstTime ? setIsAdminUserLandedFirstTime(false) : navigation.navigate('AiCaddie')
      dispatch(fetchUpdatePasswordIdle())
    }
  }, [updatePassword.status])

  const onSubmit = () => {
    if (password == '') {
      alert('Password is required.')
      return
    }
    if (password !== passwordConfirm) {
      alert("Passwords do not match, please try again.");
      return null;
    }

    dispatch(fetchUpdatePasswordRequest({
      userId: uid,
      password: password
    }))
  };

  return (
    <View style={setIsAdminUserLandedFirstTime ? { marginTop: 100, marginHorizontal: 10 } : null}>
        <ScrollView style={{ flex:1}}>
          <StyledView>
            <StyledView className="mx-auto my-2">
              {
                (setIsAdminUserLandedFirstTime) && 
              <StyledText className={pageTitleStyle}>
                {`
Update Password!
                `}
              </StyledText>
              } 
              {
                setIsAdminUserLandedFirstTime && <StyledText className={textStyle}>
                  Welcome! As a first-time user, we strongly recommend you to update your password to ensure the security of your account. Please take a moment to change your password now.
                </StyledText>
              }

            </StyledView>

            <MyTextInput
              label={"Password"}
              type={"password"}
              value={password}
              onChangeValue={onChangePassword}
            />

            <MyTextInput
              label={"Confirm Password"}
              type={"password"}
              value={passwordConfirm}
              onChangeValue={onChangePasswordConfirm}
            />

            <MyButton
              label={"Update Password"}
              theme={"submit"}
              onPress={() => onSubmit()}
            />

          </StyledView>
        </ScrollView>
    </View>
  );
};
