import { styled } from "nativewind";
import { useEffect, useState } from 'react';
import { ActivityIndicator, Dimensions, KeyboardAvoidingView, Platform, SafeAreaView, ScrollView, Text, View } from 'react-native';
import { useDispatch, useSelector } from "react-redux";
import MyButton from '../MyButton';
import MyTextInput from '../MyTextInput';
import { fetchCreateUserIdle, fetchCreateUserRequest } from '../../store/ducks/app/actions';
import { APIStates } from '../../utils/Strings';
import {
    pageTitleStyle
} from '../Styles';
import MyPicker from "../Picker";
import AnalyticsLogger from "../managers/AnalyticsLogger";
import { ScreenNames } from "../../utils/Util";
import { getItemFromStore } from "../../utils/storage/storage";
import { useNavigation } from '@react-navigation/native';
import { useCurrentUser } from "../CurrentUserProvider";
import { CommonActions } from '@react-navigation/native';

const StyledView = styled(View);
const StyledText = styled(Text);
const ScreenWidth = Dimensions.get('window').width
const FieldWidth = ScreenWidth * 0.80

export default function CreateUser({route}) {
    const { role } = useCurrentUser();
    const {create} = route.params || {};
    const navigation = useNavigation();
    const dispatch = useDispatch()
    const createUser = useSelector(state => state.app.createUser)
    const [selectedRole, setSelectedRole] = useState("");
    const [isPickerVisible, setIsPickerVisible] = useState(-1)
    const [firstName, onChangeFirstName] = useState("")
    const [lastName, onChangeLastName] = useState("")
    const [email, onChangeEmail] = useState("");
    const [password, onChangePassword] = useState("");
    const [passwordConfirm, onChangePasswordConfirm] = useState("");

    useEffect(() => {
        AnalyticsLogger.getInstance().logScreenView(create == 'golfer' ? ScreenNames.AddGolfers : ScreenNames.AddTeamMembers)
    }, [])


      const reloadPage = () => {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: 'UserList' }],
          })
        );
      };

    useEffect(() => {
        if (createUser.status == APIStates.SUCCESS) {
            navigation.navigate("UserList",{
                create: create === "golfer" ? "golfer" : "UserList", 
                type: create === "golfer" ? "golfer" : "UserList",
              })
              reloadPage();
            dispatch(fetchCreateUserIdle())
        }
        else if (createUser.status == APIStates.FAILURE) {
            alert(createUser.message)
            dispatch(fetchCreateUserIdle())
        }
    }, [createUser.status])

    const onSubmit = async () => {
        if (email == '') {
            alert('Username is required.')
            return
        }
        if (email == '') {
            alert('Email is required.')
            return
        }
        if (password !== passwordConfirm) {
            alert("Passwords do not match, please try again.");
            return null;
        }

        const roles = getRolesForUserCreation(role)
        const roleToPost = create === 'golfer' ? 'golfer' : selectedRole != '' ? selectedRole : roles[0]
        const orgId = await getItemFromStore('orgId')
        let body = {
            firstName,
            lastName,
            email,
            password,
            role: roleToPost
        }
        orgId && (body.orgId = orgId)
        dispatch(fetchCreateUserRequest(body))
    };

    const getRolesForUserCreation = (role) => {
        switch (role) {
            case "provider":
                return ["owner", "admin", "contributor", "viewer", "golfer"] 
            case "owner":
                return ["admin", "contributor", "viewer", "golfer"] 
            case "admin":
                return ["contributor", "viewer", "golfer"] 
            case "contributor":
                return ["viewer", "golfer"]        
            default:
                return [];
        }
    }

    const setDropdownValue = (index, value) => {
        setSelectedRole(value)
    }

    return (
        <SafeAreaView style={{width:'100%', flex: 1, alignItems:'center',backgroundColor:'white'}}>
            <KeyboardAvoidingView
                behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
                keyboardVerticalOffset={130}
            >
                <ScrollView>
                    <StyledView style={{width: ScreenWidth, alignItems:'center'}}>
                        <StyledText className={pageTitleStyle + " m-2"} />

                        <View style={{flexDirection: 'row', justifyContent:'space-between', width: ScreenWidth - 40, marginHorizontal: 20 }}>
                            <View style={{ width: ScreenWidth / 2 }}> 
                                <MyTextInput
                                    label={"First Name"}
                                    type={"email"}
                                    placeholder={"First name"}
                                    value={firstName}
                                    width={Platform.OS === "ios" ? FieldWidth / 2 : FieldWidth / 2 + 30}
                                    onChangeValue={onChangeFirstName}
                                />
                            </View>
                            
                            <View style={{ width: ScreenWidth / 2 }}>  
                                <MyTextInput
                                    label={"Last Name"}
                                    type={"email"}
                                    placeholder={"Last name"}
                                    value={lastName}
                                    width={Platform.OS === "ios" ? FieldWidth / 2 : FieldWidth / 2 + 50}
                                    onChangeValue={onChangeLastName}
                                />
                            </View>
                        </View>
                        

                        <MyTextInput
                            label={"Email Address"}
                            type={"email"}
                            value={email}
                            width={ScreenWidth - 40}
                            onChangeValue={onChangeEmail}
                        />

                        {
                            create != 'golfer' &&
                            <StyledView style={{ width:ScreenWidth-45 }}>
                                <MyPicker
                                    index={1}
                                    key={"role"}
                                    label={"Role"}
                                    name={"Role"}
                                    pickerItems={getRolesForUserCreation(role)}
                                    selection={selectedRole || getRolesForUserCreation(role)[0]}
                                    setSelection={setDropdownValue}
                                    isPickerVisible={isPickerVisible}
                                    setIsPickerVisible={setIsPickerVisible}
                                />
                            </StyledView>
                        }

                        <MyTextInput
                            label={"Password"}
                            type={"password"}
                            value={password}
                            width={ScreenWidth - 40}
                            onChangeValue={onChangePassword}
                        />

                        <MyTextInput
                            label={"Confirm Password"}
                            type={"password"}
                            value={passwordConfirm}
                            width={ScreenWidth - 40}
                            onChangeValue={onChangePasswordConfirm}
                        />

                        {
                            createUser.status == APIStates.LOADING ?
                                <View className='m-2 flex flex-row items-center justify-center'>
                                    <ActivityIndicator size="large" color="rgba(170,218,253,1)" />
                                </View>
                                :
                                <MyButton
                                    label={"Create"}
                                    theme={"submit"}
                                    onPress={() => onSubmit()}
                                />
                        }

                    </StyledView>
                </ScrollView>
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
};
